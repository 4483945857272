import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

import { SpecialtiesService } from "src/app/core/specialties.service";
import moment from "moment";
import { TimepickerDialogComponent } from "../time-picker-dialog/timepicker-dialog.component";
import { DashboardService } from "src/app/dashboard/dashboard.service";
import { CleverTapService } from "src/app/clevertap.service";
import { CredentialsService } from "src/app/core/credentials.service";
import { ConsultationDetailsPatient } from "../patient.component";

export interface DoctorList {
  data: Datum[];
  meta: Meta;
}

export interface Datum {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  gender: number;
  password: string;
  verificationCode: null;
  resetStatus: number;
  availabilityStatus: number;
  doctorType?: number;
  doctorRole: number;
  status: number;
  shift: Shift;
  providers: Provider[];
  shippingCounterEmail?: number;
  shippingCounterSms?: number;
  createdAt: Date;
  updatedAt: Date;
  ip?: string;
  quickbloxId?: string;
  quickbloxInfo?: QuickbloxInfo;
  about: string;
  cmNumber: string;
  countryCode: string;
  dateOfBirth: Date;
  identificationNumber: string;
  mppsNumber: string;
  phoneNumber: string;
  speciality: SpecialityElement[];
  subSpeciality: SubSpeciality[];
  firmPic?: string;
  profilePic?: string;
  countryIso?: string;
  firmPic2?: string;
}

export interface Provider {
  _id: string;
  status: number;
  name: string;
  providerType: number;
}

export interface QuickbloxInfo {
  _id?: string;
  application_id?: number;
  created_at: Date;
  id: number;
  nonce?: string;
  token?: string;
  ts?: number;
  updated_at: Date;
  user_id?: number;
  full_name?: string;
  custom_data?: string;
  email?: null;
  login?: string;
  website?: null;
  facebook_id?: null;
  phone?: null;
  last_request_at?: Date | null;
  external_user_id?: null;
  blob_id?: null;
  age_over16?: boolean;
  parents_contacts?: string;
  user_tags?: null;
  twitter_id?: null;
}

export interface Shift {
  startTime: string;
  endTime: string;
  days: number[];
}

export interface SpecialityElement {
  id: string;
  speciality: SubSpecialityClass;
  other?: string;
}

export interface SubSpecialityClass {
  en: string;
  es: string;
}

export interface SubSpeciality {
  id: string;
  subSpeciality: SubSpecialityClass;
}

export interface Meta {
  fileUrl: string;
  total: number;
}

@Component({
  selector: "app-schedule-appointment",
  templateUrl: "./schedule-appointment.component.html",
  styleUrls: ["./schedule-appointment.component.scss"],
})
export class ScheduleAppointmentComponent {
  appointmentForm: any;
  mytime: any;
  specialtyList: any[] = [];
  doctorList: any[] = [];
  selectedSpecialty: any;
  minDate = new Date();
  activeUser: any;
  constructor(
    private dialog: MatDialog,
    private readonly formBuilder: FormBuilder,
    private readonly specialtiesService: SpecialtiesService,
    private readonly dashboardService: DashboardService,
    public dialogRef: MatDialogRef<ScheduleAppointmentComponent>,
    private changeDetector: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly clevertap: CleverTapService,
    private readonly credentialsService: CredentialsService,
  ) {
    this.appointmentForm = this.formBuilder.group({
      date: [null, Validators.required],
      hour: ["", Validators.required],
      doctorSpecialist: ["", Validators.required],
      medicalSpecialty: ["", Validators.required],
    });

    this.activeUser = this.credentialsService.credentials.userDetails;

    this.getConsultationDetails(this.data._id);
  }

  onDateChange(event) {
    const date = moment(event.target.value, "DD/MM/YYYY");

    this.appointmentForm.controls.date.setValue(date.add(4, "hour").toDate());

    // La nueva fecha seleccionada
    // Aquí puedes hacer lo que necesites con la nueva fecha
  }

  getDatePlaceholder() {
    return "Selecciona la fecha";
    // if (this.appointmentForm.controls.date.value == "") {
    // return "Selecciona la fecha";
    // } else {
    // return "Selecciona la fecha";
    // }
  }

  getHourPlaceholder() {
    if (this.appointmentForm.controls.hour.value == "") {
      return "Selecciona la hora";
    } else {
      return "";
    }
  }

  setAppointmentData() {
    this.appointmentForm.setValue({
      doctorSpecialist: this.data.doctor?._id ?? "",
      medicalSpecialty: this.data.medicalSpecialty._id,
      hour: this.data?.hour ? this.getTime(this.data.hour) : "",
      date: this.data?.date
        ? moment(this.data.date).add(4, "hour").toDate()
        : "",
    });
  }

  getTime(hour: string) {
    if (!hour) {
      return "";
    }
    return moment(hour, "HH:mm:ss").format("h:mm A");
  }

  getDate(date: string) {
    return moment(date).add(4, "hour").format("DD/MM/YYYY");
  }

  submitAppointment() {
    if (this.appointmentForm.invalid) {
      this.appointmentForm.markAllAsTouched();
      return;
    }

    /* if(this.data.modalAction === 'schedule'){ */
    this.scheduleAppointment();
    /*   } */
  }

  scheduleAppointment() {
    /*    console.log(this.appointmentForm.value)schedule
    this.dialogRef.close(true); */
    this.specialtiesService
      .scheduleAppointment(this.data._id, {
        ...this.appointmentForm.value,
        date: moment(this.appointmentForm.value.date).add(4, "hour").toDate(),
      })
      .subscribe({
        next: (res) => {
          if (this.data.modalAction === "schedule") {
            this.clevertap.registerEvent$("appointment_scheduled", {
              user_id: this.data.patient.identificationNumber,
              specialty: res.data.medicalSpecialty,
              agent_id: this.activeUser._id,
              appointment_date: this.getDate(res.data.date),
              scheduling_time: this.getTime(res.data.hour),
            });
          } else if (this.data.modalAction === "reschedule") {
            this.clevertap.registerEvent$("appointment_rescheduled", {
              user_id: this.data.patient.identificationNumber,
              specialty: res.data.medicalSpecialty,
              agent_id: this.activeUser._id,
              new_appointment_date: this.getDate(res.data.date),
              rescheduling_time: this.getTime(res.data.hour),
            });
          }

          this.dialogRef.close(true);
        },
        error: () => {
          this.dialogRef.close(false);
        },
      });
  }
  /*   rescheduleAppointment(){

       this.specialtiesService.rescheduleAppointment(this.data._id, this.appointmentForm.value).subscribe((response) => {
         console.log('response reschedule appointment', response)
         this.dialogRef.close(true);
       })
     } */

  getConsultationDetails(id) {
    this.dashboardService.getConsultationDetails(id).subscribe({
      next: (res: ConsultationDetailsPatient) => {
        const [data] = res.data;

        this.getSpecialties(data.provider);
        this.setAppointmentData();

        if (data.medicalSpecialty) {
          this.getDoctors(data.medicalSpecialty);
        }
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  getSpecialties(provider: string) {
    this.specialtiesService.getSpecialties(provider).subscribe({
      next: (res: any) => {
        this.specialtyList = res.data.providerMedicalSpecialty;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  getSpecialtyName(specialty: any) {
    return specialty.speciality
      ? specialty.speciality.speciality.es
      : specialty.subSpeciality.subSpeciality.es;
  }

  onSpecialtyChange(medicalSpecialty: any) {
    this.selectedSpecialty = this.specialtyList.find(
      (specialty) => specialty.medicalSpecialty._id === medicalSpecialty.value,
    );
    this.appointmentForm.controls.doctorSpecialist.setValue("", {
      emitEvent: false,
    });
    this.appointmentForm.controls.doctorSpecialist.setErrors(null);
    this.getDoctors(this.selectedSpecialty.medicalSpecialty);
  }

  getDoctors(specialty: any) {
    let params: any = {};
    if (specialty?.subSpeciality) {
      params["subSpeciality"] = specialty.subSpeciality._id;
      params["doctorRole"] = "2";
    }
    if (specialty?.speciality) {
      params["speciality"] = specialty.speciality._id;
      params["doctorRole"] = "2";
    }

    this.specialtiesService.getDoctorBySpecialty(params).subscribe({
      next: (res: DoctorList) => {
        this.doctorList = res.data;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  openTimepicker() {
    const dialogRef = this.dialog.open(TimepickerDialogComponent, {
      width: "300px",
      panelClass: "timepicker-dialog-container",
      backdropClass: "timepicker-dialog-backdrop",
      data: this.appointmentForm.controls.hour.value,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.appointmentForm.controls.hour.setValue(result);
      }
    });
  }

  onClose() {
    this.dialogRef.close("close");
  }

  onCancel() {
    this.dialogRef.close(false);
  }
}
