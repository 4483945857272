import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { DashboardService } from "../dashboard.service";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { CommonService } from "../common.services";
import { ModalService } from "../modal.service";
import { HttpErrorResponse } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { SharedService } from "src/app/shared/shared.service";
import { CleverTapService } from "src/app/clevertap.service";

@Component({
  selector: "app-sucess-popup",
  templateUrl: "./sucess-popup.component.html",
  styleUrls: ["./sucess-popup.component.scss"],
})
export class SucessPopupComponent implements OnInit {
  consultId: any;
  patientInfo:any;
  constructor(
    private dashboardService: DashboardService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private matDialog: MatDialog,
    private commonServices: CommonService,
    private modalService: ModalService,
    public dialogRef: MatDialogRef<SucessPopupComponent>,
    private translationService: TranslateService,
    private sharedService: SharedService,
    private readonly clevertap: CleverTapService,
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.consultId = params.id;
    });
    this.patientInfo = JSON.parse(localStorage.getItem("patientDetails"));
  }

  sucessCall() {
    this.dashboardService.successCallByDoctor(this.consultId).subscribe(
      (res: any) => {
        console.log(res, "triage_completed");
        if (res.data.appointmentType === 2) {
          this.clevertap.registerEvent$("triage_completed", {
            user_id: this.patientInfo ? this.patientInfo.identificationNumber: res.data.patient,
            specialty: res.data.medicalSpecialty,
          });
        }
        if (res.data.appointmentType === 3) {
          this.clevertap.registerEvent$("schedule_complete", {
            user_id:this.patientInfo ? this.patientInfo.identificationNumber: res.data.patient,
            specialty: res.data.medicalSpecialty,
          });
          
        }
        this.translationService
          .get("Call has been End Successfully")
          .subscribe((text: string) => {
            this.sharedService.showMessage(text, "success");
          });
        this.modalService.callFeedBackSubject.next({ callStatus: 2 });
        this.matDialog.closeAll();
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      },
    );
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }
}
