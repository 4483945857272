import { Injectable, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MessageComponent } from "./message/message.component";
import { MultipleerrormessageComponent } from "./multipleerrormessage/multipleerrormessage.component";
import { ErrormessageComponent } from "./errormessage/errormessage.component";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  private _currentLanguage: string;

  constructor(
    public snackBar: MatSnackBar,
    private translateService: TranslateService,
  ) {
    this.currentLanguage = this.translateService.currentLang;
    this.subscibeTranslateService();
  }

  get currentLanguage(): string {
    return this._currentLanguage;
  }

  set currentLanguage(lang: string) {
    this._currentLanguage = lang === "en-US" ? "en" : "es";
  }

  showMessage(messages: string, panelClass: string) {
    this.snackBar.openFromComponent(MessageComponent, {
      data: messages,
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: 10000,
      panelClass: panelClass,
    });
  }

  showMultipleErrors(message: any, panelClass: string) {
    this.snackBar.openFromComponent(MultipleerrormessageComponent, {
      data: message,
      panelClass: panelClass,
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: 10000,
    });
  }

  showErrorMessage(msg: string, panelClass: string = "single") {
    this.snackBar.openFromComponent(ErrormessageComponent, {
      data: msg,
      panelClass: panelClass,
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: 10000,
    });
  }

  getChiefComplaint(obj): string {
    try {
      return obj.chiefComplaint.chiefComplaint[this.currentLanguage];
    } catch (error) {
      return "";
    }
  }

  sliceString(str: string, maxLength: number = 37): string {
    return str.length > maxLength ? str.slice(0, maxLength).concat("...") : str;
  }

  getOtherChiefComplaintMessage(obj: any): string {
    const msg: string = obj.consultType?.concat(obj.chiefComplaint?.other);
    return this.sliceString(msg);
  }

  getChiefComplaintMessage(obj: any): string {
    const msg: string = obj.consultType?.concat(this.getChiefComplaint(obj));
    return this.sliceString(msg);
  }

  private subscibeTranslateService(): void {
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLanguage = event.lang;
    });
  }
}
