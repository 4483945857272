import {
  Component,
  OnInit,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  Input,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DashboardService } from "../dashboard.service";
import { ModalService } from "../modal.service";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { AttachmentPopupComponent } from "../attachment-popup/attachment-popup.component";
import { TranslateService } from "@ngx-translate/core";
import { HttpErrorResponse } from "@angular/common/http";
import { SharedService } from "src/app/shared/shared.service";
import { ViewAttachmentComponent } from "../view-attachment/view-attachment.component";
import { ConnectionService } from "../connection.service";
import { UploadFileComponent } from "../upload-file/upload-file.component";
export class PageParams {
  patient: string;
  tag: string;
  size: number;
  skip: number;
}

export interface Document {
  _id: string;
  url: string;
  name: string;
  patient: string;
  parent: string;
  status: number;
  createdAt: Date;
  updatedAt: Date;
  id: string;
}

@Component({
  selector: "app-attachments",
  templateUrl: "./attachments.component.html",
  styleUrls: ["./attachments.component.scss"],
})
export class AttachmentsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() canAdd: boolean;
  totalLength: boolean = false;
  tags: any = [];
  docsList: any = [];
  documentFilter: any = [];
  pageParams: Partial<PageParams> = { size: 10, skip: 0 };
  tagId: any = [];
  patientId;
  patientDetials: any;
  filterId;
  filterAttachement = false;
  loading: boolean = true;
  attachmentInCall: boolean = false;
  callStatus: boolean = false;
  tagSequence = [];
  callFeedbackSubscription: Subscription;
  currentLanguage: string = "es";
  sockectSubscription: Subscription;
  public attachmentOnCallRecord: any;

  constructor(
    public dialog: MatDialog,
    private dashboardService: DashboardService,
    // private store: DataStoreServiceService,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private translateService: TranslateService,
    private sharedService: SharedService,
    private connectionService: ConnectionService,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.patientId = params.patient;
      this.getPatientDetails(this.patientId);
      this.docsList = [];
      this.pageParams.skip = 0;
      this.getDocuments();
    });

    this.modalService.vscallStatusObservable.subscribe((res) => {
      if (res.callStarted == true) {
        this.callStatus = true;
        this.sockectSubscription = this.connectionService.messages.subscribe(
          (msg) => {
            if (msg.data.url) {
              this.attachmentOnCallRecord = msg["data"];
              this.viewAttachment();
            }
          },
        );
      }
    });
  }
  ngOnChanges(changes: SimpleChanges): void {}

  viewAttachment() {
    const dialogRef = this.dialog.open(ViewAttachmentComponent, {
      width: "402px",
      disableClose: true,
      data: { record: this.attachmentOnCallRecord },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.docsList = [];
      let replace = true;
      this.pageParams.skip = 0;
      this.pageParams.tag = "";
      this.getDocuments(replace);
    });
  }
  ngOnInit() {
    if (this.translateService.currentLang == "en-US") {
      this.currentLanguage = "en";
    }

    this.getallTags();
    this.callFeedbackSubscription =
      this.modalService.callFeedBackObservable.subscribe((res) => {
        if (res.callStatus == 2) {
          this.callStatus = false;
          if (this.sockectSubscription) {
            this.sockectSubscription.unsubscribe();
          }
        }
      });
  }

  attachmentPopup(attachment) {
    const dialogRef = this.dialog.open(AttachmentPopupComponent, {
      width: "600px",
      maxHeight: "500px",
      data: { document: attachment._id, status: this.callStatus },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  getallTags() {
    this.dashboardService.getTags().subscribe({
      next: (res: any) => {
        this.tags = res["data"];
      },
      error: (error: HttpErrorResponse) => {
        this.handleError(error);
      },
    });
  }

  documentsGetImage( data: Document ) {
    try {
      console.log(data);
      
      const bucketName = "sinapsis-dev.s3.us-east-1";
      if ( !data.url.split( ".com" )[0].includes( bucketName )  ) {
        data.url = "https://sinapsis-dev.s3.us-east-1.amazonaws.com/" + data.url;
      }

      const url = new URL(data.url);

      var path = url.pathname;
      const imageExtension = path.split(".").pop().toLowerCase();

      console.log(imageExtension);

      // console.log({ imageExtension });

      if (imageExtension === "jpg" || imageExtension === "jpeg") {
        return {
          ...data,
          url: data.url,
          ext: "jpg",
        }
      } else if (["xls", "xlsx", "csv"].includes(imageExtension)) {
        return {
          ...data,
          url: data.url,
          ext: "xls",
        }
      } else if (["doc", "docx"].includes(imageExtension)) {
        return {
          ...data,
          url: data.url,
          ext: "doc",
        }
      }

      return {
        ...data,
        url: data.url,
        ext: "pdf",
      };
    } catch (e) {
      this.handleError(e);
    }
  }

  getDocumentsFilter(data) {
    this.pageParams.tag = data ? data : "";
    this.pageParams.skip = 0;
    let replace = true;
    this.getDocuments(replace);
  }

  pagination() {
    this.pageParams.skip = this.pageParams.skip + 10;
    this.getDocuments();
  }

  getDocuments(replace?: boolean) {
    this.pageParams.patient = this.patientId;
    delete this.pageParams.tag;

    this.dashboardService.getDocuments(this.pageParams as any).subscribe({
      next: (res: any) => {
        if (replace) {
          this.docsList = res.data.map((doc: Document) => 
            this.documentsGetImage(doc) 
          );
        } else {
          this.docsList = res.data.map((doc: Document) => 
            this.documentsGetImage(doc)
          );
        }

        console.log(this.docsList);

        if (this.docsList.length == res.meta.total) {
          this.totalLength = true;
        } else {
          this.totalLength = false;
        }
        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.handleError(error);
        this.loading = false;
      },
    });
  }

  getPatientDetails(patientId) {
    this.dashboardService.getPatientDetials(patientId).subscribe({
      next:(res: any) => {
        this.patientDetials = res;
      },
      error:(error: HttpErrorResponse) => {
        this.handleError(error);
      },
    });
  }

  uploadFile() {
    const uploadRef = this.dialog.open(UploadFileComponent, {
      width: "600px",
      disableClose: true,
      autoFocus: false,
      data: {
        patientDetials: this.patientDetials,
      },
    });

    uploadRef.afterClosed().subscribe((result) => {
      if (result && result.event) {
        switch (result.event) {
          case "refreshList":
            this.getDocuments(true);
            break;
        }
      }
    });
  }

  ngOnDestroy() {
    this.callFeedbackSubscription.unsubscribe();
    if (this.sockectSubscription) {
      this.sockectSubscription.unsubscribe();
    }
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        console.log(error);
        if (error?.error?.errors && typeof error?.error?.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error?.errors || error.error.message.join(", ");
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }
}
