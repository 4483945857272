import { Component, OnInit, Input } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import { DashboardService } from "../dashboard.service";
import { ModalService } from "../modal.service";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService } from "../common.services";
import { CredentialsService } from "src/app/core/credentials.service";
import { TranslateService } from "@ngx-translate/core";
import { SharedService } from "src/app/shared/shared.service";
import { HttpErrorResponse } from "@angular/common/http";
import { AttachmentPopupComponent } from "../attachment-popup/attachment-popup.component";
import { MatDialog } from "@angular/material/dialog";
import { Observable, of, Subscription } from "rxjs";
import { map, startWith, tap } from "rxjs/operators";
import { E } from "@angular/cdk/keycodes";
import { environment } from "src/environments/environment";
import moment from "moment";
import { ScheduleFollowUpConsultationComponent } from "../schedule-follow-up-consultation/schedule-follow-up-consultation.component";
import { Status } from "../consults-page/consults-page.component";

export interface VisitDetails {
  data: Datum[];
  meta: Meta;
}

export interface Datum {
  _id: string;
  chiefComplaint: ChiefComplaint;
  document: any[];
  informedConsent: boolean;
  patient: Patient;
  parent: Parent;
  status: number;
  consultationType: number;
  appointmentType: number;
  appointmentStatus: number;
  appointmentRescheduledCount: number;
  appointmentRescheduledByDoctorCount: number;
  action: string[];
  paraclinicalPrescription: any[];
  medicalPrescription: Array<MedicalPrescription | null>;
  providerCall: string;
  createdAt: Date;
  updatedAt: Date;
  communicationMode: number;
  date: string;
  provider: string;
  utcOffset: number;
  doctor: Doctor;
  hour: string;
  visitForm: VisitForm;
  consultationTime: number;
  waitingTime: number;
  followUpConsultation?: any;
  hasFollowUp?: any;
  followUpConsultation2?: any;
  followUpConsultation3?: any;
  closureReason?: any;
}

export interface MedicalPrescription {
  durationTime?: DurationTime;
  frequencyTime?: any;
  routeOfAdministration?: RouteOfAdministration;
  unit?: Unit;
  alternative?: Array<Alternative | null>;
}
export interface DurationTime {
  id: any;
}
export interface FrecuencyTime {
  id: string;
}
export interface RouteOfAdministration {
  id: any;
}
export interface Unit {
  id: any;
}
export interface Alternative {
  durationTime?: DurationTime;
  frequencyTime?: any;
  routeOfAdministration?: RouteOfAdministration;
  unit?: Unit;
}

export interface ChiefComplaint {
  id: string;
  chiefComplaint: SpecialityClass;
  other: string;
}

export interface SpecialityClass {
  en: string;
  es: string;
}

export interface Doctor {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  cmNumber: string;
  countryCode: string;
  identificationNumber: string;
  mppsNumber: string;
  phoneNumber: string;
  speciality: Speciality[];
  subSpeciality: SubSpeciality[];
}

export interface Speciality {
  id: string;
  speciality: SpecialityClass;
}

export interface SubSpeciality {
  id: string;
  subSpeciality: SpecialityClass;
}

export interface Parent {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  identificationNumber: string;
  countryCode: string;
  profilePic: string;
}

export interface Patient {
  _id: string;
  firstName: string;
  lastName: string;
  profilePic: string;
  identificationNumber: string;
}

export interface VisitForm {
  _id: string;
  consultation: string;
  patient: string;
  parent: string;
  doctor: string;
  status: number;
  historyOfPresentIllness: string;
  paraclinics: string;
  plan: string;
  doctorNotes: string;
  createdAt: Date;
  updatedAt: Date;
  reviewOfSystem?: any;
  differentialDiagnosisCie11?: string;
  diagnosisCie11?: string;
  heartRate?: number;
  respiratoryRate?: number;
  temperature?: number;
  bloodPressureSystolic?: number;
  bloodPressureDiastolic?: number;
}

export interface Meta {
  fileUrl: string;
  videoRecordingFileUrl: string;
}

@Component({
  selector: "app-visit-history-form",
  templateUrl: "./visit-history-form.component.html",
  styleUrls: ["./visit-history-form.component.scss"],
})
export class VisitHistoryFormComponent implements OnInit {
  @Input() visit;

  oldDiagnosis: any;
  oldDifferentialDiagnosis: any;

  chiefComplaintList = [];
  Units: any = [];
  bodysystem: any = [];
  adminstrations: any = [];
  tablete: any = [];
  consultId: string;
  enableButton: boolean = false;
  public currentVisitDetails: any = {};
  testCall: boolean = false;
  hideFollowUpForm: boolean = true;
  hideFollowUpForm2: boolean = true;
  hideFollowUpForm3: boolean = true;
  followUpStatus: any;
  followUpStatus2: any;
  followUpStatus3: any;
  currentLanguage: string = "es";
  enableUpdate: boolean = false;
  showOtherChiefComplaint: boolean = false;
  historyOfPresentIllness = "";

  isPlanValid = true;
  isDiagnosisValid = true;
  isPresentIllnessValid = true;

  reviewOfSystemSubscription$: Subscription;
  isNewChiefComplaintList = true;
  isEditTimeOver = true;
  isEditFollowupTimeOver = true;
  isEditFollowUpInTime = false;
  isEditFollowUp2InTime = false;
  isEditFollowUp3InTime = false;

  patientId: number;
  patientDetials: any;
  providerConsultId: string;

  scheduleDate: Date = null;

  ///

  diagnosticList: any = [];
  diagnosticListFiltered: any = [];
  differentialDiagnosisListFiltered: any = [];

  diagnosticListSelected: any = [];
  differentialDiagnosisListSelected: any = [];

  assessmentListSelected: any = [];
  assessmentListFiltered: any = [];
  assessmentValue: any = "";
  oldAssessment: any = "";
  ////

  closureReasons: any = [];
  isOtherClosureReason: boolean = false;
  canCloseAttention: boolean = true;
  closedAttention: boolean = false;
  closeAttentionForm = this.fb.group({
    closeAttention: [false, [Validators.required]],
    closureReason: [""],
    otherClosureReason: [""],
  });
  closureReasonSelectIsOpen: boolean = false;

  get anyFollowUpInTimeForEdit(): boolean {
    return (
      this.isEditFollowUpInTime ||
      this.isEditFollowUp2InTime ||
      this.isEditFollowUp3InTime
    );
  }

  filteredOptions: Observable<string[]>;

  medicalPrescriptionUnits = [];
  mpFollowUpForm = this.fb.group({
    medicalPrescription: this.fb.array([this.initX()]),
  });
  followUpForm = {
    subjective: "",
    objective: "",
    assesment: "",
    assesmentCie11: [],
    plan: "",
  };

  mpFollowUpForm2 = this.fb.group({
    medicalPrescription: this.fb.array([this.initX()]),
  });
  followUpForm2 = {
    subjective: "",
    objective: "",
    assesment: "",
    assesmentCie11: [],
    plan: "",
  };

  mpFollowUpForm3 = this.fb.group({
    medicalPrescription: this.fb.array([this.initX()]),
  });
  followUpForm3 = {
    subjective: "",
    objective: "",
    assesment: "",
    assesmentCie11: [],
    plan: "",
  };
  formControlPlaceholder = "";

  noEmptyHTML(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const valueWithoutHtmlTags = control.value.replace(/<[^>]+>/g, "");
      const valueWithoutTagsAndSpace = valueWithoutHtmlTags.replace(
        /&nbsp;/g,
        "",
      );

      const result =
        valueWithoutTagsAndSpace == "" ||
        new RegExp(/^\s*$/).test(valueWithoutTagsAndSpace);

      return result ? { notEmptyText: { value: control.value } } : null;
    };
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.chiefComplaintList
      .map((chiefComplaint) => chiefComplaint.chiefComplaint.es)
      .filter((option) => option.toLowerCase().includes(filterValue));
  }

  setHistoryOfPresentIllness(val: any) {
    const valueWithoutHtmlTags = val.replace(/<[^>]+>/g, "");
    const valueWithoutTagsAndSpace = valueWithoutHtmlTags.replace(
      /&nbsp;/g,
      "",
    );

    if (
      valueWithoutTagsAndSpace == "" ||
      new RegExp(/^\s*$/).test(valueWithoutTagsAndSpace)
    ) {
      this.isPresentIllnessValid = false;
    } else {
      this.isPresentIllnessValid = true;
    }

    if (!(val instanceof Event))
      this.form.patchValue({ historyOfPresentIllness: val });
  }

  doctorNotes = "";
  setDoctorNotes(val: any) {
    if (!(val instanceof Event))
      this.form.patchValue({
        doctorNotes: val,
      });
  }

  paraclinics = "";
  setParaclinics(val: any) {
    if (!(val instanceof Event))
      this.form.patchValue({
        paraclinics: val,
      });
  }

  differentialDiagnosisCie11 = "";
  setDifferentialDiagnosis(val: any) {
    if (!(val instanceof Event))
      this.form.patchValue({
        differentialDiagnosisCie11: val,
      });
  }

  diagnosisCie11 = "";
  setDiagnosis(val: any) {
    const valueWithoutHtmlTags = val.replace(/<[^>]+>/g, "");
    const valueWithoutTagsAndSpace = valueWithoutHtmlTags.replace(
      /&nbsp;/g,
      "",
    );

    if (
      valueWithoutTagsAndSpace == "" ||
      new RegExp(/^\s*$/).test(valueWithoutTagsAndSpace)
    ) {
      this.isDiagnosisValid = false;
    } else {
      this.isDiagnosisValid = true;
    }
    if (!(val instanceof Event)) this.form.patchValue({ diagnosisCie11: val });
  }

  plan = "";
  setPlan(val: any) {
    const valueWithoutHtmlTags = val.replace(/<[^>]+>/g, "");
    const valueWithoutTagsAndSpace = valueWithoutHtmlTags.replace(
      /&nbsp;/g,
      "",
    );

    if (
      valueWithoutTagsAndSpace == "" ||
      new RegExp(/^\s*$/).test(valueWithoutTagsAndSpace)
    ) {
      this.isPlanValid = false;
    } else {
      this.isPlanValid = true;
    }

    if (!(val instanceof Event)) this.form.patchValue({ plan: val });
  }

  updateFollowUp(forWhich: string, val: any, wich: string) {
    let data = {
      [`followUpForm${wich}`]: {
        [forWhich]: val,
      },
    };
    this.form.patchValue(data);
  }

  form: FormGroup;
  loading: boolean = true;

  isOriginalDoctor = false;
  isFollowUpDoctor = false;
  isFollowUpEditEnabled = false;
  isFollowUp2EditEnabled = false;
  isFollowUp3EditEnabled = false;
  enableUpdateButton = false;
  lastConsultation: { doctor: { _id: string } };
  currentDoctorId: string;

  scheduleFollowUpAppointmentForm: FormGroup;
  showScheduleFollowUpAppointmentFormAbove: boolean = false;
  showScheduleFollowUpAppointmentFormBelow: boolean = false;
  minDateScheduleFollowUp: Date = new Date();
  hasAnAppointmentScheduled: boolean = false;

  constructor(
    private fb: FormBuilder,
    private dashboardService: DashboardService,
    private modalService: ModalService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private credentialService: CredentialsService,
    private commonServices: CommonService,
    private translateService: TranslateService,
    public dialog: MatDialog,
    private sharedService: SharedService,
  ) {
    this.currentVisitDetails.isPregnant = 0;
  }

  ngOnInit() {
    if (this.translateService.currentLang == "en-US") {
      this.currentLanguage = "en";
    }

    this.consultId = this.visit._id;

    if (this.visit.isTestCall) {
      this.testCall = true;
      this.loading = false;
    } else {
      this.testCall = false;
      this.createForm(this.consultId);
      this.minDateScheduleFollowUp.setDate(
        this.minDateScheduleFollowUp.getDate() + 1,
      );
    }

    this.activatedRoute.queryParams.subscribe((params) => {
      this.patientId = params.patient;

      this.dashboardService.getPatientDetials(this.patientId).subscribe({
        next: (res: any) => {
          this.patientDetials = res;
        },
        error: (error: HttpErrorResponse) => {
          this.handleError(error);
        },
      });
    });
  }

  createForm(id: string) {
    this.dashboardService.getConsultationDetails(id).subscribe((res: any) => {
      this.currentVisitDetails = { ...res.data[0] };

      this.oldDiagnosis = this.currentVisitDetails.visitForm.diagnosis;

      if (this.currentVisitDetails.followUpConsultation) {
        this.oldAssessment = this.currentVisitDetails.followUpConsultation
          ?.followUpForm?.assesment
          ? this.currentVisitDetails.followUpConsultation?.followUpForm
              ?.assesment
          : "";
      }
      this.oldDifferentialDiagnosis =
        this.currentVisitDetails.visitForm.differentialDiagnosis;
      this.loading = false;
      if (this.currentVisitDetails.isTestCall == true) {
        this.testCall = true;
      } else {
        this.testCall = false;
      }
      this.getCheifComplaintsList();

      if (
        this.credentialService.credentials.userDetails._id ==
        this.visit.doctor._id
      ) {
        this.isOriginalDoctor = true;
        this.enableButton = true;
      } else {
        this.isOriginalDoctor = false;
      }

      this.lastConsultation = { ...this.visit };
      this.currentDoctorId = this.credentialService.credentials.userDetails._id;
      this.canCloseAttention = !res.data.followUpDate;

      if (this.visit.hasFollowUp) {
        this.isFollowUpDoctor = false;

        if (
          res.data.followUpConsultation3 &&
          res.data.followUpConsultation3.doctor &&
          this.credentialService.credentials.userDetails._id ==
            res.data.followUpConsultation3.doctor._id
        ) {
          this.isFollowUpDoctor = true;
          this.enableButton = true;
        }

        if (
          !this.isFollowUpDoctor &&
          res.data.followUpConsultation2 &&
          res.data.followUpConsultation2.doctor &&
          this.credentialService.credentials.userDetails._id ==
            res.data.followUpConsultation2.doctor._id
        ) {
          this.isFollowUpDoctor = true;
          this.enableButton = true;
        }

        if (
          !this.isFollowUpDoctor &&
          res.data.followUpConsultation &&
          res.data.followUpConsultation.doctor &&
          this.credentialService.credentials.userDetails._id ==
            res.data.followUpConsultation.doctor._id
        ) {
          this.isFollowUpDoctor = true;
          this.enableButton = true;
        }

        if (
          res.data.followUpConsultation &&
          res.data.followUpConsultation.doctor
        ) {
          this.lastConsultation = { ...res.data.followUpConsultation };
          this.canCloseAttention = !res.data.followUpDate2;
        }

        if (
          res.data.followUpConsultation2 &&
          res.data.followUpConsultation2.doctor
        ) {
          this.lastConsultation = { ...res.data.followUpConsultation2 };
          this.canCloseAttention = !res.data.followUpDate3;
        }

        if (
          res.data.followUpConsultation3 &&
          res.data.followUpConsultation3.doctor
        ) {
          this.lastConsultation = { ...res.data.followUpConsultation3 };
          this.canCloseAttention = true;
        }
      }

      this.closedAttention =
        !!this.currentVisitDetails?.closureReason?.id ||
        !!this.currentVisitDetails?.followUpConsultation?.closureReason?.id ||
        !!this.currentVisitDetails?.followUpConsultation2?.closureReason?.id ||
        !!this.currentVisitDetails?.followUpConsultation3?.closureReason?.id;

      if (
        !this.closedAttention &&
        (this.isOriginalDoctor || this.isFollowUpDoctor)
      ) {
        const minDate = new Date();
        const maxDate = new Date();
        minDate.setHours(0, 0, 0, 1);
        maxDate.setDate(maxDate.getDate() + 1);
        maxDate.setHours(0, 0, 0, 0);
        if (this.visit.hasFollowUp) {
          const { followUpDate, followUpDate2, followUpDate3 } = this.visit;
          const followUpDates = [followUpDate, followUpDate2, followUpDate3];

          const {
            followUpConsultation,
            followUpConsultation2,
            followUpConsultation3,
          } = res.data;

          const followUpConsultations = [
            followUpConsultation,
            followUpConsultation2,
            followUpConsultation3,
          ];

          const lastIndex = followUpDates
            .map((followUpDate) => {
              if (followUpDate) {
                const date = new Date(followUpDate);
                date.setDate(date.getDate() + 1);
                return date >= minDate && date <= maxDate;
              }
              return false;
            })
            .lastIndexOf(true);

          if (followUpDates && followUpDates[lastIndex]) {
            if (
              this.isFollowUpDoctor &&
              lastIndex < 2 &&
              followUpConsultations[lastIndex].status === 5 &&
              followUpConsultations[lastIndex].doctor._id ===
                this.currentDoctorId
            ) {
              this.showScheduleFollowUpAppointmentFormBelow = true;

              if (followUpDates[lastIndex + 1]) {
                this.hasAnAppointmentScheduled = true;

                this.scheduleFollowUpAppointmentForm
                  .get("enableSchedule")
                  .setValue(true);

                const followUpDate = new Date(followUpDates[lastIndex + 1]);
                followUpDate.setDate(followUpDate.getDate() + 1);

                this.scheduleFollowUpAppointmentForm
                  .get("scheduleDate")
                  .setValue(followUpDate);

                this.scheduleDate = followUpDate;
              }
            }
          } else if (this.visit.followUpDate) {
            const visitDate = new Date(this.visit.date);
            if (visitDate >= minDate && visitDate <= maxDate) {
              this.showScheduleFollowUpAppointmentFormAbove = true;
              this.hasAnAppointmentScheduled = true;
              this.scheduleFollowUpAppointmentForm
                .get("enableSchedule")
                .setValue(true);

              const followUpDate = new Date(this.visit.followUpDate);
              followUpDate.setDate(followUpDate.getDate() + 1);
              this.scheduleFollowUpAppointmentForm
                .get("scheduleDate")
                .setValue(followUpDate);

              this.scheduleDate = followUpDate;
            }
          }
        } else {
          const visitDate = new Date(this.visit.date);
          if (visitDate >= minDate && visitDate <= maxDate) {
            this.showScheduleFollowUpAppointmentFormAbove = true;
          }
        }
      }
    });

    this.getUnits();
    this.getRouteofAdmininstration();
    this.getTablete();
    this.getBodySystem();
    this.getClosureReasons();

    this.form = this.fb.group({
      chiefComplaint: ["", [Validators.required]],
      otherChiefComplaint: [""],
      historyOfPresentIllness: ["", [Validators.required, this.noEmptyHTML()]],
      heartRate: [""],
      respiratoryRate: [""],
      temperature: [""],
      bloodPressureSystolic: [""],
      bloodPressureDiastolic: [""],
      paraclinics: [""],
      differentialDiagnosisCie11: [""],
      // diagnosis: ["", [Validators.required, this.noEmptyHTML()]],
      diagnosisCie11: [""],
      plan: ["", [Validators.required, this.noEmptyHTML()]],
      doctorNotes: [""],
      paraclinicalPrescription: this.fb.array([
        this.initParaClinicalPrescription(),
      ]),
      reviewOfSystem: this.fb.array([this.initReviewOfSystem()]),
      medicalPrescription: this.fb.array([this.initX()]),
      followUpForm: this.fb.group({
        subjective: [""],
        objective: [""],
        plan: [""],
        assesment: [""],
        closeAttentionForm: this.closeAttentionForm,
      }),
      followUpForm2: this.fb.group({
        subjective: [""],
        objective: [""],
        plan: [""],
        assesment: [""],
        closeAttentionForm: this.closeAttentionForm,
      }),
      followUpForm3: this.fb.group({
        subjective: [""],
        objective: [""],
        plan: [""],
        assesment: [""],
        closeAttentionForm: this.closeAttentionForm,
      }),
      closeAttentionForm: this.closeAttentionForm,
    });

    this.form.get("paraclinicalPrescription").valueChanges.subscribe((data) => {
      // this.validatePcp();
    });

    this.validatePcp();

    this.reviewOfSystemSubscription$ = this.form
      .get("reviewOfSystem")
      .valueChanges.subscribe((data) => {
        return this.validateReviewOfSystem();
      });
    this.validateReviewOfSystem();

    this.isOriginalDoctor = false;
    this.isFollowUpDoctor = false;
    this.isFollowUpEditEnabled = false;
    this.isFollowUp2EditEnabled = false;
    this.isFollowUp3EditEnabled = false;
    this.enableUpdate = false;

    this.scheduleFollowUpAppointmentForm = this.fb.group({
      enableSchedule: false,
      scheduleDate: ["", Validators.required],
    });

    this.getVisitDetails(id);
  }

  getCheifComplaintsList() {
    this.dashboardService.getChiefComplaintList().subscribe(
      (res: any) => {
        this.chiefComplaintList = res;
        let preselectValue = this.chiefComplaintList.filter(
          (item) => item._id == this.currentVisitDetails.chiefComplaint.id,
        );

        if (preselectValue[0].isOther == true) {
          this.form.patchValue({
            otherChiefComplaint:
              this.currentVisitDetails.chiefComplaint.chiefComplaint.other,
          });

          this.form.patchValue({
            chiefComplaint: this.currentVisitDetails.chiefComplaint.other,
          });
        } else {
          this.form.patchValue({
            chiefComplaint: preselectValue[0].chiefComplaint.es,
          });
        }

        this.form.updateValueAndValidity();

        this.filteredOptions = this.form.controls[
          "chiefComplaint"
        ].valueChanges.pipe(
          startWith(""),
          map((value) => this._filter(this.form.get("chiefComplaint").value)),
        );
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      },
    );
  }

  getNameUpdate(event, index, wichFollowUp) {
    const value = event.target.value;
    let XsA =
      wichFollowUp > 0
        ? <FormArray>(
            this["mpFollowUpForm" + `${wichFollowUp > 1 ? wichFollowUp : ""}`][
              "controls"
            ].medicalPrescription
          )
        : <FormArray>this.form["controls"].medicalPrescription;
    if (value.length > 0) {
      XsA.at(index).enable();
      XsA.at(index).get("frequency").setValidators([Validators.required]);
      XsA.at(index).get("frequency").updateValueAndValidity();
      XsA.at(index).get("frequency").enable();
      XsA.at(index).get("frequencyTime").setValidators([Validators.required]);
      XsA.at(index).get("frequencyTime").updateValueAndValidity();
      XsA.at(index).get("frequencyTime").enable();
      XsA.at(index).get("duration").setValidators([Validators.required]);
      XsA.at(index).get("duration").updateValueAndValidity();
      XsA.at(index).get("duration").enable();
      XsA.at(index).get("durationTime").setValidators([Validators.required]);
      XsA.at(index).get("durationTime").updateValueAndValidity();
      XsA.at(index).get("durationTime").enable();
    } else {
      XsA.at(index).disable();
      XsA.at(index).get("name").enable();
    }
  }

  getAlternateNameUpdate(event, index, alterIndex, wichFollowUp) {
    const value = event.target.value;
    let XsA =
      wichFollowUp > 0
        ? <FormArray>(
            this["mpFollowUpForm" + `${wichFollowUp > 1 ? wichFollowUp : ""}`][
              "controls"
            ].medicalPrescription
          )
        : <FormArray>this.form["controls"].medicalPrescription;
    let alternate = <FormArray>XsA.at(index).get("alternative");
    const currentItem = alternate.at(alterIndex);
    if (currentItem) {
      if (value.length > 0) {
        currentItem.enable();
        currentItem.get("frequency").setValidators([Validators.required]);
        currentItem.get("frequency").updateValueAndValidity();
        currentItem.get("frequency").enable();
        currentItem.get("frequencyTime").setValidators([Validators.required]);
        currentItem.get("frequencyTime").updateValueAndValidity();
        currentItem.get("frequencyTime").enable();
        currentItem.get("duration").setValidators([Validators.required]);
        currentItem.get("duration").updateValueAndValidity();
        currentItem.get("duration").enable();
        currentItem.get("durationTime").setValidators([Validators.required]);
        currentItem.get("durationTime").updateValueAndValidity();
        currentItem.get("durationTime").enable();
      } else {
        currentItem.disable();
        currentItem.get("name").enable();
      }
    }
  }

  getUnitName(ix): string {
    const unitId = <FormArray>(
      this.form["controls"].medicalPrescription["controls"][ix]["value"]["unit"]
    );

    // console.log( this.medicalPrescriptionUnits );

    // const unit = this.medicalPrescriptionUnits.find((x) => x.id === unitId);

    return ""; //unit ? unit.unit[this.currentLanguage] : "";
  }

  getVisitDetails(id: string) {
    this.dashboardService.getConsultationDetails(id).subscribe({
      next: (res: VisitDetails) => {
        const [data] = res.data;

        if (res.data) {
          this.providerConsultId = data.provider;
        }

        let dateAt12am;

        let strDate = "";
        if (!data.hasFollowUp || !data.followUpConsultation) {
          dateAt12am = new Date(data.date);
          strDate = data.date.split("T")[0];
        } else {
          dateAt12am = new Date(data.followUpConsultation.date);
          strDate = data.followUpConsultation.date.split("T")[0];
        }

        dateAt12am = new Date(
          +strDate.split("-")[0],
          +strDate.split("-")[1] - 1,
          +strDate.split("-")[2],
        );
        dateAt12am.setDate(dateAt12am.getDate() + 1);
        dateAt12am.setHours(0, 0, 0, 0);

        if (!data.hasFollowUp || !data.followUpConsultation) {
          this.isEditTimeOver = new Date().getTime() > dateAt12am.getTime();
        } else {
          this.isEditFollowupTimeOver =
            new Date().getTime() > dateAt12am.getTime();
        }

        if (
          data.hasFollowUp &&
          data.followUpConsultation?.date &&
          data.followUpConsultation.followUpForm
        ) {
          const followUpDateAt12Am = new Date(data.followUpConsultation.date);
          followUpDateAt12Am.setDate(followUpDateAt12Am.getDate() + 1);
          followUpDateAt12Am.setHours(0, 0, 0, 0);

          if (new Date().getTime() <= followUpDateAt12Am.getTime()) {
            this.isEditFollowUpInTime = true;
          }
        }

        if (
          data.hasFollowUp &&
          data.followUpConsultation2?.date &&
          data.followUpConsultation2.followUpForm
        ) {
          const followUp2DateAt12Am = new Date(data.followUpConsultation2.date);
          followUp2DateAt12Am.setDate(followUp2DateAt12Am.getDate() + 1);
          followUp2DateAt12Am.setHours(0, 0, 0, 0);

          if (new Date().getTime() <= followUp2DateAt12Am.getTime()) {
            this.isEditFollowUp2InTime = true;
          }
        }

        if (
          data.hasFollowUp &&
          data.followUpConsultation3 &&
          data.followUpConsultation3.date &&
          data.followUpConsultation3.followUpForm
        ) {
          const followUp3DateAt12Am = new Date(data.followUpConsultation3.date);
          followUp3DateAt12Am.setDate(followUp3DateAt12Am.getDate() + 1);
          followUp3DateAt12Am.setHours(0, 0, 0, 0);

          if (new Date().getTime() <= followUp3DateAt12Am.getTime()) {
            this.isEditFollowUp3InTime = true;
          }
        }

        if (data.visitForm?.reviewOfSystem) {
          for (var i = 0; i < data.visitForm.reviewOfSystem.length; i++) {
            data.visitForm.reviewOfSystem[i].bodySystem =
              data.visitForm.reviewOfSystem[i].id;
          }
        }

        if (data.medicalPrescription) {
          for (var j = 0; j < data.medicalPrescription.length; j++) {
            this.medicalPrescriptionUnits.push(
              data.medicalPrescription[j].unit,
            );
            data.medicalPrescription[j].durationTime =
              data.medicalPrescription[j].durationTime.id;
            data.medicalPrescription[j].frequencyTime =
              data.medicalPrescription[j].frequencyTime.id;
            data.medicalPrescription[j].routeOfAdministration = data
              .medicalPrescription[j].routeOfAdministration
              ? data.medicalPrescription[j].routeOfAdministration.id
              : "";
            data.medicalPrescription[j].unit = data.medicalPrescription[j].unit
              ? data.medicalPrescription[j].unit.id
              : "";
            for (
              var k = 0;
              k < data.medicalPrescription[j].alternative.length;
              k++
            ) {
              data.medicalPrescription[j].alternative[k].durationTime =
                data.medicalPrescription[j].alternative[k].durationTime.id;
              data.medicalPrescription[j].alternative[k].frequencyTime =
                data.medicalPrescription[j].alternative[k].frequencyTime.id;
              data.medicalPrescription[j].alternative[k].routeOfAdministration =
                data.medicalPrescription[j].alternative[k].routeOfAdministration
                  ? data.medicalPrescription[j].alternative[k]
                      .routeOfAdministration.id
                  : "";
              data.medicalPrescription[j].alternative[k].unit = data
                .medicalPrescription[j].alternative[k].unit
                ? data.medicalPrescription[j].alternative[k].unit.id
                : "";
            }
          }
          this.medicalPrescriptionUnits = this.medicalPrescriptionUnits.filter(
            (x) => !!x,
          );
        }

        if (data.hasFollowUp) {
          if (data.followUpConsultation?.status == Status.COMPLETED) {
            this.followUpStatus = data.followUpConsultation.status;
            this.hideFollowUpForm = false;
            this.patchFollowUpValues(res);

            if (data.followUpConsultation.medicalPrescription?.length > 0) {
              this.initFollowUpPrescription(data.followUpConsultation);
              this.setExistMedicalPrescription(
                data.followUpConsultation.medicalPrescription,
                1,
              );
              this.mpFollowUpForm.disable();
            }
          }

          if (data.followUpConsultation2?.status == Status.COMPLETED) {
            this.followUpStatus2 = data.followUpConsultation2.status;
            this.hideFollowUpForm2 = false;
            this.patchFollowUpValues(res);
            if (data.followUpConsultation2.medicalPrescription?.length) {
              this.initFollowUpPrescription(data.followUpConsultation2);
              this.setExistMedicalPrescription(
                data.followUpConsultation2.medicalPrescription,
                2,
              );
              this.mpFollowUpForm2.disable();
            }
          }

          if (data.followUpConsultation3?.status == Status.COMPLETED) {
            this.followUpStatus3 = data.followUpConsultation3.status;
            this.hideFollowUpForm3 = false;
            this.patchFollowUpValues(res);
            if (data.followUpConsultation3.medicalPrescription?.length) {
              this.initFollowUpPrescription(data.followUpConsultation3);
              this.setExistMedicalPrescription(
                data.followUpConsultation3.medicalPrescription,
                3,
              );
              this.mpFollowUpForm3.disable();
            }
          }
        }

        this.historyOfPresentIllness = data.visitForm.historyOfPresentIllness;
        this.doctorNotes = data.visitForm.doctorNotes;
        this.paraclinics = data.visitForm.paraclinics;
        this.differentialDiagnosisCie11 = "";
        this.diagnosisCie11 = "";
        ///
        if (data.visitForm.differentialDiagnosisCie11?.length) {
          this.differentialDiagnosisListSelected =
            data.visitForm.differentialDiagnosisCie11;
        }
        if (data.visitForm.diagnosisCie11?.length) {
          this.diagnosticListSelected = data.visitForm.diagnosisCie11;
        }
        /*    console.log(res.data,'AAAAAAAAAAAAAAAAAAAA')
        if(res.data.followUpConsultation.followUpForm.assesmentCie11.length > 0){
        this.assessmentListSelected =res.data.followUpConsultation.followUpForm.assesmentCie11
        } */
        this.plan = data.visitForm.plan;

        const closureReason =
          data?.closureReason?.id ||
          data?.followUpConsultation?.closureReason?.id ||
          data?.followUpConsultation2?.closureReason?.id ||
          data?.followUpConsultation3?.closureReason?.id;

        const otherClosureReason =
          data?.closureReason?.other ||
          data?.followUpConsultation?.closureReason?.other ||
          data?.followUpConsultation2?.closureReason?.other ||
          data?.followUpConsultation3?.closureReason?.other;

        this.isOtherClosureReason = false;

        this.form.patchValue({
          historyOfPresentIllness: data.visitForm.historyOfPresentIllness,
          heartRate: data.visitForm.heartRate,
          respiratoryRate: data.visitForm.respiratoryRate,
          temperature: data.visitForm.temperature,
          bloodPressureSystolic: data.visitForm.bloodPressureSystolic,
          bloodPressureDiastolic: data.visitForm.bloodPressureDiastolic,
          paraclinics: data.visitForm.paraclinics,
          /*   differentialDiagnosisCie11: '',
          diagnosisCie11:'', */
          plan: data.visitForm.plan,
          doctorNotes: data.visitForm.doctorNotes,
        });

        this.closeAttentionForm.patchValue({
          closeAttention: !!closureReason,
          closureReason: closureReason,
          otherClosureReason: otherClosureReason,
        });

        if (data.medicalPrescription?.length > 0) {
          this.setExistMedicalPrescription(data.medicalPrescription, 0);
        }

        if (data.visitForm.reviewOfSystem?.length > 0) {
          this.form.setControl(
            "reviewOfSystem",
            this.setExistReviewSystem(data.visitForm.reviewOfSystem),
          );
          this.reviewOfSystemSubscription$.unsubscribe();
          this.reviewOfSystemSubscription$ = this.form
            .get("reviewOfSystem")
            .valueChanges.subscribe((data) => {
              return this.validateReviewOfSystem();
            });
        }

        if (data.paraclinicalPrescription?.length > 0) {
          this.form.setControl(
            "paraclinicalPrescription",
            this.setExistParaclinicalPrescription(
              data.paraclinicalPrescription,
            ),
          );
        }
        this.form.disable();
        this.closeAttentionForm.disable();
        this.mpFollowUpForm.disable();
        this.mpFollowUpForm2.disable();
        this.mpFollowUpForm3.disable();
        this.form.updateValueAndValidity();
      },
      error: (error: HttpErrorResponse) => {
        this.handleError(error);
      },
    });
  }

  initFollowUpPrescription(followUpConsultation) {
    for (var j = 0; j < followUpConsultation.medicalPrescription.length; j++) {
      followUpConsultation.medicalPrescription[j].durationTime =
        followUpConsultation.medicalPrescription[j].durationTime.id;
      followUpConsultation.medicalPrescription[j].frequencyTime =
        followUpConsultation.medicalPrescription[j].frequencyTime.id;
      followUpConsultation.medicalPrescription[j].routeOfAdministration =
        followUpConsultation.medicalPrescription[j].routeOfAdministration
          ? followUpConsultation.medicalPrescription[j].routeOfAdministration.id
          : "";
      followUpConsultation.medicalPrescription[j].unit = followUpConsultation
        .medicalPrescription[j].unit
        ? followUpConsultation.medicalPrescription[j].unit.id
        : "";
      for (
        var k = 0;
        k < followUpConsultation.medicalPrescription[j].alternative.length;
        k++
      ) {
        followUpConsultation.medicalPrescription[j].alternative[
          k
        ].durationTime =
          followUpConsultation.medicalPrescription[j].alternative[
            k
          ].durationTime.id;
        followUpConsultation.medicalPrescription[j].alternative[
          k
        ].frequencyTime =
          followUpConsultation.medicalPrescription[j].alternative[
            k
          ].frequencyTime.id;
        followUpConsultation.medicalPrescription[j].alternative[
          k
        ].routeOfAdministration = followUpConsultation.medicalPrescription[j]
          .alternative[k].routeOfAdministration
          ? followUpConsultation.medicalPrescription[j].alternative[k]
              .routeOfAdministration.id
          : "";
        followUpConsultation.medicalPrescription[j].alternative[k].unit =
          followUpConsultation.medicalPrescription[j].alternative[k].unit
            ? followUpConsultation.medicalPrescription[j].alternative[k].unit.id
            : "";
      }
    }
  }

  patchFollowUpValues(res) {
    if (res.data.followUpConsultation.followUpForm) {
      this.followUpForm = {
        subjective: res.data.followUpConsultation.followUpForm.subjective,
        objective: res.data.followUpConsultation.followUpForm.objective,
        plan: res.data.followUpConsultation.followUpForm.plan,
        assesment: res.data.followUpConsultation.followUpForm.assesment,
        assesmentCie11: res.data.followUpConsultation.followUpForm
          .assesmentCie11
          ? res.data.followUpConsultation.followUpForm.assesmentCie11
          : [],
      };
      this.form.patchValue({
        followUpForm: {
          subjective: res.data.followUpConsultation.followUpForm.subjective,
          objective: res.data.followUpConsultation.followUpForm.objective,
          plan: res.data.followUpConsultation.followUpForm.plan,
          assesment: res.data.followUpConsultation.followUpForm.assesment,
        },
      });
    }

    if (res.data.followUpConsultation2?.followUpForm) {
      this.followUpForm2 = {
        subjective: res.data.followUpConsultation2.followUpForm.subjective,
        objective: res.data.followUpConsultation2.followUpForm.objective,
        plan: res.data.followUpConsultation2.followUpForm.plan,
        assesment: res.data.followUpConsultation2.followUpForm.assesment,
        assesmentCie11: res.data.followUpConsultation2.followUpForm
          .assesmentCie11
          ? res.data.followUpConsultation2.followUpForm.assesmentCie11
          : [],
      };
      this.form.patchValue({
        followUpForm2: {
          subjective: res.data.followUpConsultation2.followUpForm.subjective,
          objective: res.data.followUpConsultation2.followUpForm.objective,
          plan: res.data.followUpConsultation2.followUpForm.plan,
          assesment: res.data.followUpConsultation2.followUpForm.assesment,
        },
      });
    }

    if (res.data.followUpConsultation3?.followUpForm) {
      this.followUpForm3 = {
        subjective: res.data.followUpConsultation3.followUpForm.subjective,
        objective: res.data.followUpConsultation3.followUpForm.objective,
        plan: res.data.followUpConsultation3.followUpForm.plan,
        assesment: res.data.followUpConsultation3.followUpForm.assesment,
        assesmentCie11: res.data.followUpConsultation3.followUpForm
          .assesmentCie11
          ? res.data.followUpConsultation3.followUpForm.assesmentCie11
          : [],
      };
      this.form.patchValue({
        followUpForm3: {
          subjective: res.data.followUpConsultation3.followUpForm.subjective,
          objective: res.data.followUpConsultation3.followUpForm.objective,
          plan: res.data.followUpConsultation3.followUpForm.plan,
          assesment: res.data.followUpConsultation3.followUpForm.assesment,
        },
      });
    }
  }

  setExistParaclinicalPrescription(list: any[]): FormArray {
    const epp = new FormArray([]);
    list.forEach((s) => {
      epp.push(
        this.fb.group({
          _id: s._id,
          title: s.title,
          description: s.description,
        }),
      );
    });
    return epp;
  }

  setExistReviewSystem(list: any[]): FormArray {
    const fa = new FormArray([]);
    list.forEach((s) => {
      fa.push(
        this.fb.group({
          bodySystem: s.id,
          value: s.value,
        }),
      );
    });
    return fa;
  }

  setExistMedicalPrescription(list: any, wichFollowUp: number) {
    (wichFollowUp > 0
      ? <FormArray>(
          this["mpFollowUpForm" + `${wichFollowUp > 1 ? wichFollowUp : ""}`][
            "controls"
          ].medicalPrescription
        )
      : <FormArray>this.form["controls"].medicalPrescription
    ).clear();
    list.forEach((t) => {
      var mp: FormGroup = this.fb.group({
        _id: [""],
        name: [""],
        dosage: ["", Validators.required],
        unit: [""],
        routeOfAdministration: [""],
        frequency: [""],
        frequencyTime: [""],
        duration: [""],
        durationTime: [""],
        notes: [""],
        alternative: this.fb.array([]),
      });
      (wichFollowUp > 0
        ? <FormArray>(
            this["mpFollowUpForm" + `${wichFollowUp > 1 ? wichFollowUp : ""}`][
              "controls"
            ].medicalPrescription
          )
        : <FormArray>this.form["controls"].medicalPrescription
      ).push(mp);

      t.alternative.forEach((b) => {
        var alter = this.fb.group({
          _id: [""],
          name: [""],
          dosage: [""],
          unit: [""],
          routeOfAdministration: [""],
          frequency: [""],
          frequencyTime: [""],
          duration: [""],
          durationTime: [""],
          notes: [""],
        });

        (mp.get("alternative") as FormArray).push(alter);
      });
    });

    (wichFollowUp > 0
      ? this["mpFollowUpForm" + `${wichFollowUp > 1 ? wichFollowUp : ""}`]
      : this.form
    ).patchValue({
      medicalPrescription: list,
    });
  }

  validateReviewOfSystem() {
    let RoSA = this.form.get("reviewOfSystem") as FormArray;
    this.formErrors.reviewOfSystem = [];

    for (let rr = 0; rr < RoSA.length; rr++) {
      this.formErrors.reviewOfSystem.push({
        bodySystem: "",
        value: "",
      });

      let RR = RoSA.at(rr) as FormGroup;

      if (RR.get("bodySystem").value != "") {
        if (RR.get("value").validator === null) {
          RR.get("value").setValidators([Validators.required]);
          RR.get("value").updateValueAndValidity();
        }
      }

      if (
        RR.get("bodySystem").value == "" ||
        RR.get("bodySystem").value == null ||
        RR.get("bodySystem").value == undefined
      ) {
        if (RR.get("value").validator !== null) {
          RR.get("value").clearValidators();
          RR.get("value").updateValueAndValidity();
        }
      }

      for (let field in RR.controls) {
        let input = RR.get(field);

        if (input.invalid && input.dirty) {
          for (let error in input.errors) {
            this.formErrors.reviewOfSystem[rr][field] =
              this.validationMessages.reviewOfSystem[field][error];
          }
        }
      }
    }
  }

  validatePcp() {
    const PcpA = this.form.get("paraclinicalPrescription") as FormArray;
    this.formErrors.paraclinicalPrescription = [];

    for (let pp = 0; pp < PcpA.length; pp++) {
      this.formErrors.paraclinicalPrescription.push({
        title: "",
        description: "",
      });

      const PP = PcpA.at(pp) as FormGroup;

      if (PP.get("title").value.length > 0) {
        if (PP.get("description").validator === null) {
          PP.get("description").setValidators([Validators.required]);
          PP.get("description").updateValueAndValidity();
        }
      }

      if (PP.get("title").value.length < 1) {
        if (PP.get("description").value.length > 0) {
          PP.get("title").setValidators([Validators.required]);
          PP.get("title").updateValueAndValidity();
        } else {
          PP.get("title").clearValidators();
          PP.get("title").updateValueAndValidity();
        }

        if (PP.get("description").validator !== null) {
          PP.get("description").clearValidators();
          PP.get("description").updateValueAndValidity();
        }
      }

      for (const field in PP.controls) {
        const input = PP.get(field);

        if (input.invalid && input.dirty) {
          for (const error in input.errors) {
            this.formErrors.paraclinicalPrescription[pp][field] =
              this.validationMessages.paraclinicalPrescription[field][error];
          }
        }
      }
    }
  }

  getBodySystem() {
    this.dashboardService.getbodySystem().subscribe(
      (res: any) => {
        this.bodysystem = res;
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      },
    );
  }

  getUnits() {
    this.dashboardService.getUnits().subscribe(
      (res: any) => {
        this.Units = res["data"];
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      },
    );
  }

  getTablete() {
    this.dashboardService.getTimes().subscribe(
      (res: any) => {
        this.tablete = res["data"];
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      },
    );
  }

  getRouteofAdmininstration() {
    this.dashboardService.getRoute().subscribe(
      (res: any) => {
        this.adminstrations = res["data"];
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      },
    );
  }

  getClosureReasons() {
    this.dashboardService.getClosureReasons().subscribe(
      (res: any) => {
        this.closureReasons = res;
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      },
    );
  }

  initReviewOfSystem() {
    return this.fb.group({
      bodySystem: [""],
      value: [""],
    });
  }

  addReviewOfSystem() {
    const control = <FormArray>this.form.controls["reviewOfSystem"];
    control.push(this.initReviewOfSystem());
  }

  removeReviewOfSystem(ir) {
    ((<FormArray>this.form.controls["reviewOfSystem"]) as FormArray).removeAt(
      ir,
    );
  }

  initParaClinicalPrescription() {
    return this.fb.group({
      title: [""],
      description: [""],
    });
  }

  addParaClinicalPrescription() {
    const control = <FormArray>this.form.controls["paraclinicalPrescription"];
    control.push(this.initParaClinicalPrescription());
  }

  removeParaClinicalPrescription(ip, val) {
    if (val.value._id) {
      this.dashboardService
        .removeParaClinicalPrescription(val.value._id)
        .subscribe((res: any) => {
          (
            (<FormArray>(
              this.form.controls["paraclinicalPrescription"]
            )) as FormArray
          ).removeAt(ip);
        });
    } else {
      (
        (<FormArray>this.form.controls["paraclinicalPrescription"]) as FormArray
      ).removeAt(ip);
    }
  }

  initX() {
    return this.fb.group({
      //  ---------------------forms fields on x level ------------------------
      name: [""],
      dosage: [{ value: "", disabled: true }],
      unit: [{ value: "", disabled: true }],
      routeOfAdministration: [{ value: "", disabled: true }],
      frequency: [{ value: "", disabled: true }],
      frequencyTime: [{ value: "", disabled: true }],
      duration: [{ value: "", disabled: true }],
      durationTime: [{ value: "", disabled: true }],
      notes: [{ value: "", disabled: true }],
      // ---------------------------------------------------------------------
      alternative: this.fb.array([
        //this.initY()
      ]),
    });
  }

  initY() {
    return this.fb.group({
      //  ---------------------forms fields on y level ------------------------
      name: [""],
      dosage: [{ value: "", disabled: true }],
      unit: [{ value: "", disabled: true }],
      routeOfAdministration: [{ value: "", disabled: true }],
      frequency: [{ value: "", disabled: true }],
      frequencyTime: [{ value: "", disabled: true }],
      duration: [{ value: "", disabled: true }],
      durationTime: [{ value: "", disabled: true }],
      notes: [{ value: "", disabled: true }],
      // ---------------------------------------------------------------------
    });
  }

  addX(wichFollowUp) {
    const control =
      wichFollowUp > 0
        ? <FormArray>(
            this["mpFollowUpForm" + `${wichFollowUp > 1 ? wichFollowUp : ""}`][
              "controls"
            ].medicalPrescription
          )
        : <FormArray>this.form["controls"].medicalPrescription;
    control.push(this.initX());
  }

  addY(ix, wichFollowUp) {
    const control = (
      wichFollowUp > 0
        ? <FormArray>(
            this["mpFollowUpForm" + `${wichFollowUp > 1 ? wichFollowUp : ""}`][
              "controls"
            ].medicalPrescription
          )
        : <FormArray>this.form["controls"].medicalPrescription
    )
      .at(ix)
      .get("alternative") as FormArray;
    control.push(this.initY());
  }

  formErrors = {
    medicalPrescription: this.medicalPrescriptionErrors(),
    paraclinicalPrescription: this.paraclinicalPrescriptionErrors(),
    reviewOfSystem: this.reviewOfSystemErrors(),
  };

  reviewOfSystemErrors() {
    return [
      {
        bodySystem: "",
        value: "",
      },
    ];
  }

  paraclinicalPrescriptionErrors() {
    return [
      {
        title: "",
        description: "",
      },
    ];
  }

  medicalPrescriptionErrors() {
    return [
      {
        //  ---------------------forms errors on x level ------------------------
        name: "",
        dosage: "",
        unit: "",
        routeOfAdministration: "",
        frequency: "",
        frequencyTime: "",
        duration: "",
        durationTime: "",
        notes: "",

        // ---------------------------------------------------------------------
        alternative: this.alternativeErrors(),
      },
    ];
  }

  alternativeErrors() {
    return [
      {
        //  ---------------------forms errors on y level ------------------------
        name: "",
        dosage: "",
        unit: "",
        routeOfAdministration: "",
        frequency: "",
        frequencyTime: "",
        duration: "",
        durationTime: "",
        notes: "",
        // ----------------------------------------------------------------------
      },
    ];
  }

  validationMessages = {
    medicalPrescription: {
      name: {
        required: "name is required.",
      },
      dosage: {
        required: "dosage is required.",
      },
      unit: {
        required: "unit is required.",
        pattern: "unit must be 3 characters long.",
      },
      routeOfAdministration: {
        required: "routeOfAdministration is required.",
        pattern: "routeOfAdministration must be 3 characters long.",
      },
      frequency: {
        required: "frequency is required.",
        pattern: "frequency must be 3 characters long.",
      },
      duration: {
        required: "duration is required.",
        pattern: "duration must be 3 characters long.",
      },
      frequencyTime: {
        required: "frequencyTime is required.",
        pattern: "frequencyTime must be 3 characters long.",
      },
      durationTime: {
        required: "durationTime is required.",
        pattern: "durationTime must be 3 characters long.",
      },
      notes: {
        required: "notes is required.",
        pattern: "notes must be 3 characters long.",
      },
      alternative: {
        name: {
          required: "name is required.",
        },
        dosage: {
          required: "dosage is required.",
        },
        unit: {
          required: "unit is required.",
          pattern: "unit must be 3 characters long.",
        },
        routeOfAdministration: {
          required: "routeOfAdministration is required.",
          pattern: "routeOfAdministration must be 3 characters long.",
        },
        frequency: {
          required: "frequency is required.",
          pattern: "frequency must be 3 characters long.",
        },
        duration: {
          required: "duration is required.",
          pattern: "duration must be 3 characters long.",
        },
        frequencyTime: {
          required: "frequencyTime is required.",
          pattern: "frequencyTime must be 3 characters long.",
        },
        durationTime: {
          required: "durationTime is required.",
          pattern: "durationTime must be 3 characters long.",
        },
        notes: {
          required: "notes is required.",
          pattern: "notes must be 3 characters long.",
        },
      },
    },
    paraclinicalPrescription: {
      title: {
        title: "title is required.",
      },
      description: {
        description: "description is required.",
      },
    },
    reviewOfSystem: {
      bodySystem: {
        bodySystem: "bodySystem is required.",
      },
      value: {
        value: "value is required.",
      },
    },
  };

  removeY(ix, iy, val, wichFollowUp) {
    if (val.value._id) {
      this.dashboardService
        .removeMedicine(val.value._id)
        .subscribe((res: any) => {
          (
            (wichFollowUp > 0
              ? <FormArray>(
                  this[
                    "mpFollowUpForm" + `${wichFollowUp > 1 ? wichFollowUp : ""}`
                  ]["controls"].medicalPrescription
                )
              : <FormArray>this.form["controls"].medicalPrescription
            )
              .at(ix)
              .get("alternative") as FormArray
          ).removeAt(iy);
        });
    } else {
      (
        (wichFollowUp > 0
          ? <FormArray>(
              this[
                "mpFollowUpForm" + `${wichFollowUp > 1 ? wichFollowUp : ""}`
              ]["controls"].medicalPrescription
            )
          : <FormArray>this.form["controls"].medicalPrescription
        )
          .at(ix)
          .get("alternative") as FormArray
      ).removeAt(iy);
    }
  }

  removeX(ix, val, wichFollowUp) {
    if (val.value._id) {
      this.dashboardService
        .removeMedicine(val.value._id)
        .subscribe((res: any) => {
          (
            (<FormArray>(
              (wichFollowUp > 0
                ? <FormArray>(
                    this[
                      "mpFollowUpForm" +
                        `${wichFollowUp > 1 ? wichFollowUp : ""}`
                    ]["controls"].medicalPrescription
                  )
                : <FormArray>this.form["controls"].medicalPrescription)
            )) as FormArray
          ).removeAt(ix);
        });
    } else {
      (
        (<FormArray>(
          (wichFollowUp > 0
            ? <FormArray>(
                this[
                  "mpFollowUpForm" + `${wichFollowUp > 1 ? wichFollowUp : ""}`
                ]["controls"].medicalPrescription
              )
            : <FormArray>this.form["controls"].medicalPrescription)
        )) as FormArray
      ).removeAt(ix);
    }
  }

  formSubmit() {
    this.form.enable();

    this.setPlan(this.form.get("plan").value);
    /*     this.setDiagnosis(this.form.get("diagnosisCie11").value); */
    if (this.diagnosticListSelected.length === 0) {
      this.form.get("diagnosisCie11").setErrors({ required: true });
    }
    this.setHistoryOfPresentIllness(
      this.form.get("historyOfPresentIllness").value,
    );

    if (this.form.invalid) {
      this.translateService
        .get("Please fill the required fields in visit summary form")
        .subscribe((text: string) => {
          this.sharedService.showErrorMessage(text);
        });

      if (this.anyFollowUpInTimeForEdit) {
        this.form.disable();
        this.closeAttentionForm.enable();
      }
      this.form.markAllAsTouched();
      return;
    } else {
      let ppList = this.form.value["paraclinicalPrescription"];
      for (var f = 0; f < ppList.length; f++) {
        if (ppList[f].title && !ppList[f].description) {
          this.translateService
            .get("Please enter description in Paraclinical Prescription")
            .subscribe((text: string) => {
              this.sharedService.showErrorMessage(text);
            });
          return;
        } else if (!ppList[f].title && ppList[f].description) {
          this.translateService
            .get("Please enter title in Paraclinical Prescription")
            .subscribe((text: string) => {
              this.sharedService.showErrorMessage(text);
            });
          return;
        }
      }

      let ppp = this.form.value["paraclinicalPrescription"].filter(
        (item) => item.title != "",
      );
      let ros = this.form.value["reviewOfSystem"].filter(
        (item) => item.bodySystem != "",
      );

      let mpl = this.form.value["medicalPrescription"];
      mpl = this.treatMedicalPrescriptions(mpl);

      let data = this.form.value;
      const pattern = /^\d{0,3}(\.\d{0,2})?$/;
      if (data.temperature && !pattern.test(data.temperature)) {
        this.translateService
          .get("Enter the valid temperature.")
          .subscribe((text: string) => {
            this.sharedService.showErrorMessage(text);
          });
        return;
      }
      data["paraclinicalPrescription"] = ppp;
      data["reviewOfSystem"] = ros;
      data["medicalPrescription"] = mpl;

      let selectedChiefComplaint = this.chiefComplaintList.find(
        (chiefComplaint) => {
          return (
            this.form.value["chiefComplaint"] ===
            chiefComplaint.chiefComplaint.es
          );
        },
      );

      if (!selectedChiefComplaint) {
        selectedChiefComplaint = this.chiefComplaintList.find(
          (chiefComplaint) => {
            return chiefComplaint.chiefComplaint.es === "Otro";
          },
        );

        data["otherChiefComplaint"] = this.form.value["chiefComplaint"];
      }

      data["chiefComplaint"] = selectedChiefComplaint?._id;

      let apiData = this.removeEmptyKeys(data);

      let idToUpdate = this.consultId;

      if (this.isOriginalDoctor) {
        idToUpdate = this.consultId;
      }

      if (this.isFollowUpDoctor) {
        if (this.isEditFollowUpInTime) {
          idToUpdate = this.currentVisitDetails.followUpConsultation._id;
          let followUpMpl =
            this.mpFollowUpForm["controls"].medicalPrescription.value;
          followUpMpl = this.treatMedicalPrescriptions(followUpMpl);
          data["medicalPrescription"] = followUpMpl;
        } else if (this.isEditFollowUp2InTime) {
          idToUpdate = this.currentVisitDetails.followUpConsultation2._id;
          let followUpMpl =
            this.mpFollowUpForm2["controls"].medicalPrescription.value;
          followUpMpl = this.treatMedicalPrescriptions(followUpMpl);
          data["medicalPrescription"] = followUpMpl;
          data["followUpForm"] = data["followUpForm2"];
        } else {
          idToUpdate = this.currentVisitDetails.followUpConsultation3._id;
          let followUpMpl =
            this.mpFollowUpForm3["controls"].medicalPrescription.value;
          followUpMpl = this.treatMedicalPrescriptions(followUpMpl);
          data["medicalPrescription"] = followUpMpl;
          data["followUpForm"] = data["followUpForm3"];
        }
        apiData = this.removeEmptyKeys(apiData);
      }

      if (this.followUpStatus != 5) {
        delete data["followUpForm"];
        delete data["followUpForm2"];
        delete data["followUpForm3"];
      }

      if (
        this.showScheduleFollowUpAppointmentFormAbove ||
        this.showScheduleFollowUpAppointmentFormBelow
      ) {
        if (this.scheduleDate) {
          const scheduleDate = moment(this.scheduleDate);

          if (scheduleDate.isValid()) {
            apiData.updateFollowUpDate = scheduleDate.format("YYYY-MM-DD");
          }
        }
      }

      if (apiData.closeAttentionForm) {
        apiData = {
          ...apiData,
          ...apiData.closeAttentionForm,
        };

        delete apiData["closeAttentionForm"];

        if (!apiData.closeAttention) {
          apiData.deleteClosureReason = true;
        }
      }

      const formatData = this.formatDataToSend(apiData);

      this.dashboardService
        .updateVisitHistory(idToUpdate, formatData)
        .subscribe(
          (res: any) => {
            this.enableUpdate = false;
            this.form.disable();
            this.translateService
              .get("Visit History details updated successfully")
              .subscribe((text: string) => {
                this.sharedService.showMessage(text, "success");
              });
            this.getVisitDetails(this.visit._id);
            this.dashboardService.historyUpdateSubject.next({
              updated: "success",
            });
          },
          (error: HttpErrorResponse) => {
            this.handleError(error);
          },
        );
    }

    if (this.form.valid) {
      this.form.disable();
    }
  }

  scheduleFollowUpAppointmentFormSubmit() {
    if (this.scheduleDate) {
      const scheduleDate = moment(this.scheduleDate);

      if (scheduleDate.isValid()) {
        this.formSubmit();
        this.form.disable();
      }
    }
  }

  treatMedicalPrescriptions(mpl) {
    if (mpl.filter((item) => item.name === "" && item.frequency).length > 0) {
      this.translateService
        .get("Medicine name is required")
        .subscribe((text: string) => {
          this.sharedService.showErrorMessage(text);
        });
      return;
    } else {
      mpl = mpl.filter((item) => item.name != "");
      mpl = mpl.map((x) => ({
        ...x,
        frequency: parseInt(x.frequency),
        duration: parseInt(x.duration),
      }));
    }

    for (var i = 0; i < mpl.length; i++) {
      mpl[i] = Object.entries(mpl[i]).reduce(
        (a, [k, v]) => (v ? ((a[k] = v), a) : a),
        {},
      ); //Remove empty values
      let alternative =
        mpl[i].alternative && mpl[i].alternative.length > 0
          ? mpl[i].alternative.filter((item) => item.name != "")
          : [];
      alternative = alternative.map((x) => ({
        ...x,
        frequency: parseInt(x.frequency),
        duration: parseInt(x.duration),
      }));
      alternative = alternative.map((aItem) => {
        aItem = Object.entries(aItem).reduce(
          (a, [k, v]) => (v ? ((a[k] = v), a) : a),
          {},
        ); //Remove empty values
        return aItem;
      });
      if (alternative.filter((item) => item.name === "").length > 0) {
        this.translateService
          .get("Medicine name is required")
          .subscribe((text: string) => {
            this.sharedService.showErrorMessage(text);
          });
        return;
      } else {
        mpl[i].alternative = alternative;
      }
    }

    return mpl;
  }

  removeEmptyKeys(obj) {
    for (var propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === "" ||
        obj[propName].length < 1
      ) {
        delete obj[propName];
      }
    }
    return obj;
  }

  _keyPress(event: any) {
    const pattern = /^\d{0,3}(\.\d{0,2})?$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  enableUpdateBtn() {
    this.enableUpdateButton = true;
    if (this.isOriginalDoctor && !this.isEditTimeOver) {
      this.form.enable();
      this.enableUpdate = true;
      try {
        //TODO: This is actually a very worst work around I could ever think in this situation.
        const isEmpty =
          this.form.controls["medicalPrescription"].value.length === 1 &&
          this.form.controls["medicalPrescription"].value[0].name === "";

        this.form.controls["medicalPrescription"].value[0];
        if (isEmpty) {
          this.form.controls["medicalPrescription"].disable();
          this.form.controls["medicalPrescription"]["controls"][0]["controls"][
            "name"
          ].enable();
        }
      } catch (e) {
        console.log(e);
      }

      // if (!this.isEditFollowupTimeOver) {
      //   this.isFollowUpEditEnabled = true;
      // }
    }

    if (this.isFollowUpDoctor) {
      if (this.isEditFollowUpInTime) {
        this.isFollowUpEditEnabled = true;
        this.closeAttentionForm.enable();
        this.mpFollowUpForm.enable();
      }

      if (this.isEditFollowUp2InTime) {
        this.isFollowUp2EditEnabled = true;
        this.closeAttentionForm.enable();
        this.mpFollowUpForm2.enable();
      }

      if (this.isEditFollowUp3InTime) {
        this.isFollowUp3EditEnabled = true;
        this.closeAttentionForm.enable();
        this.mpFollowUpForm3.enable();
      }
    }
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          let v = error.error.errors.messages[key];
          if (typeof v === "object") {
            for (let key in v) {
              if (key === "unit") {
                this.translateService
                  .get("Unit is required for dosage.")
                  .subscribe((text: string) => {
                    this.sharedService.showErrorMessage(text);
                  });
                return;
              } else {
                values.push(v[key]);
              }
            }
          } else {
            values.push(v);
          }
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }

  documentsGetImage(data) {
    var url = new URL(data.url);
    var path = url.pathname;
    const imageExtension = path.split(".").pop().toLowerCase();
    if (imageExtension === "png") {
      return "png";
    }
    if (imageExtension === "jpg" || imageExtension === "jpeg") {
      return "jpg";
    }
    if (imageExtension === "pdf") {
      return "pdf";
    }
    if (imageExtension === "docx" || imageExtension === "doc") {
      return "doc";
    }
    if (
      imageExtension === "xls" ||
      imageExtension === "xlsx" ||
      imageExtension === "csv"
    ) {
      return "xsl";
    }
  }

  attachmentPopup(attachment) {
    const dialogRef = this.dialog.open(AttachmentPopupComponent, {
      width: "600px",
      maxHeight: "500px",
      data: { document: attachment._id, status: false },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  async downloadMedicalReport() {
    const params = {
      consultation: this.consultId,
      provider: this.providerConsultId,
      parent: this.patientDetials?.parent?._id || this.patientId,
      patient: this.patientId,
      type: 2, // PDF
      file: 2, // Medical report
      apps: false,
    };

    this.dashboardService.downloadMedicalPrescription(params).subscribe({
      next: (res: any) => {
        this.downloadFile(res.filename, res.data);
      },
      error: (error: HttpErrorResponse) => {
        this.handleError(error);
      },
    });
  }

  downloadMedicalPrescription() {
    if (this.currentVisitDetails?.medicalPrescription?.length >= 1) {
      const params = {
        provider: this.providerConsultId,
        consultation: this.consultId,
        parent: this.patientDetials?.parent?._id || this.patientId,
        patient: this.patientId,
        type: 2, // PDF
        file: 1, // Medical Prescription
        apps: false,
      };

      this.dashboardService.downloadMedicalPrescription(params).subscribe({
        next: (res: any) => {
          this.downloadFile(res.filename, res.data);
        },
        error: (error: HttpErrorResponse) => {
          this.handleError(error);
        },
      });
    } else {
      return false;
    }
  }

  downloadMedicalReportAndPrescription() {
    this.downloadMedicalReport();
    this.downloadMedicalPrescription();
  }

  downloadFile(filename, res) {
    const uint8Array = new Uint8Array(res.data);
    const blob = new Blob([uint8Array], { type: "application/pdf" });

    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = filename;
    // start download
    a.click();
  }

  openScheduleFollowUpConsultation() {
    const dialogRef = this.dialog.open(ScheduleFollowUpConsultationComponent, {
      width: "480px",
      disableClose: true,
      autoFocus: false,
      panelClass: "schedule-follow-up-dialog",
      data: {
        action: "update",
        consultId: this.consultId,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.event && result.event === "update") {
        this.scheduleDate = result?.data?.followUpDate;
      }
    });
  }

  closeAttentionChange(event) {
    const closureReasonControl = this.closeAttentionForm.get("closureReason");
    closureReasonControl.reset();
    closureReasonControl.setValidators([]);
    closureReasonControl.updateValueAndValidity();

    const otherClosureReasonControl =
      this.closeAttentionForm.get("otherClosureReason");
    otherClosureReasonControl.reset();
    otherClosureReasonControl.setValidators([]);
    otherClosureReasonControl.updateValueAndValidity();

    if (event && event.checked) {
      closureReasonControl.setValidators([Validators.required]);
      closureReasonControl.updateValueAndValidity();

      this.isOtherClosureReason = false;
    }
  }

  closureReasonChange(event) {
    if (event && event.value) {
      const closureReason = this.closureReasons.find((closureReason) => {
        return closureReason._id === event.value;
      });

      this.isOtherClosureReason = closureReason && closureReason.isOther;
    } else {
      this.isOtherClosureReason = false;
    }

    const otherClosureReasonControl =
      this.closeAttentionForm.get("otherClosureReason");
    otherClosureReasonControl.reset();

    if (this.isOtherClosureReason) {
      otherClosureReasonControl.setValidators([
        Validators.required,
        Validators.maxLength(35),
      ]);
      otherClosureReasonControl.updateValueAndValidity();
    } else {
      otherClosureReasonControl.setValidators([]);
      otherClosureReasonControl.updateValueAndValidity();
    }
  }

  closureReasonOpened() {
    this.closureReasonSelectIsOpen = true;
    // this.closeAttentionForm.get("closureReason").setValue(null);
    this.closeAttentionChange({ checked: true });
  }

  get subscription(): string {
    return (
      this.patientDetials?.subscription?.provider?.id ??
      this.patientDetials?.parent?.subscription?.provider?.id ??
      "Sin membresía"
    );
  }

  get isTelecarrier(): boolean {
    return !!this.patientDetials?.subscription?.provider?.provider;
  }

  get providerId(): string {
    return this.subscription === "Sin membresía" || this.isTelecarrier
      ? environment.organizationId
      : this.subscription;
  }

  formatDataToSend(data: any) {
    const formattedData = {
      ...data,
      diagnosisCie11: this.diagnosticListSelected.map(
        (diagnosis) => diagnosis._id,
      ),
      differentialDiagnosisCie11: this.differentialDiagnosisListSelected.map(
        (diagnosis) => diagnosis._id,
      ),
    };

    if (data.followUpForm) {
      formattedData.followUpForm = {
        subjective: data.followUpForm?.subjective ?? "",
        objective: data.followUpForm?.objective ?? "",
        assesmentCie11:
          this.followUpForm.assesmentCie11.length > 0
            ? this.followUpForm.assesmentCie11.map((diagnosis) => diagnosis._id)
            : "",
        plan: data.followUpForm?.plan ?? "",
      };
    }
    if (data.followUpForm2 && this.followUpForm2.assesmentCie11.length > 0) {
      formattedData.followUpForm2 = {
        subjective: data.followUpForm2?.subjective ?? "",
        objective: data.followUpForm2?.objective ?? "",
        assesmentCie11: this.followUpForm2.assesmentCie11.map(
          (diagnosis) => diagnosis._id,
        ),
        plan: data.followUpForm2?.plan ?? "",
      };
      formattedData.followUpForm.assesmentCie11 =
        this.followUpForm2.assesmentCie11.map((diagnosis) => diagnosis._id);
    }
    if (data.followUpForm3 && this.followUpForm3.assesmentCie11.length > 0) {
      formattedData.followUpForm.assesmentCie11 =
        this.followUpForm3.assesmentCie11.length > 0
          ? this.followUpForm3.assesmentCie11.map((diagnosis) => diagnosis._id)
          : "";
      formattedData.followUpForm3 = {
        subjective: data.followUpForm3?.subjective ?? "",
        objective: data.followUpForm3?.objective ?? "",
        assesmentCie11: this.followUpForm3.assesmentCie11.map(
          (diagnosis) => diagnosis._id,
        ),
        plan: data.followUpForm3?.plan ?? "",
      };
      formattedData.followUpForm.assesmentCie11 =
        this.followUpForm3.assesmentCie11.map((diagnosis) => diagnosis._id);
    }
    return formattedData;
  }

  onChangeDifferentDiagnosisCie11(event) {
    if (event.length > 2) {
      const selectedDiagnosisSet = new Set(
        this.differentialDiagnosisListSelected.map((diag) => diag._id),
      );
      this.dashboardService.getDiagnosticList(event).subscribe({
        next: (res: any) => {
          this.differentialDiagnosisListFiltered = res.data.filter(
            (diagnosis) => {
              return !selectedDiagnosisSet.has(diagnosis._id);
            },
          );
        },
        error: (error: HttpErrorResponse) => {
          this.handleError(error);
        },
      });
    }
  }

  onChangeDiagnosisCie11(event) {
    if (event.length > 2) {
      const selectedDiagnosisSet = new Set(
        this.diagnosticListSelected.map((diag) => diag._id),
      );
      this.dashboardService.getDiagnosticList(event).subscribe({
        next: (res: any) => {
          this.diagnosticListFiltered = res.data.filter((diagnosis) => {
            return !selectedDiagnosisSet.has(diagnosis._id);
          });
        },
        error: (error: HttpErrorResponse) => {
          this.handleError(error);
        },
      });
    }
  }

  addDiagnosis(diagnosis: any, diagnosisArray: any[], control: string) {
    diagnosisArray.push(diagnosis);
    this.diagnosticListFiltered = [];
    this.differentialDiagnosisListFiltered = [];
    this.form.get(control).setValue("", { emitEvent: false });
  }

  removeDiagnosis(diagnosisId: string) {
    this.diagnosticListSelected = this.diagnosticListSelected.filter(
      (diagnosis) => diagnosis._id != diagnosisId,
    );
    if (this.diagnosticListSelected.length === 0) {
      this.form.get("diagnosisCie11").setErrors({ required: true });
    }
  }
  removeDiffDiagnosis(diagnosisId: string) {
    this.differentialDiagnosisListSelected =
      this.differentialDiagnosisListSelected.filter(
        (diagnosis) => diagnosis._id != diagnosisId,
      );
  }

  transformTitle(title: string) {
    if (title.length > 150) {
      return title.substring(0, 150) + "...";
    } else {
      return title;
    }
  }

  //// assessment
  /*   onChange(event){
    
    
      if(event.length > 2){
        const selectedDiagnosisSet = new Set(this.assessmentListSelected.map(diag => diag._id));
       this.dashboardService.getDiagnosticList(event).subscribe({
        next:(res: any) => {
      
         this.assessmentListFiltered = res.data.filter((diagnosis) => {
          return !selectedDiagnosisSet.has(diagnosis._id);
        })
        },
        error:(error: HttpErrorResponse) => {
          this.handleError(error);
        }})
      }  
  } */
  addAssessment(diagnosis: any, diagnosisArray: any[]) {
    diagnosisArray.push(diagnosis);
    this.assessmentListFiltered = [];
    this.assessmentValue = "";
  }

  /*   removeAssessment(diagnosisId:string) {
    
    this.assessmentListSelected = this.assessmentListSelected.filter((diagnosis) => diagnosis._id != diagnosisId)
  } */
  removeAssessmentFollowUp(diagnosisId: string, diagnosisArray: string) {
    if (diagnosisArray === "followUpForm") {
      this.followUpForm.assesmentCie11 =
        this.followUpForm.assesmentCie11.filter(
          (diagnosis) => diagnosis._id != diagnosisId,
        );
    }
    if (diagnosisArray === "followUpForm2") {
      this.followUpForm2.assesmentCie11 =
        this.followUpForm2.assesmentCie11.filter(
          (diagnosis) => diagnosis._id != diagnosisId,
        );
    }
    if (diagnosisArray === "followUpForm3") {
      this.followUpForm3.assesmentCie11 =
        this.followUpForm3.assesmentCie11.filter(
          (diagnosis) => diagnosis._id != diagnosisId,
        );
    }
  }

  onChangeFollowUp(event, diagnosisArray: any[]) {
    if (event.length > 2) {
      const selectedDiagnosisSet = new Set(
        diagnosisArray.map((diag) => diag._id),
      );
      this.dashboardService.getDiagnosticList(event).subscribe({
        next: (res: any) => {
          this.assessmentListFiltered = res.data.filter((diagnosis) => {
            return !selectedDiagnosisSet.has(diagnosis._id);
          });
        },
        error: (error: HttpErrorResponse) => {
          this.handleError(error);
        },
      });
    }
  }
}
