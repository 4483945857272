import {
  Component,
  ViewChild,
  forwardRef,
  Input,
  ElementRef,
  AfterViewInit,
  OnInit,
} from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import SignaturePad from "signature_pad";

@Component({
  selector: "signature-field",
  templateUrl: "signature-field.component.html",
  styleUrls: ["signature-field.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SignatureFieldComponent),
      multi: true,
    },
  ],
})
export class SignatureFieldComponent {
  //@ViewChild(SignaturePad) signaturePad: SignaturePad;

  signatureNeeded!: boolean;
  @ViewChild("canvas") canvasEl!: ElementRef;
  signatureImg!: string;
  @Input() options: Object = {};
  @Input() resizeSignaturePad = () => {};
  public _signature: any = null;

  public propagateChange: Function = null;

  get signature(): any {
    return this._signature;
  }
  signaturePad!: SignaturePad;

  constructor() {}

  ngOnInit(): void {}
  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);

    this.canvasEl.nativeElement.addEventListener("click", (event) => {
      if (!this.signaturePad.isEmpty()) {
        this.signature = this.signaturePad.toDataURL("image/png", 0.5);
      }
    });
    this.canvasEl.nativeElement.addEventListener("mouseleave", (event) => {
      if (!this.signaturePad.isEmpty()) {
        this.signature = this.signaturePad.toDataURL("image/png", 0.5);
      }
    });
  }
  clearPad() {
    this.signaturePad.clear();
  }

  savePad() {
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data;
    this.signatureNeeded = this.signaturePad.isEmpty();
    if (!this.signatureNeeded) {
      this.signatureNeeded = false;
    }
  }

  set signature(value: any) {
    this._signature = value;
    this.propagateChange(this.signature);
  }

  public writeValue(value: any): void {
    if (!value) {
      return;
    }
    this._signature = value;
    this.signaturePad.fromDataURL(this.signature);
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(): void {
    // no-op
  }

  public drawComplete(): void {
    this.signature = this.signaturePad.toDataURL("image/png", 0.5);
  }

  public clear(): void {
    this.signaturePad.clear();
    this.signature = "";
  }
}
