export enum AvailabilityStatus {
  AVAILABLE = 1,
  UNAVAILABLE = 2,
}

export enum DoctorType {
  INTERNAL = 1,
  EXTERNAL = 2,
}

export enum DoctorRole {
  GENERAL = 1,
  SPECIALIST = 2,
}
