import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { SocketEvent, SocketEvents, SocketioService } from "./socketio.service";

@Injectable({
  providedIn: "root",
})
export class ConnectionService {
  messages: Subject<SocketEvent>;

  constructor(private socketService: SocketioService) {
    this.messages = this.socketService.connect();
  }

  sendMsg(event: SocketEvents) {
    this.socketService.emit(event);
  }
}
