import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MessageComponent } from "./message/message.component";
import { MultipleerrormessageComponent } from "./multipleerrormessage/multipleerrormessage.component";
import { ErrormessageComponent } from "./errormessage/errormessage.component";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  constructor(public snackBar: MatSnackBar) {}

  showMessage(messages: string, panelClass: string) {
    this.snackBar.openFromComponent(MessageComponent, {
      data: messages,
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: 10000,
      panelClass: panelClass,
    });
  }
  showMultipleErrors(message: any, panelClass: string) {
    this.snackBar.openFromComponent(MultipleerrormessageComponent, {
      data: message,
      panelClass: panelClass,
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: 10000,
    });
  }

  showErrorMessage(msg: string, panelClass: string = 'single') {
    console.log("msg single", msg);
    
    this.snackBar.openFromComponent(ErrormessageComponent, {
      data: msg,
      panelClass: panelClass,
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: 10000,
    });
  }
}
