<div class="reset-container">
  <div class="container">
    <div style="text-align: center">
      <h4 class="heading">{{ "Set New Password" | translate }}</h4>
    </div>
    <!-- <p class="subheading">Please enter your email below</p> -->
    <mat-card class="reset-card" *ngIf="!hideForm">
      <form
        [formGroup]="changepasswordForm"
        (ngSubmit)="changePasswordSubmit()"
      >
        <p
          style="
            text-align: initial;
            color: #46535b;
            padding-left: 2.5%;
            font-weight: 600;
          "
        >
          {{
            "Please enter your new password and confirm password." | translate
          }}
        </p>
        <mat-form-field class="app-form-field" style="width: 95%">
          <mat-label>{{ "Old Password" | translate }}</mat-label>
          <input type="password" matInput formControlName="oldPassword" />
          <mat-error
            *ngIf="changepasswordForm.get('oldPassword').hasError('required')"
          >
            {{ "Old Password is Required" | translate }}
          </mat-error>
        </mat-form-field>
        <br />
        <mat-form-field class="app-form-field" style="width: 95%">
          <mat-label>{{ "New Password" | translate }}</mat-label>
          <input type="password" matInput formControlName="password" />

          <mat-error
            *ngIf="changepasswordForm.get('password').hasError('required')"
          >
            {{ "Password is Required." | translate }}
          </mat-error>
          <mat-error
            *ngIf="
              changepasswordForm.get('password').hasError('pattern') &&
              !changepasswordForm.get('password').hasError('minlength') &&
              !changepasswordForm.get('password').hasError('maxlength')
            "
          >
            {{ "Minimum 8 characters (1 numeric and 1 alphabet)" | translate }}
          </mat-error>
          <mat-error
            *ngIf="changepasswordForm.get('password').hasError('minlength')"
          >
            {{ "Password should be minimum of 8 characters." | translate }}
          </mat-error>
          <mat-error
            *ngIf="changepasswordForm.get('password').hasError('maxlength')"
          >
            {{ "Password should be maximum of 15 characters." | translate }}
          </mat-error>
        </mat-form-field>
        <br />
        <mat-form-field class="app-form-field" style="width: 95%">
          <mat-label>{{ "Confirm Password" | translate }}</mat-label>
          <input type="password" matInput formControlName="confirmPassword" />
          <mat-error
            *ngIf="
              changepasswordForm.get('confirmPassword').hasError('required')
            "
          >
            {{ "Confirm Password is Required." | translate }}
          </mat-error>
          <mat-error
            *ngIf="
              changepasswordForm.get('confirmPassword').hasError('mustMatch')
            "
          >
            {{ "Password and confirm password do not match" | translate }}
          </mat-error>
        </mat-form-field>
        <br /><br />

        <p *ngIf="showError" style="font-weight: bold; color: red">
          {{ errorMsg }}
        </p>

        <div class="reset-button-row">
          <button
            [disabled]="loading"
            mat-raised-button
            class="large-button-blue medium-width"
          >
            {{ "Change" | translate }}
          </button>
        </div>
      </form>
    </mat-card>
  </div>
</div>
