import {
  Component,
  OnInit,
  OnDestroy,
  NgZone,
  ChangeDetectorRef,
} from "@angular/core";
import { CredentialsService, QbDetails } from "../core/credentials.service";
import { qbEndpoints } from "../core/qb.conf";
import { QbService } from "./qb.service";
import { BnNgIdleService } from "bn-ng-idle";
import { UserIdleService } from "angular-user-idle";
import { DashboardService } from "./dashboard.service";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { AuthenticationService } from "../core/authentication.service";
import { EMPTY, Subscription } from "rxjs";
import { catchError, concatMap, switchMap, tap } from "rxjs/operators";
/* import { PushNotificationsService } from "ng-push-ivy"; */
import { ConnectionService } from "./connection.service";
import { CallStateService } from "../shared/call-state/call-state.service";
import { SocketEvents } from "./socketio.service";
import { VideoService } from "./video/video.service";

declare var QB: any;

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit, OnDestroy {
  qbDetails: QbDetails;
  userDetails: any;
  userProviders: any;
  doctorCallState = false;

  // some fields to store our state so we can display it in the UI
  idleState = "NOT_STARTED";
  countdown?: number = null;
  lastPing?: Date = null;
  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private credentialsService: CredentialsService,
    private dashboardService: DashboardService,
    private qbService: QbService,
    private bnIdle: BnNgIdleService,
    private authService: AuthenticationService,
    private userIdle: UserIdleService,
    //private idle: Idle,
    private router: Router,
    // private _pushNotifications: PushNotificationsService,
    private connectionService: ConnectionService,
    private zone: NgZone,
    private callStateService: CallStateService,
    private videoService: VideoService,
    cd: ChangeDetectorRef,
  ) {
    this.qbDetails = this.credentialsService.credentials.qbDetails;
    this.userProviders =
      this.credentialsService.credentials.userDetails.providers;
    QB.init(
      this.qbDetails.appId,
      this.qbDetails.authKey,
      this.qbDetails.authSecret,
      // this.qbDetails.accountKey,
      qbEndpoints,
    );
    /* this._pushNotifications.requestPermission(); */

    //Start watching for user inactivity.
    this.userIdle.startWatching();
    // Start watching when user idle is starting.
    this.subscriptions.add(this.userIdle.onTimerStart().subscribe());

    // Start watching when user idle is starting.
    this.subscriptions.add(
      this.userIdle
        .onTimeout()
        .pipe(switchMap(() => this.authService.logout()))
        .subscribe({
          next: () => {
            this.router.navigate(["/login"]).then(() => {
              window.location.reload();
            });
          },
        }),
    );

    this.subscriptions.add(
      this.bnIdle
        .startWatching(environment.userIdleTime)
        .pipe(
          switchMap((res) => {
            if (res) {
              this.bnIdle.resetTimer();
              if (sessionStorage.getItem("sendNotification") !== "true") {
                return this.dashboardService.sendInactiveNotification().pipe(
                  tap(() => {
                    sessionStorage.setItem("sendNotification", "true");
                  }),
                );
              }
            }

            return EMPTY;
          }),
        )
        .subscribe(),
    );
  }

  callNotify() {
    const notificationOptns: NotificationOptions = {
      body: "Un nuevo paciente ha solicitado consulta.",
      icon: "assets/images/holadoc-corazon-azul.png",
      silent: this.doctorCallState,
    };

    this.notify(notificationOptns, this.zone, this.router);
  }

  notify(options, zone, router) {
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      const notification = new Notification("HolaDOC", options);

      this.configureNotification(notification, options, zone, router);
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          const notification = new Notification("HolaDOC", options);

          this.configureNotification(notification, options, zone, router);
        }
      });
    }
  }

  ngOnInit() {
    this.subscriptions.add(
      this.callStateService.cast.subscribe({
        next: (callState) => {
          this.doctorCallState = callState;
        },
      }),
    );

    window.onbeforeunload = (ev) => {
      sessionStorage.removeItem("chatConnection");
      sessionStorage.removeItem("sendNotification");
    };

    this.qbDetails = this.credentialsService.credentials.qbDetails;
    this.userDetails = this.credentialsService.credentials.userDetails;
    this.quickBloxLogin(this.qbDetails, this.userDetails);

    this.subscriptions.add(
      this.connectionService.messages.subscribe({
        next: (msg) => {
          if (
            msg.operation === SocketEvents.WaitingRoom &&
            !Array.isArray(msg.data) &&
            this.userProviders.find(
              (provider) => provider._id === msg.data.provider._id,
            ) &&
            this.credentialsService.credentials.userDetails.doctorRole === 1
          ) {
            this.callNotify();
          }
        },
      }),
    );

    this.subscriptions.add(
      this.videoService
        .getRecordedVideoOutput$()
        .pipe(
          concatMap((recordedVideoOutput) =>
            this.dashboardService
              .uploadVideoCall$(recordedVideoOutput)
              .pipe(catchError(() => EMPTY)),
          ),
        )
        .subscribe(),
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  quickBloxLogin(qbDetails: any, userDetails: any) {
    QB.createSession(
      {
        login: userDetails._id,
        password: qbEndpoints.passString + userDetails._id,
      },
      (error, response) => {
        if (response) {
          let password =
            qbEndpoints.passString +
            this.credentialsService.credentials.userDetails._id;
          this.connectToChat(response.user_id, password);
        } else {
          console.log("Error trying to create session", error);
        }
      },
    );
  }

  connectToChat(userId, password) {
    QB.chat.connect(
      {
        userId: userId,
        password: password,
      },
      (err, roster) => {
        if (err) {
          console.log("Error trying to connect", err);
        } else {
          // Listing all the listeners
          QB.webrtc.onStopCallListener = this.qbService.onStopCallListener;
          QB.webrtc.onSessionCloseListener =
            this.qbService.onSessionCloseListener;
          QB.webrtc.onRejectCallListener = this.qbService.onRejectCallListener;
          QB.webrtc.onAcceptCallListener = this.qbService.onAcceptCallListener;
          QB.webrtc.onUserNotAnswerListener =
            this.qbService.onUserNotAnswerListener;
          QB.webrtc.onRemoteStreamListener =
            this.qbService.onRemoteStreamListener;
          this.qbService.qbListener(true);
          sessionStorage.setItem("chatConnection", "finish");

          QB.chat.onDisconnectedListener =
            this.qbService.onDisconnectedListener;
          QB.chat.onReconnectListener = this.qbService.onReconnectListener;
          QB.chat.onMessageListener = this.qbService.onMessageListener;
        }
      },
    );
  }

  private configureNotification(
    notification: Notification,
    options: NotificationOptions,
    zone: NgZone,
    router: Router,
  ): void {
    notification.onshow = function (event) {
      if (!options.silent) {
        const audio = new Audio("assets/audio/notification.mp3");
        audio.play();
      }
    };

    notification.onclick = function (event) {
      if (!options.silent) {
        zone.run(() => {
          window.focus();
          router.navigate(["/dashboard"]);
        });
      }
      notification.close();
    };
  }
}
