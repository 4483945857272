import { Component, Inject, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { SharedService } from "src/app/shared/shared.service";
import { DashboardService } from "../dashboard.service";

export interface DialogData {
  action: "create" | "update";
  consultId: string;
}
@Component({
  selector: "app-schedule-follow-up-consultation",
  templateUrl: "./schedule-follow-up-consultation.component.html",
  styleUrls: ["./schedule-follow-up-consultation.component.scss"],
})
export class ScheduleFollowUpConsultationComponent implements OnInit {
  loading: boolean = true;
  error: boolean = false;

  count: number = 0;

  minDate = moment().add(1, "day").toDate();
  date = new FormControl(this.minDate);
  DATE_FORMAT = "YYYY-MM-DD";

  constructor(
    public dialog: MatDialog,
    private dashboardService: DashboardService,
    private translationService: TranslateService,
    private sharedService: SharedService,
    private dateAdapter: DateAdapter<Date>,
    public dialogRef: MatDialogRef<ScheduleFollowUpConsultationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    this.dateAdapter.setLocale("es-ES"); //dd/MM/yyyy
  }

  ngOnInit(): void {
    this.onDateChange();
  }

  onDateChange() {
    this.loading = true;
    this.error = false;

    const date = moment(this.date.value);

    if (date.isValid()) {
      const params = {
        startDate: date.toISOString().split(".")[0],
      };

      this.dashboardService.followUpCount(params).subscribe({
        next: (result) => {
          this.loading = false;
          this.count = result?.data?.count || 0;
        },
        error: (err) => {
          this.loading = false;
          this.error = true;
        },
      });
    } else {
      this.loading = false;
      this.error = true;
    }
  }

  save() {
    const date = moment(this.date.value);

    if (date.isValid()) {
      if (this.data.action === "create") {
        // this.showMessage();
        this.dialogRef.close({
          event: this.data.action,
          data: { followUpDate: moment(date).format(this.DATE_FORMAT) },
        });
      } else if (this.data.action === "update") {
        // this.showMessage();
        this.dialogRef.close({
          event: this.data.action,
          data: { followUpDate: moment(date).format(this.DATE_FORMAT) },
        });
      }
    }
  }

  // showMessage() {
  //   this.translationService
  //     .get("Scheduled follow-up consultation")
  //     .subscribe((text: string) => {
  //       this.sharedService.showMessage(text, "success");
  //     });
  // }
}
