import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

class DoctorCommunication {
  audio: boolean;
  chat: boolean;
  video: boolean;
}

@Injectable()
export class CallStateService {
  private callState = new BehaviorSubject<any>(false);
  private errorSubjct = new Subject<boolean>();

  cast = this.callState.asObservable();

  updateCallState(data: boolean) {
    this.callState.next(data);
  }

  emitError(errorMessage: boolean) {
    this.errorSubjct.next(errorMessage);
  }

  getErrorObservable() {
    return this.errorSubjct.asObservable();
  }
}
