<div class="container">
  <div class="title-container">
    <p>Origen de la solicitud</p>
    <mat-icon (click)="onClose()" mat-dialog-close>close</mat-icon>
  </div>
  <div class="buttons-container">
    <button class="fill-button" (click)="onSelectOrigin(originCall.IVR)">
      Central telefónica
    </button>

    <button
      class="fill-button"
      (click)="onSelectOrigin(originCall.COLLABORATOR)"
    >
      Colaborador Andrómeda
    </button>

    <button (click)="onSelectOrigin(originCall.OTHER)">Otro</button>
  </div>
</div>
