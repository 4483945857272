import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
} from "@angular/core";
import { CredentialsService } from "src/app/core/credentials.service";
import { TranslateService } from "@ngx-translate/core";
import { SortOrder } from "src/app/shared/pipes/order-by/order-by.model";
export interface Section {
  Name: string;
  Complaint: string;
}

export interface TimeSpan {
  hours: number;
  minutes: number;
  seconds: number;
}

@Component({
  selector: "app-main-page",
  templateUrl: "./main-page.component.html",
  styleUrls: ["./main-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainPageComponent implements OnInit {
  doctorRole: any;
  widthSet = false;
  messages: any[] = [];
  messagesSpecialty: any[] = [];
  inProcessConsult: any = [];
  recentConsult: any = [];
  averagetimeConsult: any = [];
  waitingtimeConsult: any = [];
  waitingTime: any = [];
  public singPatientRecord: any;
  followUpList: any[] = [];
  consultComplete: boolean = false;
  currentLanguage: string = 'es';
  fileUrl: string;
  loading: boolean = true;
  sortOrder = SortOrder;

  constructor(
    private translateService: TranslateService,
    private credentialService: CredentialsService,
  ) {}

  ngOnInit(): void {
    if (this.translateService.currentLang == "en-US") {
      this.currentLanguage = "en";
    } 
    
    localStorage.removeItem("patientDetails");
    this.doctorRole = this.credentialService.credentials.userDetails.doctorRole;
  }

  /* 
  secondsToHms(entry) {
    let totalSeconds = Math.floor(
      (new Date().getTime() - new Date(entry.date).getTime()) / 1000
    );
    var d = Number(totalSeconds);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    return (
      ("0" + h).slice(-2) +
      ":" +
      ("0" + m).slice(-2) +
      ":" +
      ("0" + s).slice(-2)
    );
  }

  sendMessage() {
    if(this.credentialService.credentials.userDetails.doctorRole == 1){
      this.connectionService.sendMsg(SocketEvents.WaitingRoom);
      this.connectionService.sendMsg(SocketEvents.FollowUpRoom);
    }else {
      this.connectionService.sendMsg(SocketEvents.DoctorSpecialistWaitingRoom);
    }

   
    
  }

  recentConstult() {
    this.subscriptions.add(
      this.dashboardService
        .getConsults({
          "status[0]": 5,
          includeFollowUp: 1,
          doctor: this.credentialService.credentials.userDetails._id,
          startDate: moment().startOf("day").utc().format(),
          endDate: moment().endOf("day").utc().format(),
        })
        .subscribe({
          next: (res: any) => {
            this.recentConsult = res["data"];
          },
          error: (error: HttpErrorResponse) => {
            this.handleError(error);
          },
        })
    );
  }

  inprocessConstult() {
    this.subscriptions.add(
      this.dashboardService
        .getConsults({
          "status[0]": 2,
          "status[1]": 3,
          "status[2]": 4,
          includeFollowUp: 1,
          doctor: this.credentialService.credentials.userDetails._id,
        })
        .subscribe({
          next: (res: any) => {
            this.inProcessConsult = res["data"];
          },
          error: (error: HttpErrorResponse) => {
            this.handleError(error);
          },
        })
    );
  }


  averageConsult() {
    this.subscriptions.add(
      this.dashboardService.getAverageConsult().subscribe({
        next: (res: any) => {
          this.averagetimeConsult = res["data"];

          if (
            this.averagetimeConsult.length > 0 &&
            this.averagetimeConsult[0].consultationTime !== null
          ) {
            var averagetime = this.averagetimeConsult[0].consultationTime.toFixed(
              0
            );
            var hours = Math.floor(averagetime / 3600);
            var minutes = Math.floor((averagetime - hours * 3600) / 60);
            var seconds = averagetime - hours * 3600 - minutes * 60;

            // round seconds
            seconds = Math.round(seconds * 100) / 100;

            var result = hours < 10 ? "0" + hours : hours;
            result += ":" + (minutes < 10 ? "0" + minutes : minutes);
            result += ":" + (seconds < 10 ? "0" + seconds : seconds);
            this.averageconsulttime = result;
            return result;
          } else {
            this.averageconsulttime = "00:00:00";
          }
        },
        error: (error: HttpErrorResponse) => {
          this.handleError(error);
        },
      })
    );
  }
  averagewaitingTime() {
    this.subscriptions.add(
      this.dashboardService.getwaitTime().subscribe({
        next: (res: any) => {
          this.waitingtimeConsult = res["data"];
          if (
            this.waitingtimeConsult.length > 0 &&
            this.waitingtimeConsult[0].waitingTime != null
          ) {
            var waitingtime = this.waitingtimeConsult[0].waitingTime.toFixed(0);

            var hours = Math.floor(waitingtime / 3600);
            var minutes = Math.floor((waitingtime - hours * 3600) / 60);
            var seconds = waitingtime - hours * 3600 - minutes * 60;

            // round seconds
            seconds = Math.round(seconds * 100) / 100;

            var result = hours < 10 ? "0" + hours : hours;
            result += ":" + (minutes < 10 ? "0" + minutes : minutes);
            result += ":" + (seconds < 10 ? "0" + seconds : seconds);
            this.display = result;
            return result;
          } else {
            this.display = "00:00:00";
          }
        },
        error: (error: HttpErrorResponse) => {
          this.handleError(error);
        },
      })
    );
  }



  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }

  private insertConsultation(
    consultationMap: any[],
    message: SocketEvent
  ): void {
    const doctorProviders: any[] = this.credentialService.credentials
      .userDetails.providers;
    if (Array.isArray(message.data)) {
      message.data.forEach((consultation) => {
        if (
          doctorProviders.find(
            (provider) => provider._id === consultation.provider._id
          )
        ) {
          const isInList = consultationMap.some(
            (x) => x._id === consultation._id
          );
          if (!isInList) {
            consultationMap.push(consultation);
          }
        }
      });
    } else {
      if (
        doctorProviders.find(
          (provider) => provider._id === message.data.provider._id
        )
      ) {
        const isInList = consultationMap.some(
          (x) => x._id === message.data._id
        );
        if (!isInList) {
          consultationMap.push(message.data);
        }
      }
    }
  }

  public isYounger(message: any) {
    const dateOfBirth = message.patient.dateOfBirth;
    const now = moment();

    if (moment(now).diff(dateOfBirth, "y") < 18) {
      return true;
    } else {
      return false;
    }
  } */
}
