<div class="container" *ngIf="!loading">
  <h5 class="heading">
    {{ "Attachment" | translate }}
    <div class="add-file" *ngIf="canAdd">
      <button mat-button (click)="uploadFile()">
        + {{ "Add file" | translate }}
      </button>
    </div>
  </h5>

  <mat-card class="card">
    <div style="display: flex">
      <div style="flex: 1"></div>
      <br /><br />

      <mat-form-field>
        <mat-label>{{ "Choose a tags" | translate }}</mat-label>
        <select
          (change)="getDocumentsFilter($event.target.value)"
          matNativeControl
        >
          <option
            value=""
            style="font-size: 0.875rem; font-style: normal; font-weight: 600"
          >
            {{ "None" | translate }}
          </option>
          <option
            *ngFor="let t of tags"
            style="font-size: 0.875rem; font-style: normal; font-weight: 600"
            [value]="t._id"
          >
            {{ t.tag[currentLanguage] }}
          </option>
        </select>
      </mat-form-field>
    </div>
    <div class="grid-container">
      <div
        *ngFor="let doc of docsList; let i = index"
        [ngClass]="'item' + i"
        (click)="attachmentPopup(doc)"
      >
        <span *ngIf="!filterAttachement">
          <img
            *ngIf="documentsGetImage(doc) === 'jpg'"
            src="{{ doc.url }}"
            style="width: 100px; height: 100px"
          />
          <img
            *ngIf="documentsGetImage(doc) === 'png'"
            src="{{ doc.url }}"
            style="width: 100px; height: 100px"
          />
          <img
            *ngIf="documentsGetImage(doc) === 'pdf'"
            src="../../../assets/images/pdf-view.png"
            style="width: 100px; height: 100px"
          />
          <img
            *ngIf="documentsGetImage(doc) === 'doc'"
            src="../../../assets/images/word.svg"
            style="width: 100px; height: 100px"
          />
          <img
            *ngIf="documentsGetImage(doc) === 'xsl'"
            src="../../../assets/images/excel.svg"
            style="width: 100px; height: 100px"
          />
        </span>
      </div>
    </div>
    <div *ngIf="docsList.length == 0">
      <img
        *ngIf="currentLanguage == 'en'"
        style="display: block; margin: 0 auto"
        src="../../../assets/images/no-photos-en.svg"
      />
      <img
        *ngIf="currentLanguage == 'es'"
        style="display: block; margin: 0 auto"
        src="../../../assets/images/no-photos-es.svg"
      />
    </div>
    <div
      style="display: flex; align-items: center; cursor: pointer"
      (click)="pagination()"
      *ngIf="!totalLength"
    >
      <div style="flex: 1"></div>
      <a style="float: right; color: #65a7f6"
        >{{ "View More" | translate
        }}<img
          style="text-align: center; padding-left: 15px"
          src="../../../assets/images/downarrow.svg"
      /></a>
    </div>
  </mat-card>
</div>
