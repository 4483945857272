<div>
  <div class="container">
    <div class="flex-container" fxLayout="row" fxLayoutGap="50px">
      <div fxFlex="70">
        <div>
          <div fxLayout="row" fxLayoutGap="50px">
            <div style="width: 50%">
              <mat-card class="card">
                <h5 class="heading">{{ "Queue Status" | translate }}</h5>
                <app-queuestatus></app-queuestatus>
              </mat-card>
            </div>

            <div style="width: 50%">
              <mat-card class="card">
                <div class="averagetime">
                  <div>
                    <h5 class="heading">
                      <span>{{
                        "Average Consultation Length" | translate
                      }}</span>
                      <!-- <img
                          style="
                            align-items: center;
                            padding-left: 10px;
                            position: relative;
                            top: 5px;
                          "
                          src="../../../assets/images/info.svg"
                        /> -->
                    </h5>
                  </div>
                  <div style="display: flex; align-items: center">
                    <img src="../../../assets/images/times.svg" /><span
                      style="
                        text-align: center;
                        padding-left: 15px;
                        font-size: 1.125rem;
                      "
                      >{{ averageconsulttime }}</span
                    >
                  </div>
                </div>
                <div class="averagewait">
                  <div>
                    <h5 class="heading">
                      <span>{{ "Average Wait Time" | translate }}</span>
                      <!-- <img
                          style="
                            align-items: center;
                            padding-left: 10px;
                            position: relative;
                            top: 5px;
                          "
                          src="../../../assets/images/info.svg"
                        /> -->
                    </h5>
                  </div>
                  <div style="display: flex; align-items: center">
                    <img src="../../../assets/images/clock.svg" /><span
                      style="
                        text-align: center;
                        padding-left: 15px;
                        font-size: 1.125rem;
                      "
                      >{{ display }}</span
                    >
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
        <br /><br />

        @if (inProcessConsult.length > 0) {
          <div>
            <br />
          </div>

          <div>
            <mat-card class="card">
              <h5 class="heading">{{ "In Process Consult" | translate }}</h5>
              <mat-list>
                <mat-list-item
                  *ngFor="let consults of inProcessConsult"
                  style="text-align: left"
                >
                  <div style="display: flex; justify-content: space-between">
                    <div style="display: flex; align-items: center; gap: 10px">
                      @if (consults.patient.profilePic) {
                        <img
                          matListAvatar
                          class="avatar-img"
                          style="margin-right: 16px"
                          [src]="fileUrl + consults.patient.profilePic"
                        />
                      } @else {
                        <ngx-avatars
                          class="user-profile"
                          [name]="
                            consults.patient.firstName +
                            ' ' +
                            consults.patient.lastName
                          "
                          size="40"
                        ></ngx-avatars>
                      }
                      <div style="display: flex; flex-direction: column">
                        <h3
                          style="
                            font-style: normal;
                            font-weight: 600;
                            font-size: 0.875rem;
                            color: #46535b;
                            margin: 0px;
                          "
                        >
                          {{
                            consults.patient.firstName +
                              " " +
                              consults.patient.lastName
                          }}
                        </h3>
                        <p style="margin: 0px">
                          <span
                            style="
                              font-style: normal;
                              font-weight: normal;
                              font-size: 0.813rem;
                              color: #666666;
                            "
                          >
                            {{ getConsultationType(consults) }}
                            @if (consults.chiefComplaint?.other) {
                              {{ consults.chiefComplaint.other }}
                            } @else {
                              {{ sharedService.getChiefComplaint(consults) }}
                            }
                          </span>
                        </p>
                      </div>
                    </div>

                    <button
                      mat-raised-button
                      color="primary"
                      (click)="resumeConsult(consults)"
                      class="button-color"
                    >
                      {{ "Resume Consult" | translate }}
                    </button>
                  </div>
                </mat-list-item>
              </mat-list>
            </mat-card>
          </div>
        }
        <br /><br />
        <div>
          <mat-card class="recent-card" style="height: 543px">
            <h5 class="heading">
              {{ "My recenly completed consults" | translate }}
            </h5>
            <mat-list>
              <mat-list-item
                *ngFor="let recent of recentConsult"
                style="text-align: left; height: 72px"
                class="completed-list"
                (click)="recentCompleteInfo(recent)"
                sy
              >
                <div style="display: flex">
                  @if (recent.patient.profilePic) {
                    <img
                      matListAvatar
                      class="avatar-img"
                      style="margin-right: 3%"
                      [src]="fileUrl + recent.patient.profilePic"
                    />
                  } @else {
                    <ngx-avatars
                      class="user-profile"
                      [name]="
                        recent.patient.firstName + ' ' + recent.patient.lastName
                      "
                      size="40"
                    ></ngx-avatars>
                  }
                  <div style="display: flex; flex-direction: column">
                    <h3
                      matLine
                      style="
                        font-style: normal;
                        font-weight: 600;
                        font-size: 0.875rem;
                        color: #46535b;
                        margin: 5px 0px;
                      "
                      [ngClass]="
                        recent.patient.profilePic
                          ? 'profile-pic-exist'
                          : 'no-profile-pic'
                      "
                    >
                      {{
                        recent.patient.firstName + " " + recent.patient.lastName
                      }}
                    </h3>
                    <p style="margin: 0px">
                      <span
                        style="
                          font-style: normal;
                          font-weight: normal;
                          font-size: 0.813rem;
                          color: #666666;
                          margin: 0px;
                        "
                        [ngClass]="
                          recent.patient.profilePic
                            ? 'profile-pic-exist'
                            : 'no-profile-pic'
                        "
                      >
                        {{ getConsultationType(recent) }}
                        @if (recent.chiefComplaint?.other) {
                          {{ recent.chiefComplaint.other }}
                        } @else {
                          {{ sharedService.getChiefComplaint(recent) }}
                        }
                      </span>
                    </p>
                  </div>
                </div>
              </mat-list-item>
            </mat-list>
            @if ( !recentConsult?.length ) {
              <div>
                @switch (sharedService.currentLanguage) {
                  @case ("en") {
                    <img
                      class="img-nocontents"
                      src="../../../assets/images/no-history-en.svg"
                    />
                  }
                  @case ("es") {
                    <img
                      class="img-nocontents"
                      src="../../../assets/images/no-history-es.svg"
                    />
                  }
                }
              </div>
            }
          </mat-card>
        </div>
      </div>

      <div fxFlex="30" fxLayoutAlign="stretch">
        <mat-card class="waiting-card">
          <h5 class="heading" style="margin-left: 3%">
            {{ "Waiting Room" | translate }}
          </h5>
          <div>
            <mat-tab-group>
              <mat-tab label="Primary Calls">
                <ng-template matTabLabel>
                  <!-- <span [matBadgePosition] [matBadge]="messages.length" matBadgeOverlap="false"
                      >Primary Calls</span
                    > -->
                  <span>{{ "Primary Calls" | translate }}</span>
                  <span class="text-span" *ngIf="messages.length > 0">{{
                    messages.length
                  }}</span>
                </ng-template>

                <div class="primary-call-container">
                  <mat-list>
                    <mat-list-item
                      class="waiting-list"
                      *ngFor="let message of messages"
                      style="text-align: left; position: relative"
                      (click)="waitingRoom(message)"
                    >
                      <div style="display: flex; align-items: center">
                        <div class="img-container">
                          @if (message.patient.profilePic) {
                            <img
                              matListAvatar
                              [src]="fileUrl + message.patient.profilePic"
                            />
                          } @else {
                            <ngx-avatars
                              class="user-profile"
                              [name]="
                                message.patient.firstName +
                                ' ' +
                                message.patient.lastName
                              "
                              size="40"
                            ></ngx-avatars>
                          }
                          <div
                            message
                            *ngIf="isYounger(message)"
                            class="younger-icon"
                            matTooltip="Este paciente es menor de
                          edad"
                            matTooltipClass="custom-tooltips right"
                            matTooltipPosition="right"
                          >
                            <img
                              style="width: 70%; height: 70%"
                              src="../../../assets/images/younger.svg"
                            />
                          </div>
                        </div>

                        <div class="call-content-container">
                          <h3
                            matLine
                            style="
                              font-style: normal;
                              font-weight: 600;
                              font-size: 0.875rem;
                              color: #46535b;
                              text-transform: capitalize;
                              margin: 5px 0px;
                            "
                          >
                            {{
                              message.patient.firstName +
                                " " +
                                message.patient.lastName
                            }}
                          </h3>
                          <p matLine style="margin: 5px 0px">
                            <span
                              class="reason"
                              style="
                                font-style: normal;
                                font-weight: normal;
                                font-size: 0.813rem;
                                color: #666666;
                              "
                            >
                              @if (message.chiefComplaint?.other) {
                                <span
                                  [tooltip]="message.chiefComplaint.other"
                                  placement="top"
                                  delay="500"
                                  [showEnable]="true"
                                >
                                  {{
                                    sharedService.getOtherChiefComplaintMessage(
                                      message
                                    )
                                  }}
                                </span>
                              } @else {
                                <span
                                  [tooltip]="
                                    sharedService.getChiefComplaint(message)
                                  "
                                  placement="top"
                                  delay="500"
                                  [showEnable]="true"
                                >
                                  {{
                                    sharedService.getChiefComplaintMessage(
                                      message
                                    )
                                  }}
                                </span>
                              }
                              <img
                                *ngIf="message.communicationMode == 1"
                                src="../../../assets/images/call.svg"
                                class="call"
                              />
                              <img
                                *ngIf="message.communicationMode == 2"
                                src="../../../assets/images/video.svg"
                                class="call"
                              />
                              <img
                                *ngIf="message.communicationMode == 3"
                                src="../../../assets/images/msg.svg"
                                class="call"
                              />
                            </span>
                          </p>
                          <p
                            matLine
                            style="
                              font-style: normal;
                              font-weight: normal;
                              font-size: 0.75rem;
                              color: #666666;
                              margin: 5px 0px;
                            "
                          >
                            <span *ngIf="secondsToHms(message) as result">
                              {{ "Wait Time" | translate }} {{ result }}
                            </span>
                          </p>
                          <div class="tooltip">
                            <p
                              matLine
                              style="
                                font-style: normal;
                                font-weight: normal;
                                font-size: 0.75rem;
                                color: #666666;
                              "
                            >
                              <span>
                                {{
                                  message.providerCall
                                    ? message.providerCall.length > 25
                                      ? (message.providerCall | slice: 0 : 22) +
                                        "..."
                                      : message.providerCall
                                    : "Free Trial"
                                }}
                              </span>
                              <span
                                style="
                                  font-style: normal;
                                  font-weight: 400;
                                  font-size: 0.7rem;
                                  color: #2751a5;
                                "
                                *ngIf="message.countSubscription > 0"
                              >
                                +{{ message.countSubscription }}
                              </span>
                            </p>
                            <span
                              class="tooltiptext"
                              *ngIf="message.countSubscription > 0"
                            >
                              {{ "Affiliations" | translate }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </mat-list-item>
                    <mat-list-item style="height: 30px"></mat-list-item>
                  </mat-list>
                  <div *ngIf="messages.length == 0">
                    <img
                      class="img-nocontentssss"
                      src="../../../assets/images/family.png"
                    />
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="Followup Calls">
                <ng-template matTabLabel>
                  <!-- <span [matBadge]="followUpList.length" matBadgeOverlap="false"
                      >Followup Calls</span
                    > -->
                  <span>{{ "Followup Calls" | translate }}</span>
                  <span class="text-span" *ngIf="followUpList.length > 0">{{
                    followUpList.length
                  }}</span>
                </ng-template>
                <div class="primary-call-container">
                  <mat-list>
                    <mat-list-item
                      class="waiting-list"
                      *ngFor="let follow of followUpList"
                      style="text-align: left; position: relative"
                      (click)="followUpCall(follow)"
                    >
                      <div style="display: flex; align-items: center">
                        <div class="img-container">
                          @if (follow.patient.profilePic) {
                            <img
                              matListAvatar
                              [src]="fileUrl + follow.patient.profilePic"
                            />
                          } @else {
                            <ngx-avatars
                              class="user-profile"
                              [name]="
                                follow.patient.firstName +
                                ' ' +
                                follow.patient.lastName
                              "
                              size="40"
                            ></ngx-avatars>
                          }
                          <div
                            *ngIf="isYounger(follow)"
                            class="younger-icon"
                            matTooltip="Este paciente es menor de
                        edad"
                            matTooltipClass="custom-tooltips right"
                            matTooltipPosition="right"
                          >
                            <img
                              style="width: 70%; height: 70%"
                              src="../../../assets/images/younger.svg"
                            />
                          </div>
                        </div>
                        <div class="call-content-container">
                          <h3
                            matLine
                            style="
                              font-style: normal;
                              font-weight: 600;
                              font-size: 0.875rem;
                              color: #46535b;
                              text-transform: capitalize;
                              margin: 5px 0px;
                            "
                          >
                            {{
                              follow.patient.firstName +
                                " " +
                                follow.patient.lastName
                            }}
                          </h3>
                          <p matLine style="margin: 5px 0px">
                            <span
                              style="
                                font-style: normal;
                                font-weight: normal;
                                font-size: 0.813rem;
                                color: #666666;
                              "
                            >
                              {{ sharedService.getChiefComplaint(follow) }}
                              <img
                                *ngIf="follow.communicationMode == 1"
                                src="../../../assets/images/call.svg"
                                class="call"
                              />
                              <img
                                *ngIf="follow.communicationMode == 2"
                                src="../../../assets/images/video.svg"
                                class="call"
                              />
                              <img
                                *ngIf="follow.communicationMode == 3"
                                src="../../../assets/images/msg.svg"
                                class="call"
                              />
                            </span>
                          </p>
                          <p
                            matLine
                            style="
                              font-style: normal;
                              font-weight: normal;
                              font-size: 0.75rem;
                              color: #666666;
                              margin: 5px 0px;
                            "
                          >
                            <span>
                              {{
                                follow.providerCall
                                  ? follow.providerCall.length > 25
                                    ? (follow.providerCall | slice: 0 : 22) +
                                      "..."
                                    : follow.providerCall
                                  : "Free Trial"
                              }}
                            </span>
                          </p>
                        </div>
                      </div>
                    </mat-list-item>
                  </mat-list>
                  <div *ngIf="followUpList.length == 0">
                    <img
                      style="display: block; margin: auto"
                      *ngIf="sharedService.currentLanguage === 'en'"
                      src="../../../assets/images/no-history-en.svg"
                    />
                    <img
                      style="display: block; margin: auto"
                      *ngIf="sharedService.currentLanguage === 'es'"
                      src="../../../assets/images/no-history-es.svg"
                    />
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>
