<div style="position: relative; height: 100%">
  <div
    style="
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
    "
  >
    {{ "Appointment management" | translate }}
    <div class="select-container" style="display: flex; gap: 15px">
      <mat-form-field appearance="outline" style="font-size: 14px">
        <mat-select
          [(ngModel)]="selectedStatus"
          (ngModelChange)="onStatusChange($event)"
        >
          <mat-option *ngFor="let status of statusArray" [value]="status.value">
            {{ status.label }}</mat-option
          >
          <mat-option value="">Ver todas</mat-option>
        </mat-select>
      </mat-form-field>

      <button (click)="displayTable()" class="seeMore-button">
        <mat-icon>visibility</mat-icon>
        {{ seeMore ? "Ver menos" : "Ver más" }}
      </button>
    </div>
  </div>

  <div style="width: 100%; margin-top: 15px; height: 100%">
    <div class="tHead">
      <p>Doctor</p>
      <p>Fecha</p>
      <p>Hora</p>
      <p>Estado</p>
    </div>

    <div style="max-height: calc(100% - 105px) !important; overflow: auto">
      <div
        class="tBody-item"
        *ngFor="let item of consults"
        (click)="gotoPatient(item._id)"
      >
        <div>
          <span class="shd-ml-sm">{{ getDoctorName(item) }}</span>
        </div>
        <div>
          <span class="shd-ml-sm">{{ getDate(item.date) }}</span>
        </div>
        <div>
          <span class="shd-ml-sm">{{ getTime(item.hour) }}</span>
        </div>
        <div>
          <span
            class="shd-ml-sm"
            [class.canceled]="canceledStatus.includes(item.appointmentStatus)"
            >{{
              getAppointmentStatus(
                item.appointmentStatus,
                item.appointmentRescheduledCount +
                  item.appointmentRescheduledByDoctorCount
              )
            }}</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
