import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "src/environments/environment";
import { CredentialsService } from "./core/credentials.service";
import { catchError } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MatDialog } from "@angular/material/dialog";
import { SharedService } from "./shared/shared.service";

const GENERIC_ERROR_MESSAGE = "Ha ocurrido un error";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    public credentialsService: CredentialsService,
    public translateService: TranslateService,
    private router: Router,
    private sharedService: SharedService,
    private dialog: MatDialog,
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const token = this.credentialsService.credentials
      ? this.credentialsService.credentials
      : "";
    if (!token) {
      req = req.clone({
        url: environment.base_url + req.url,
        headers: req.headers.set(
          "Accept-Language",
          this.translateService.currentLang,
        ),
      });
    } else {
      req = req.clone({
        url: environment.base_url + req.url,
        setHeaders: {
          Accept: "application/json",
          Authorization: `Bearer ${this.credentialsService.credentials.token}`,
        },
        headers: req.headers.set(
          "Accept-Language",
          this.translateService.currentLang,
        ),
      });
    }
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        const { status } = error;
        const [message] = error?.error?.message || ["Ha ocurrido un error"];

        switch (status) {
          case 500: {
            this.sharedService.showErrorMessage(
              "Error en el servidor, por favor intente más tarde",
            );
            this.dialog.closeAll();
            break;
          }
          case 403: {
            this.sharedService.showErrorMessage(message);
            this.dialog.closeAll();
            break;
          }
          case 401: {
            this.sharedService.showErrorMessage(message);
            this.dialog.closeAll();
            this.credentialsService.setCredentials();
            this.router.navigate(["/login"]);
            break;
          }
          case 400: {
            if (typeof message === "string") {
              this.sharedService.showErrorMessage(message);
              return;
            }
            break;
          }
          default:
            return throwError(() => {
              throw error;
            });
        }
      }),
    );
  }
}
