import { Injectable, OnDestroy } from "@angular/core";
import { environment } from "src/environments/environment";
import { io, Socket } from "socket.io-client";
import { CredentialsService } from "../core/credentials.service";
import { Observable, Subject } from "rxjs";

export enum SocketEvents {
  WaitingRoom = "WAITING_ROOM",
  FollowUpRoom = "FOLLOW_UP_ROOM",
  RemoveFromWaitingRoom = "REMOVE_FROM_WAITING_ROOM",
  RemoveFromFollowUpRoom = "REMOVE_FROM_FOLLOW_UP_ROOM",
  Document = "DOCUMENT",
  Connect = "connect",
  Disconnect = "disconnect",
  AgentScheduleRoom = "AGENT_SCHEDULE_ROOM",
  AgentScheduledRoom = "AGENT_SCHEDULED_ROOM",
  AgentRescheduleRoom = "AGENT_RESCHEDULE_ROOM",
  RemoveFromAgentScheduleRoom = "REMOVE_FROM_AGENT_SCHEDULE_ROOM",
  RemoveFromAgentScheduledRoom = "REMOVE_FROM_AGENT_SCHEDULED_ROOM",
  RemoveFromAgentRescheduleRoom = "REMOVE_FROM_AGENT_RESCHEDULE_ROOM",
  DoctorSpecialistWaitingRoom = "DOCTOR_SPECIALIST_WAITING_ROOM",
  RemoveFromDoctorSpecialistWaitingRoom = "REMOVE_FROM_DOCTOR_SPECIALIST_WAITING_ROOM",
}

export const SocketEventsArray = [
  SocketEvents.WaitingRoom,
  SocketEvents.FollowUpRoom,
  SocketEvents.RemoveFromWaitingRoom,
  SocketEvents.RemoveFromFollowUpRoom,
  SocketEvents.Document,
  SocketEvents.Connect,
  SocketEvents.Disconnect,
  SocketEvents.AgentScheduleRoom,
  SocketEvents.AgentScheduledRoom,
  SocketEvents.AgentRescheduleRoom,
  SocketEvents.RemoveFromAgentScheduleRoom,
  SocketEvents.RemoveFromAgentScheduledRoom,
  SocketEvents.RemoveFromAgentRescheduleRoom,
  SocketEvents.DoctorSpecialistWaitingRoom,
  SocketEvents.RemoveFromDoctorSpecialistWaitingRoom,
];

export type SocketEvent = MessageEvent & {
  operation: SocketEvents;
  consultation: any;
};

@Injectable({
  providedIn: "root",
})
export class SocketioService implements OnDestroy {
  // private socket: SocketIOClient.Socket;
  private socket: Socket;
  public connection$: Subject<SocketEvent>;

  constructor(private credentialsService: CredentialsService) {
    this.socket = io(environment.socket_url, {
      query: {
        token: this.credentialsService.credentials.token,
      },
      transports: ["websocket"],
      forceNew: true,
    });
  }

  ngOnDestroy(): void {
    this.disconnect();
  }

  connect(): Subject<SocketEvent> {
    if (!this.socket && !this.connection$) {
      this.connection$ = new Subject<SocketEvent>();
      

      this.subscribeToAllEvents(this.connection$);
    }

    return this.connection$;
  }

  disconnect() {
    if (this.socket) {
      this.unsubscribeAllEvents();
      this.socket.disconnect();
      this.socket = null;
    }

    if (this.connection$) {
      this.connection$.complete();
      this.connection$ = null;
    }
  }

  emit(event: SocketEvents): void {
    if ( this.socket ) {
      this.socket.emit(event, JSON.stringify({}));
    }
  }

  on(event: SocketEvents): Observable<SocketEvent> {
    console.log("METODO ON ", event);
    
    return new Observable((observer) => {
      this.socket.on(event, (data: any) => {
        console.log(data);
        
        observer.next(data);
      });

      return () => {
        this.socket.off(event);
      };
    });
  }

  private subscribeToEvent( event: SocketEvents): void {
    if (this.socket) {
      // this.socket.off(event);
      this.socket.on(event, (res: any) => {
        console.log(res);
        if ( ![SocketEvents.Connect, SocketEvents.Disconnect].includes(event) ) {
            // this.data.operation = event;
            const data = res;
            data['operation'] = event;
            this.connection$.next(data);
        }

        
      });
    }
  }

  private subscribeToAllEvents(connection$: Subject<MessageEvent>): void {
    if (this.socket) {
      SocketEventsArray.forEach((event) => {
        this.subscribeToEvent(event);
        console.log(event);
      });
    }
  }

  private unsubscribeAllEvents(): void {
    if (this.socket) {
      SocketEventsArray.forEach((event) => {
        this.socket.off(event);
      });
    }
  }
}
