<div style="display: flex; flex-direction: column; height: 100%; gap: 30px">
  <div
    style="display: flex; align-items: center; justify-content: end; gap: 10px"
  >
    <div
      style="
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #5664af;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <mat-icon style="color: white">person</mat-icon>
    </div>
    <p style="margin: 0; font-size: 24px; font-weight: bold">
      {{ "Information of the patient" | translate }}
    </p>
  </div>
  <div
    style="
      width: 100%;
      min-height: 45%;
      box-shadow: 0px 4px 4px 0px #00000040;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
    "
  >
    <div class="patient-1-container">
      <div class="patient-info-item">
        <p class="item-title">{{ "Name of patient" | translate }}</p>
        <p class="item-text">
          {{
            patientInfo.patient.firstName + " " + patientInfo.patient.lastName
          }}
        </p>
      </div>
      <div class="patient-info-item">
        <p class="item-title">ID</p>
        <p class="item-text">
          {{ formatIdentification(patientInfo.patient.identificationNumber) }}
        </p>
      </div>
      <div class="patient-info-item">
        <p class="item-title">{{ "Phone" | translate }}</p>
        <p class="item-text">{{ formatPhoneNumber() }}</p>
      </div>
      <div class="patient-info-item">
        <p class="item-title">{{ "Email" | translate }}</p>
        <p class="item-text">
          {{
            patientInfo.patient.parent
              ? patientInfo.patient?.parent.email
              : patientInfo.patient?.email
                ? patientInfo.patient?.email
                : "-"
          }}
        </p>
      </div>
    </div>
    <div class="patient-2-container">
      <div
        *ngIf="consultationList.length === 0 && isAppointment"
        class="patient-info-item"
      >
        <p class="item-title">{{ "Chief Complaint" | translate }}</p>
        <p class="item-text">
          @if (patientInfo.chiefComplaint?.other) {
            {{ patientInfo.chiefComplaint.other }}
          } @else {
            {{ sharedService.getChiefComplaint(patientInfo) }}
          }
        </p>
      </div>
      <div
        *ngIf="
          patientInfo.doctor && consultationList.length === 0 && isAppointment
        "
        class="patient-info-item"
      >
        <p class="item-title">{{ "Dr" | translate }}</p>
        <p class="item-text">
          {{ patientInfo.doctor.firstName + " " + patientInfo.doctor.lastName }}
          <br />
          {{ getSpecialtyName(patientInfo.medicalSpecialty) }}
        </p>
      </div>
      <div class="patient-info-item">
        <p class="item-title">{{ "Affiliation" | translate }}</p>
        <p class="item-text">{{ getMembershipName() }}</p>
        <p
          (click)="showOverlayAffiliationsView = true"
          style="
            margin: 0px;
            margin-top: 5px;
            font-size: 12px;
            display: flex;
            align-items: center;
            color: #2751a5;
            gap: 5px;
            cursor: pointer;
          "
        >
          <mat-icon
            *ngIf="subscriptions"
            style="font-size: 12px; width: 12px; height: 12px"
            >visibility</mat-icon
          >
          <span *ngIf="subscriptions">{{ "View More" | translate }}</span>
        </p>
      </div>
      <div class="patient-info-item">
        <!--  <div class="doctor-container">
                   <p class="item-title">{{ "Consultations available" | translate }}:</p>
                <div style="width: 58px;height: 58px;border-radius: 50%;border: 2px solid #2751A5;display: flex;
                justify-content: center;align-items: center;">
                    <p style="font-size: 32px;margin: 0;">{{patientInfo.consultAmount}}</p>
                </div>
                 
                </div> -->
      </div>
    </div>
  </div>
  <div
    *ngIf="patientInfo.appointmentStatus === 2"
    style="display: flex; justify-content: end; padding-right: 40px"
  >
    <button
      (click)="openScheduleFormDialog()"
      style="
        background-color: #2751a5;
        width: 193px;
        height: 45px;
        font-size: 18px;
        color: white;
        border: none;
        border-radius: 20px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
      "
    >
      <mat-icon style="width: 18px; height: 18px; font-size: 18px"
        >phone</mat-icon
      >
      {{ "Schedule appointment" | translate }}
    </button>
  </div>
  <div
    *ngIf="patientInfo.appointmentStatus > 2 || !isAppointment"
    style="font-size: 20px; font-weight: 600"
  >
    Últimas citas agendadas
  </div>
  <!-- <div> -->
  <app-appointment-info
    *ngIf="patientInfo.appointmentStatus > 2 && consultationList.length === 0"
    [completeInfo]="true"
    [appointmentInfo]="patientInfo"
  ></app-appointment-info>
  <div *ngIf="consultationList.length > 0" class="consultations-container">
    <app-appointment-info
      *ngFor="let appointment of consultationList"
      [appointmentInfo]="appointment"
    ></app-appointment-info>
  </div>
  <div
    *ngIf="consultationList.length == 0 && !isAppointment"
    class="consultations-container"
  >
    <app-appointment-info [completeInfo]="true"></app-appointment-info>
  </div>

  <!--   </div> -->
</div>
<div
  style="position: fixed; width: 100vw; height: 100vh"
  class="overlay-memberships-container"
  [ngClass]="
    showOverlayAffiliationsView ? 'overlay-memberships-container-display' : ''
  "
>
  <div class="modal-container-memberships">
    <div class="modal-container-close-button">
      <button (click)="showOverlayAffiliationsView = false">
        <img src="../../../assets/images/close-cancel.svg" alt="" />
      </button>
    </div>
    <span class="modal-container-memberships-title">{{
      "Afiliaciones" | translate
    }}</span>
    <span class="modal-container-memberships-subtitle">{{
      "The patient is affiliated with the following entities:" | translate
    }}</span>

    <div style="height: 145px; overflow: auto">
      <div
        *ngFor="let membership of subscriptions"
        class="modal-memberships-container"
      >
        <p>
          <span
            *ngIf="membership.status == 1"
            class="modal-container-span-active"
            >{{ "activa" | translate }}</span
          >
          <span
            *ngIf="membership.status == 2"
            class="modal-container-span-active"
            ><img src="../../../assets/images/membership-check.svg" alt=""
          /></span>
          <span class="modal-container-span-title">{{ membership.name }}</span>
        </p>
      </div>
    </div>

    <div class="modal-container-button-parent">
      <button
        (click)="showOverlayAffiliationsView = false"
        class="modal-container-button"
      >
        Ok
      </button>
    </div>

    <!-- <span ngf></span> -->
  </div>
</div>
