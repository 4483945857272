import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { HttpErrorResponse } from "@angular/common/http";
import { CredentialsService } from "src/app/core/credentials.service";
import { SharedService } from "src/app/shared/shared.service";
import { RegistrationService } from "src/app/registration/registration.service";
import moment from "moment";
import { SignatureFieldComponent } from "src/app/shared/signature-field/signature-page.component";
import { concat } from "rxjs";
import { MatOption } from "@angular/material/core";
import { MatSelect } from "@angular/material/select";
import { DoctorRole } from "src/app/shared/types/enum.types";

export interface AboutMe {
  data: Data;
  meta: Meta;
}

export interface Data {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  gender: number;
  resetStatus: number;
  availabilityStatus: number;
  doctorType: number;
  doctorRole: number;
  status: number;
  shift: Shift;
  providers: Providers[];
  shippingCounterEmail: number;
  shippingCounterSms: number;
  createdAt: Date;
  updatedAt: Date;
  ip: string;
  quickbloxId: string;
  quickbloxInfo: QuickbloxInfo;
  about: string;
  cmNumber: string;
  countryCode: string;
  dateOfBirth: Date;
  identificationNumber: string;
  mppsNumber: string;
  phoneNumber: string;
  speciality: SpecialityElement[];
  firmPic: string;
  profilePic?: string;
  subSpeciality: SubSpeciality[];
  specialtyServed?: SubSpeciality[];
}

export interface Providers {
  _id: string;
  status: number;
  name: string;
}
export interface QuickbloxInfo {
  _id: string;
  application_id: number;
  created_at: Date;
  id: number;
  nonce: string;
  token: string;
  ts: number;
  updated_at: Date;
  user_id: number;
}

export interface Shift {
  startTime: string;
  endTime: string;
  days: number[];
}

export interface SpecialityElement {
  id: string;
  speciality: SubSpecialityClass;
  other?: string;
}

export interface SubSpecialityClass {
  en: string;
  es: string;
}

export interface SubSpeciality {
  id: string;
  subSpeciality: SubSpecialityClass;
  other?: string;
  speciality?: string;
}

export interface Meta {
  fileUrl: string;
}

@Component({
  selector: "app-editprofile",
  templateUrl: "./editprofile.component.html",
  styleUrls: ["./editprofile.component.scss"],
})
export class EditprofileComponent implements OnInit {
  doctorId;
  specialityList = [];
  subSpecialityList = [];
  editProfileForm: FormGroup;
  showSpeciality: boolean = true;
  showsubSpeciality: boolean = true;
  showOtherSpeciality: boolean = false;
  showOtherSubSpeciality: boolean = false;
  savebuttonshow: boolean = false;
  editInformation;
  DOB;
  Gender;
  profilePicUrl = "";
  profilePicPath: any;
  picInfo = true;
  profileProviders: any[] = [];
  picError: boolean = false;
  profilePicChanged = false;
  profilePicBlob: any;
  @ViewChild("profilePic", { read: true, static: false })
  profilePic: ElementRef;
  documentPath = "";
  enableEditbtn: boolean = false;
  currentLanguage: string = "es";
  maxDate: any;
  nameRegex = /^([^0-9]*)$/;
  hasFirmPicture = true;
  specialtyServedList: any = [];
  doctorRole = 0;
  allSpecialtysSelected = false;
  @ViewChild("form") formElement: ElementRef;
  @ViewChild(SignatureFieldComponent) public sig1: SignatureFieldComponent;
  @ViewChild("sigContainer1") public sigContainer1: ElementRef;
  @ViewChild("specialtyServedSelect") specialtyServedSelect: MatSelect;
  signatureError = {
    message: "La Firma es requerida",
    status: false,
  };

  public signaturePadOptions: Object = {
    // passed through to szimek/signature_pad constructor
    canvasHeight: 125,
    minWidth: "0.9",
    maxWidth: "0.9",
  };

  constructor(
    private credentialService: CredentialsService,
    private fb: FormBuilder,
    private router: Router,
    private doctorService: RegistrationService,
    private translationService: TranslateService,
    private sharedService: SharedService,
    private ref: ChangeDetectorRef,
  ) {
    this.getSpecialitiesList();
    this.getSubSpecialityList();
  }

  get isSpecialistDoctor(): boolean {
    return this.doctorRole === DoctorRole.SPECIALIST;
  }

  ngOnInit() {
    this.maxDate = moment().subtract(18, "y").format("YYYY-MM-DD");

    if (this.translationService.currentLang == "en-US") {
      this.currentLanguage = "en";
    }

    this.doctorId = this.credentialService.credentials.userDetails._id;
    let emailregex: RegExp =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    this.editProfileForm = this.fb.group({
      firstName: [
        "",
        [Validators.required, Validators.pattern(this.nameRegex)],
      ],
      lastName: ["", [Validators.required, Validators.pattern(this.nameRegex)]],
      gender: ["", Validators.required],
      dateOfBirth: ["", Validators.required],
      about: [""],
      identificationNo: ["", Validators.required],
      speciality: ["", Validators.required],
      subspeciality: [""],
      cmNO: ["", [Validators.required, Validators.pattern(/[0-9]/)]],
      mppsNo: ["", [Validators.required, Validators.pattern(/[0-9]/)]],
      email: ["", [Validators.required, Validators.pattern(emailregex)]],
      countryCode: ["", [Validators.required, Validators.maxLength(3)]],
      phoneNumber: [
        "",
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(16),
        ],
      ],
      otherSubSpeciality: [""],
      otherSpeciality: [""],
      specialtyServed: [""],
      signature: ["Firma", Validators.required],
    });

    this.editProfileForm.disable();
    this.showAndHideSpeciality();
    this.showAndHideSubSpeciality();
  }

  onSubspecialitySelectionChange() {
    const formValue = this.editProfileForm.getRawValue();

    const selectedSpeciality = [
      ...formValue.subspeciality,
      ...formValue.speciality,
    ];

    const format = selectedSpeciality.map((a) => {
      const nombreAtributo = a.speciality ? " " : "subSpeciality";
      return {
        id: a._id,
        [nombreAtributo]: a.speciality || a.subSpeciality,
      };
    });
    format.sort((a, b) => {
      const nombreA = a[Object.keys(a).find((key) => key !== "id")].es;
      const nombreB = b[Object.keys(b).find((key) => key !== "id")].es;

      return nombreA.localeCompare(nombreB);
    });

    const specialtyServedSelected = formValue.specialtyServed;

    if (specialtyServedSelected.length > 0) {
      const idsSet = new Set(specialtyServedSelected);
      const idSelected = this.specialtyServedList.filter((item) =>
        idsSet.has(item.id),
      );
      const same = idSelected.map((a) => a.id);
      this.editProfileForm
        .get("specialtyServed")
        .setValue(this.allSpecialtysSelected ? [...same, 0] : same);
    }
    this.specialtyServedList = [...format];
  }

  getCommonObjects(array1: any[], array2: any[], idProperty: string): any[] {
    if (!array1 || !array2 || array1.length === 0 || array2.length === 0) {
      return []; // Manejar arrays vacíos o indefinidos
    }

    const ids1 = new Set(array1.map((obj) => obj[idProperty])); // Obtener IDs de array1

    return array2.filter((obj2) => ids1.has(obj2[idProperty])); // Filtra array2 por IDs comunes
  }

  selectAllSepecialtiesServed() {
    this.allSpecialtysSelected = !this.allSpecialtysSelected;
    this.specialtyServedSelect.options.forEach((item: MatOption) =>
      this.allSpecialtysSelected ? item.select() : item.deselect(),
    );
  }

  _keyPress(event: any) {
    const pattern = /[0-9\b]+/;

    if (event.keyCode === 8) {
      return;
    }

    if (!pattern.test(event.key)) {
      event.preventDefault();
    }
  }

  getSpecialitiesList() {
    this.doctorService.getSpecialitiesList().subscribe((res: any) => {
      this.specialityList = res.data;
    });
  }

  getSubSpecialityList() {
    this.doctorService.getSubSpecialitiesList().subscribe((res: any) => {
      this.subSpecialityList = res.data;
      this.getDoctorsInfo();
    });
  }

  showAndHideSpeciality() {
    this.editProfileForm.get("speciality").valueChanges.subscribe((val) => {
      const otherSpecial = this.editProfileForm.get("otherSpeciality");

      if (val) {
        for (let i = 0; i < val.length; i++) {
          if (val[i].isOther === true) {
            this.showOtherSpeciality = true;
            otherSpecial.setValidators([
              Validators.required,
              Validators.pattern(this.nameRegex),
            ]);
            return;
          } else {
            this.showOtherSpeciality = false;
            otherSpecial.setValidators(null);
          }
          otherSpecial.updateValueAndValidity();
        }
      }
    });
  }

  showAndHideSubSpeciality() {
    this.editProfileForm.get("subspeciality").valueChanges.subscribe((vals) => {
      const otherSubSpecial = this.editProfileForm.get("otherSubSpeciality");
      if (vals) {
        for (let j = 0; j < vals.length; j++) {
          if (vals[j].isOther === true) {
            this.showOtherSubSpeciality = true;

            otherSubSpecial.setValidators([
              Validators.required,
              Validators.pattern(this.nameRegex),
            ]);

            return;
          } else {
            this.showOtherSubSpeciality = false;
            otherSubSpecial.setValidators(null);
          }
          otherSubSpecial.updateValueAndValidity();
        }
      }
    });
  }

  showAndHideSignature() {}

  enableProfile() {
    this.editProfileForm.enable();
    this.editProfileForm.get("email").disable();
    this.savebuttonshow = true;
    this.beResponsive();
    if (this.sig1) {
      this.sig1.registerOnChange((signature) => {
        if (!signature) {
          this.editProfileForm.controls["signature"].setValue("");
          this.editProfileForm.controls["signature"].setErrors({
            required: true,
          });
        } else {
          this.editProfileForm.controls["signature"].clearValidators();
          this.editProfileForm.controls["signature"].setValue("");
        }

        this.editProfileForm.updateValueAndValidity();
      });
    }
  }

  getDoctorsInfo() {
    this.doctorService.getDoctors().subscribe({
      next: (res: AboutMe) => {
        console.log(res);

        this.hasFirmPicture = !!res.data.firmPic;

        if (this.hasFirmPicture) {
          this.editProfileForm.get("signature").clearValidators();
        }

        if (res.data.providers) {
          this.profileProviders = res.data.providers;
        }

        const speciality = [];
        let otherSpecial;
        let otherSubSpecial;

        for (let i = 0; i < res.data.speciality.length; i++) {
          for (let k = 0; k < this.specialityList.length; k++) {
            if (res.data.speciality[i].id == this.specialityList[k]._id) {
              speciality.push(this.specialityList[k]);
              if (this.specialityList[k].isOther == true) {
                otherSpecial = res.data.speciality[i].other;
              }
            }
          }
        }
        const subSpeciality = [];
        for (let j = 0; j < res.data.subSpeciality.length; j++) {
          for (let sk = 0; sk < this.subSpecialityList.length; sk++) {
            if (
              res.data.subSpeciality[j].id == this.subSpecialityList[sk]._id
            ) {
              subSpeciality.push(this.subSpecialityList[sk]);
              if (this.subSpecialityList[sk].isOther == true) {
                otherSubSpecial = res.data.subSpeciality[j].other;
              }
            }
          }
        }

        this.editProfileForm.patchValue({
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          dateOfBirth: res.data.dateOfBirth,
          gender: res.data.gender,
          about: res.data.about,
          identificationNo: res.data.identificationNumber,
          speciality: speciality,
          subspeciality: subSpeciality,
          cmNO: res.data.cmNumber,
          mppsNo: res.data.mppsNumber,
          email: res.data.email,
          countryCode: res.data.countryCode,
          phoneNumber: res.data.phoneNumber,
          specialtyServed: res.data.specialtyServed?.map(
            (a) => a.subSpeciality || a.speciality,
          ),
          otherSpeciality: otherSpecial,
          otherSubSpeciality: otherSubSpecial,
        });
        this.doctorRole = res.data.doctorRole;
        this.specialtyServedList = [
          ...res.data.speciality,
          ...res.data.subSpeciality,
        ];
        this.specialtyServedList.sort((a, b) => {
          const nombreA =
            a[
              Object.keys(a).find(
                (key) => key == "subSpeciality" || key == "speciality",
              )
            ].es;
          const nombreB =
            b[
              Object.keys(b).find(
                (key) => key == "subSpeciality" || key == "speciality",
              )
            ].es;

          return nombreA.localeCompare(nombreB);
        });
        this.documentPath = res["meta"].fileUrl;
        this.profilePicPath = res.data.profilePic;
        this.profilePicUrl = this.documentPath + "/" + this.profilePicPath;
      },
      error: (error: HttpErrorResponse) => {
        this.handleError(error);
      },
    });
  }

  editDoctorInfo() {
    if (this.isSpecialistDoctor) {
      if (this.editProfileForm.value.specialtyServed.length == 0) {
        this.editProfileForm.controls["specialtyServed"].setErrors({
          required: true,
        });
        return;
      }
    }

    if (this.editProfileForm.invalid) {
      return;
    } else {
      this.editInformation = this.editProfileForm.value;

      const idsSet = new Set(this.editProfileForm.value.specialtyServed);

      const selectedSpeciality = this.specialtyServedList.filter((item) =>
        idsSet.has(item.id),
      );

      const data = {
        firstName: this.editInformation.firstName,
        lastName: this.editInformation.lastName,
        mppsNumber: this.editInformation.mppsNo,
        cmNumber: this.editInformation.cmNO,
        about: this.editInformation.about,
        identificationNumber: this.editInformation.identificationNo,
        phoneNumber: this.editInformation.phoneNumber,
        countryCode: this.editInformation.countryCode,
        dateOfBirth: this.editInformation.dateOfBirth,
        gender: this.editInformation.gender,
        speciality: this.editInformation.speciality.map((a: any) => a._id),
        subSpeciality:
          this.editInformation.subspeciality &&
          this.editInformation.subspeciality.length > 0
            ? this.editInformation.subspeciality.map((b: any) => b._id)
            : [],
        specialtyServed: selectedSpeciality.map((a) => ({
          speciality: a.speciality ? a.id : undefined,
          subSpeciality: a.subSpeciality ? a.id : undefined,
        })),
        otherSpeciality: this.editInformation.otherSpeciality,
        otherSubSpeciality: this.editInformation.otherSubSpeciality,
      };

      this.editProfileForm.get("signature").setValue("");

      let $editDoctorObservable;
      let form = new FormData();

      if (!this.hasFirmPicture && !this.sig1.signature) {
        this.signatureError.status = true;

        return;
      } else if (!this.hasFirmPicture && this.sig1.signature) {
        this.signatureError.status = false;
        form.append(
          "file",
          new File(
            [this.dataURItoBlob(this.sig1.signaturePad.toDataURL("png"))],
            "signature.csv",
          ),
        );
        $editDoctorObservable = concat(
          this.doctorService.updateFirmPic(form),
          this.doctorService.editDoctors(data),
        );
      } else {
        $editDoctorObservable = this.doctorService.editDoctors(data);
      }

      $editDoctorObservable.subscribe(
        (res: any) => {
          this.translationService
            .get("Profile has been Updated Successfully")
            .subscribe((text: string) => {
              this.sharedService.showMessage(text, "success");
            });
          this.router.navigate(["/"]);
        },
        (error: HttpErrorResponse) => {
          this.handleError(error);
        },
      );
    }
  }

  updateProfileInfo(event) {
    let fileName = event.target.files[0].name;
    let idxDot = fileName.lastIndexOf(".") + 1;
    let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
      //TO DO
    } else {
      this.translationService
        .get("Please upload only JPEG or PNG format")
        .subscribe((text: string) => {
          this.sharedService.showErrorMessage(text, "single");
        });
      return;
    }

    if (event.target.files[0].size > 5 * 1024 * 1024) {
      this.translationService
        .get("Size should be less than 5MB")
        .subscribe((text: string) => {
          this.sharedService.showErrorMessage(text, "single");
        });
      return;
    }

    let formData = new FormData();
    const file: File = event.target.files[0].name;
    formData.append("file", event.target.files[0]);
    this.doctorService.updateProfilePic(formData).subscribe(
      (res: any) => {
        this.documentPath = res["meta"].fileUrl;
        this.profilePicUrl = `${this.documentPath}/${res.data.profilePic}`;
        window.location.reload();
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      },
    );
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }

  navigateToHome() {
    this.router.navigate(["/"]);
  }

  // set the dimensions of the signature pad canvas
  public beResponsive() {
    this.ref.detectChanges();
  }
  /* 
  public size(container: ElementRef, sig: SignatureFieldComponent) {
    // this.sigContainer1.nativeElement.style.width = 100;
    console.log("container", container);
    if (sig) {
      sig.signaturePad["signaturePad"]._canvas.width =
        container.nativeElement.clientWidth;
    }
  } */

  /*  public setOptions() {
    this.sig1.signaturePad.set("penColor", "rgb(0, 0, 0)");
  } */

  public clear() {
    this.sig1.clearPad();
  }

  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    //Old Code
    //write the ArrayBuffer to a blob, and you're done
    //var bb = new BlobBuilder();
    //bb.append(ab);
    //return bb.getBlob(mimeString);

    //New Code
    return new Blob([ab], { type: mimeString });
  }
  // maxDeviation is the difference that is allowed default: 50kb
  // Example: targetFileSizeKb = 500 then result will be between 450kb and 500kb
  // increase the deviation to reduce the amount of iterations.
}
