<div class="reset-container">
  <app-header></app-header>
  <div class="container">
    <p *ngIf="showError" style="font-weight: bold; color: #ee2029">
      {{ errorMsg }}
    </p>
    <mat-card class="reset-card" *ngIf="!hideForm">
      <h4 class="heading">{{ "Set New Password" | translate }}</h4>

      <form
        [formGroup]="changepasswordForm"
        (ngSubmit)="changePasswordSubmit()"
        class="create-password-form"
      >
        <div class="text-start">
          <ul>
            <li>{{ "Use at least one letter." | translate }}</li>
            <li>{{ "Use at least one number." | translate }}</li>
            <li>
              {{
                "Have a length greater than or equal to 8 characters."
                  | translate
              }}
            </li>
          </ul>
        </div>
        <div class="row">
          <div class="col">
            <span class="label">{{ "New Password" | translate }}</span>
            <mat-form-field appearance="outline" class="my-form-field" >
              <input
                [type]="passwordHide ? 'password' : 'text'"
                matInput
                formControlName="password"
              />
              <mat-icon matPrefix class="mi-icono">lock_outline</mat-icon>
              <mat-icon
                matSuffix
                (click)="passwordHide = !passwordHide"
                class="mi-iconodos"
                >{{ passwordHide ? "visibility_off" : "visibility" }}</mat-icon
              >

              <mat-error
                *ngIf="changepasswordForm.get('password').hasError('required')"
              >
                {{ "Password is Required." | translate }}
              </mat-error>
              <mat-error
                *ngIf="
                  changepasswordForm.get('password').hasError('pattern') &&
                  !changepasswordForm.get('password').hasError('minlength') &&
                  !changepasswordForm.get('password').hasError('maxlength')
                "
              >
                {{
                  "Minimum 8 characters (1 numeric and 1 alphabet)" | translate
                }}
              </mat-error>
              <mat-error
                *ngIf="changepasswordForm.get('password').hasError('minlength')"
              >
                {{ "Password should be minimum of 8 characters." | translate }}
              </mat-error>
              <mat-error
                *ngIf="changepasswordForm.get('password').hasError('maxlength')"
              >
                {{ "Password should be maximum of 15 characters." | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col">
            <span class="label"> {{ "Repeat password" | translate }} </span>
            <mat-form-field
              appearance="outline"
              class="my-form-field"
            >
              <input
                [type]="confirmPasswordHide ? 'password' : 'text'"
                matInput
                formControlName="confirmPassword"
              />
              <mat-icon matPrefix class="mi-icono">lock_outline</mat-icon>
              <mat-icon
                matSuffix
                (click)="confirmPasswordHide = !confirmPasswordHide"
                class="mi-iconodos"
                >{{
                  confirmPasswordHide ? "visibility_off" : "visibility"
                }}</mat-icon
              >
              <mat-error
                *ngIf="
                  changepasswordForm.get('confirmPassword').hasError('required')
                "
              >
                {{ "Password is Required." | translate }}
              </mat-error>
              <mat-error
                *ngIf="
                  changepasswordForm
                    .get('confirmPassword')
                    .hasError('mustMatch')
                "
              >
                {{ "Password and Confirm Password do not match." | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <br />
        <div class="reset-button-row">
          <button [disabled]="loading"  mat-raised-button color="primary">
            {{ "Save" | translate }}
          </button>
        </div>
      </form>
    </mat-card>
  </div>
</div>
