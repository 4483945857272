<div style="padding: 24px">
  <mat-icon style="float: right; cursor: pointer" mat-dialog-close
    >close</mat-icon
  >
  <h5 class="heading">
    {{ "Are you sure you want to postpone the call?" | translate }}
  </h5>
  <div
    mat-dialog-actions
    class="postponeBtn"
    style="justify-content: space-around"
  >
    <span style="cursor: pointer" mat-dialog-close>{{
      "Cancel" | translate
    }}</span>
    <button
      mat-raised-button
      (click)="postponeCall()"
      class="large-button-blue mid-width"
    >
      {{ "Postpone" | translate }}
    </button>
  </div>
</div>
