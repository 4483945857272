<div class="container">
  <mat-icon style="float: right; cursor: pointer" mat-dialog-close
    >close</mat-icon
  >
  <h5 class="heading">
    {{ "You have a new attachement from the patient." | translate }}
  </h5>
  <div class="return-button-row">
    <button
      mat-raised-button
      (click)="viewDocuments()"
      class="large-button-blue"
    >
      {{ "View" | translate }}
    </button>
  </div>
</div>
