import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectorRef, Component } from "@angular/core";
import { Subscription } from "rxjs";
import { DashboardService } from "src/app/dashboard/dashboard.service";
import { SharedService } from "src/app/shared/shared.service";

@Component({
  selector: "app-agent-last-scheduled",
  templateUrl: "./last-scheduled.component.html",
  styleUrls: ["./last-scheduled.component.scss"],
})
export class AgentLastScheduledComponent {
  consults: any[] = [];
  private readonly subscriptions: Subscription = new Subscription();
  constructor(
    public readonly sharedService: SharedService,
    private readonly dashboardService: DashboardService,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.recentConstult();
  }

  recentConstult() {
    this.subscriptions.add(
      this.dashboardService
        .getConsults({
          "status[0]": 0,
          size: 100,
          appointmentStatus: 3,
        } as any)
        .subscribe({
          next: (res: any) => {
            this.consults = res.data.sort(
              (a, b) =>
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime(),
            );
            this.changeDetector.detectChanges();
          },
          error: (error: HttpErrorResponse) => {
            console.log(error);
          },
        }),
    );
  }
}
