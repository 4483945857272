<div style="padding: 16px">
  <!-- <mat-icon style="float: right; cursor: pointer;" mat-dialog-close
    >close</mat-icon
  > -->
  <h5 class="heading">{{ "How did the call go ?" | translate }}</h5>
  <h6 class="subheading" (click)="sucessPopup()">
    {{ "Success" | translate }}
  </h6>
  <h6 class="subheading" (click)="reconnectingPopup()">
    {{ "Not Successful" | translate }}
  </h6>
  <h6 *ngIf="data !== 3" class="paragraph" (click)="postponePopup()">
    {{ "Postpone" | translate }}
  </h6>
</div>
