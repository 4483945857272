import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ScheduleAppointmentComponent } from "../schedule-appointment/schedule-appointment.component";
import { Router } from "@angular/router";
import { ModalService } from "src/app/dashboard/modal.service";
import { SharedService } from "src/app/shared/shared.service";

@Component({
  selector: "app-agent-patient-info",
  templateUrl: "./patient-info.component.html",
  styleUrls: ["./patient-info.component\.scss"],
})
export class PatientInfoComponent implements OnChanges {
  @Input() patientInfo: any;
  @Input() consultationList: any[] = [];
  @Input() isAppointment: boolean = true;

  patient: any;
  subscriptions: any;
  showOverlayAffiliationsView = false;
  constructor(
    public readonly dialog: MatDialog,
    public readonly sharedService: SharedService,
    private readonly dashboardService: ModalService,
    private readonly router: Router,
    private readonly changeDetector: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.patientInfo) {
      this.getSubscriptionStatus();
    }
  }

  getSubscriptionStatus() {
    this.subscriptions = "";
    const subscriptionId = this.patientInfo?.patient?.parent
      ? this.patientInfo.patient.parent._id
      : this.patientInfo.patient._id;

    this.dashboardService
      .getSubscriptions(subscriptionId)
      .subscribe((res: any) => {
        this.subscriptions = res;
        this.changeDetector.detectChanges();
      });
  }

  getMembershipName() {
    return this.subscriptions
      ? this.subscriptions[0].name
      : "Sin membresía activa";
  }

  formatIdentification(identification: string) {
    const isNumeric = /^\d+$/.test(identification);
    if (isNumeric) {
      return parseInt(identification).toLocaleString("de-DE");
    } else {
      return identification;
    }
  }

  formatPhoneNumber() {
    const phoneNumber = this.patientInfo.patient.parent
      ? this.patientInfo.patient.parent.phoneNumber
      : this.patientInfo.patient.phoneNumber;
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  }

  getSpecialtyName(specialty: any) {
    if (specialty?.speciality) {
      return specialty.speciality.speciality.es;
    } else if (specialty?.subSpeciality) {
      return specialty.subSpeciality.subSpeciality.es;
    } else {
      return "";
    }
  }

  openScheduleFormDialog() {
    const dialogRef = this.dialog.open(ScheduleAppointmentComponent, {
      width: "480px",
      disableClose: true,
      panelClass: "schedule-appointment-container",
      autoFocus: false,
      data: {
        ...this.patientInfo,
        modalAction: "schedule",
        modalTitle: "Agendar cita médica",
        button: "Agendar",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === "close") {
        return;
      }
      if (result === true) {
        this.sharedService.showMessage("Cita agendada exitosamente", "success");
        this.router.navigate(["agent/dashboard"]);
      } else {
        this.sharedService.showErrorMessage(
          "La cita no pudo ser agendada",
          "success",
        );
      }
    });
  }
}
