import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./material.module";
import { LoginComponent } from "./login/login.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { CoreModule } from "./core/core.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptor } from "./http.interceptor";
import { SharedModule } from "./shared/shared.module";
import { HeaderComponent } from "./header/header.component";
import { RegistrationComponent } from "./registration/registration.component";
import { VerifyComponent } from "./verify/verify.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { CommonService } from "./dashboard/common.services";
import { MatTableModule } from "@angular/material/table";
//i,,,ort { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { MatFormFieldModule } from "@angular/material/form-field";
import { TranslateModule } from "@ngx-translate/core";
import { LoaderComponent } from "./loader/loader.component";
import { LoaderInterceptorService } from "./loader-interceptor.service";
import { CustomMatPaginatorIntl } from "./custom-mat-paginator-int";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { provideLottieOptions, LottieComponent } from "ngx-lottie";
import player from "lottie-web";
import localeEs from "@angular/common/locales/es";
import localeEn from "@angular/common/locales/en";
import { registerLocaleData } from "@angular/common";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { provideUserIdleConfig } from "angular-user-idle";
/* import { PushNotificationsModule } from "ng-push-ivy"; */
import { CallStateService } from "./shared/call-state/call-state.service";
import { MatGridList } from "@angular/material/grid-list";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TooltipDirective } from "./shared/tootip-directive/tooltip.directive";
import { SignatureModule } from "./shared/signature-module/signature.module";
import { Login2FAComponent } from "./login2-fa/login2-fa.component";
import { Login2FaTypeCodeComponent } from "./login2-fa-type-code/login2-fa-type-code.component";
import { NgOtpInputModule } from "ng-otp-input";
import { AgentModule } from "./agent/agent.module";
import { DashboardModule } from "./dashboard/dashboard.module";
import { VerifyAgentComponent } from "./verify-agent/verify-agent.component";
import { CreatePasswordComponent } from "./create-password/create-password.component";
import { CleverTapService } from "./clevertap.service";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";

registerLocaleData(localeEs);
registerLocaleData(localeEn);

export function createTranslateModule() {
  return TranslateModule.forRoot();
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PageNotFoundComponent,
    RegistrationComponent,
    VerifyComponent,
    VerifyAgentComponent,
    CreatePasswordComponent,
    LoaderComponent,
    Login2FAComponent,
    Login2FaTypeCodeComponent,
  ],
  imports: [
    LottieComponent,
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DashboardModule,
    CoreModule,
    FlexLayoutModule,
    SharedModule,
    BrowserAnimationsModule,
    MatTableModule,
    //  SatNativeDateModule,
    MatFormFieldModule,
    createTranslateModule(),

    /* PushNotificationsModule, */
    MatTooltipModule,
    SignatureModule,
    NgOtpInputModule,
    AgentModule,

    ////
  ],
  providers: [
    provideLottieOptions({
      player: () => player,
    }),
    provideUserIdleConfig({ idle: 1800, timeout: 10, ping: 12 }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: LoaderInterceptorService,
    //   multi: true,
    // },
    CommonService,
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl,
    },
    { provide: MAT_DATE_LOCALE, useValue: "es-ES" },
    CallStateService,
    CleverTapService,
    provideAnimationsAsync(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
