import { NgModule } from "@angular/core";
import { Routes, RouterModule, ExtraOptions } from "@angular/router";
import { AgentComponent } from "./agent.component";
import { AgentDashboardComponent } from "./components/dashboard/agent-dashboard.component";
import { PatientComponent } from "./components/patient/patient.component";
/* import { ConsultsPageComponent } from "./consults-page/consults-page.component";
import { QueuestatusComponent } from "./queuestatus/queuestatus.component";
import { MainPageComponent } from "./main-page/main-page.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { SearchComponent } from "./search/search.component";
import { MyPatientComponent } from "./my-patient/my-patient.component";
import { EditprofileComponent } from "./editprofile/editprofile.component"; */
const routes: Routes = [
  {
    path: "",
    component: AgentComponent,
    children: [
      {
        path: "",
        redirectTo: "/dashboard",
        loadComponent: () =>
          import(
            "./components/dashboard/appointment-list/appointment-list.component"
          ).then((m) => m.AppointmentListComponent),
        pathMatch: "full",
      },
      {
        path: "dashboard",
        component: AgentDashboardComponent,
        data: { animation: "AgentDashboardComponent" },
      },
      {
        path: "patient",
        component: PatientComponent,
        data: { animation: "PatientComponent" },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AgentRoutingModule {}
