<mat-toolbar fxLayout="row" color="primary">
  <img
    src="../../../assets/images/header-logo.svg"
    width="90px"
    (click)="gotoLandingPage()"
    style="cursor: pointer; height: 45px; bottom: 0"
  />

  <!-- global search-->
  <form [formGroup]="searchForm" fxFlex>
    <!-- {{booksName}} -->
    <div class="manual-input">
      <span matPrefix>
        <mat-icon style="color: black">search</mat-icon>
      </span>
      <input
        matInput
        placeholder="{{ 'Search...' | translate }}"
        aria-label="State"
        [matAutocomplete]="auto"
        formControlName="searchBar"
        class="search-form"
      />
      <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="displayFn"
        (optionSelected)="gotoPatientDetails($event.option.value)"
      >
        <mat-option
          style="font-size: 14px !important"
          *ngIf="!isLoading && searchResult.length < 1"
          class="is-loading"
        >
          {{ "No data available" | translate }}</mat-option
        >
        <ng-container *ngIf="!isLoading">
          <mat-option
            style="font-size: 12px !important"
            *ngFor="let option of searchResult | orderBy: 'firstName' : 'ASC'"
            [value]="option"
          >
            <div class="search-details">
              <span style="text-transform: capitalize" class="search-list">{{
                option.firstName + " " + option.lastName
              }}</span>

              <span class="search-list">{{ option.identificationNumber }}</span>

              <span
                *ngIf="option.parent; else childSection"
                class="search-list"
                >{{
                  option.parent.countryCode + "-" + option.parent.phoneNumber
                }}</span
              >
              <ng-template #childSection class="search-list">
                {{ option.countryCode + "-" + option.phoneNumber }}
              </ng-template>
            </div>
          </mat-option>
        </ng-container>
      </mat-autocomplete>
    </div>
    <!-- <span *ngIf="searchResult.length === 0">Not Found</span>     -->
  </form>
  <!-- end of global search-->

  <div
    *ngIf="role != 'agent'"
    [routerLink]="['/dashboard']"
    [routerLinkActive]="['active']"
    fxShow
    fxHide.lt-md
  >
    <button mat-button aria-label="My Dashboard" style="color: black">
      {{ "My Dashboard" | translate }}
    </button>
  </div>
  <span
    *ngIf="role != 'agent'"
    [routerLink]="['/patient']"
    [routerLinkActive]="['active']"
    fxShow
    fxHide.lt-md
  >
    <button mat-button aria-label="My Patients" style="color: black">
      {{ "My Patients" | translate }}
    </button>
  </span>

  <button mat-icon-button [matMenuTriggerFor]="dropMenu" fxHide fxShow.lt-md>
    <mat-icon color="primary">more_vert</mat-icon>
  </button>

  <span>
    <button
      mat-icon-button
      [matMenuTriggerFor]="menu"
      aria-label="Example icon-button with a menu"
      style="padding: 0px !important"
    >
      <!-- <ngx-avatar [name]="userName" size="40"></ngx-avatar> -->
      <img
        *ngIf="profilePicPath; else defaultProfile"
        style="border-radius: 50%; width: 40px; height: 40px"
        [src]="profilePic"
      />
      <ng-template #defaultProfile>
        <ngx-avatars [name]="userName" size="40"></ngx-avatars>
      </ng-template>
    </button>

    <mat-menu #menu="matMenu">
      <button *ngIf="role != 'agent'" mat-menu-item (click)="myProfile()">
        <mat-icon>
          <img src="assets/images/my-profile.svg" />
        </mat-icon>
        <span>{{ "My Profile" | translate }}</span>
      </button>
      <mat-divider *ngIf="role != 'agent'"></mat-divider>
      <button *ngIf="role != 'agent'" mat-menu-item (click)="changePassword()">
        <mat-icon>
          <img src="assets/images/lock.svg" />
        </mat-icon>
        <span>{{ "Change Password" | translate }}</span>
      </button>
      <mat-divider *ngIf="role != 'agent'"></mat-divider>
      <button mat-menu-item (click)="termConditions()">
        <mat-icon>
          <img src="assets/images/check-square.svg" />
        </mat-icon>
        <span>{{ "Terms and condition" | translate }}</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="privacy()">
        <mat-icon>
          <img src="assets/images/check-round.svg" />
        </mat-icon>
        <span>{{ "Privicy Policy" | translate }}</span>
      </button>
      <mat-divider></mat-divider>
      <!-- <button mat-menu-item (click)="changeLanguage('en-US')">
            <mat-icon>language</mat-icon>
            <span>English</span>
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="changeLanguage('es-ES')">
            <mat-icon>language</mat-icon>
            <span>Spanish</span>
          </button>
          <mat-divider></mat-divider> -->
      <button mat-menu-item (click)="logOut()">
        <mat-icon color="warn">
          <img src="assets/images/power.svg" />
        </mat-icon>
        <span style="color: #ee2029">{{ "Logout" | translate }}</span>
      </button>
    </mat-menu>
  </span>

  <mat-menu #dropMenu="matMenu">
    <!-- <button mat-menu-item>
                  <mat-icon class="mr">{{item.icon}}</mat-icon>
                  {{item.label}}
              </button> -->
    <span [routerLink]="['/dashboard']" [routerLinkActive]="['active']">
      <button mat-button aria-label="My Dashboard" style="color: black">
        {{ "My Dashboard" | translate }}
      </button>
    </span>
    <br />

    <span [routerLink]="['/patient']" [routerLinkActive]="['active']">
      <button mat-button aria-label="My Patients" style="color: black">
        {{ "My Patients" | translate }}
      </button>
    </span>
  </mat-menu>
</mat-toolbar>
