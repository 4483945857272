<mat-dialog-content class="dialog-content">
  <mat-icon style="float: right; cursor: pointer" mat-dialog-close
    >close</mat-icon
  >
  <div class="call-icon">
    <mat-icon> add_call </mat-icon>
  </div>
  <div class="title-and-subtitle">
    <h1 class="title">
      {{ "Schedule follow-up consultation modal" | translate }}
    </h1>
    <p class="subtitle">
      {{
        "Select a date to know the number of consultations scheduled for that day"
          | translate
      }}.
    </p>
  </div>

  <div class="date-picker">
    <mat-form-field appearance="fill">
      <input
        matInput
        [matDatepicker]="picker"
        [formControl]="date"
        [min]="minDate"
        (dateChange)="onDateChange()"
        disabled
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker disabled="false"></mat-datepicker>
      <mat-hint>{{ "DD/MM/YYYY" | translate }}</mat-hint>
    </mat-form-field>
  </div>

  <div class="searching" *ngIf="loading && !error">
    <span>{{ "Searching" | translate }}...</span>
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>

  <div class="scheduled-calls" *ngIf="!loading && !error">
    <div class="box">
      <div class="title">
        <mat-icon> info </mat-icon>
        <span>
          {{ "Number of scheduled calls" | translate }}
        </span>
      </div>
      <div class="info">
        <div class="number">
          {{ count }}
        </div>
      </div>
    </div>
  </div>

  <div class="error-message" *ngIf="!loading && error">
    <mat-icon>error</mat-icon>
    <span>{{
      "An error occurred during the search, please try again" | translate
    }}</span>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button
    mat-button
    class="schedule-follow-up large-button-blue mid-width"
    (click)="save()"
    [disabled]="loading && !error"
    cdkFocusInitial
  >
    {{ "Schedule follow-up" | translate }}
  </button>
</mat-dialog-actions>
