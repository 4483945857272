import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SpecialtiesService } from "src/app/core/specialties.service";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { DashboardService } from "src/app/dashboard/dashboard.service";
import { ChangeDetectorRef } from "@angular/core";

export interface ConsultationDetailsPatient {
  data: Datum[];
  meta: Meta;
}

export interface Datum {
  _id: string;
  chiefComplaint: ChiefComplaint;
  communicationMode: number;
  document: any[];
  informedConsent: boolean;
  patient: Ent;
  parent: Ent;
  doctor: Doctor;
  provider: string;
  status: number;
  consultationType: number;
  appointmentType: number;
  appointmentStatus: number;
  appointmentRescheduledCount: number;
  appointmentRescheduledByDoctorCount: number;
  action: any[];
  paraclinicalPrescription: any[];
  medicalPrescription: any[];
  providerCall: string;
  originCall: number;
  createdAt: Date;
  medicalSpecialty?: string;
}

export interface ChiefComplaint {
  id: string;
  chiefComplaint: SpecialityClass;
}

export interface SpecialityClass {
  en: string;
  es: string;
}

export interface Doctor {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  cmNumber: string;
  countryCode: string;
  identificationNumber: string;
  mppsNumber: string;
  phoneNumber: string;
  speciality: Speciality[];
  subSpeciality: SubSpeciality[];
}

export interface Speciality {
  id: string;
  speciality: SpecialityClass;
}

export interface SubSpeciality {
  id: string;
  subSpeciality: SpecialityClass;
}

export interface Ent {
  _id: string;
  firstName: string;
  lastName: string;
  identificationNumber: string;
  countryCode: string;
  phoneNumber: string;
  email: string;
}

export interface Meta {
  fileUrl: string;
  videoRecordingFileUrl: string;
}

@Component({
  selector: "app-agent-patient",
  templateUrl: "./patient.component.html",
  styleUrls: ["./patient.component.scss"],
})
export class PatientComponent implements OnInit {
  patient: any;
  consultationList: any[] = [];
  onlyOne = false;
  loading = true;
  private readonly subscriptions: Subscription = new Subscription();
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly specialtiesService: SpecialtiesService,
    private readonly dashboardService: DashboardService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.loading = true;
      const appointment = params.appointment;
      const patientId = params.patient;

      if (appointment == "true") {
        this.onlyOne = true;
        this.patientContainerDetails(patientId);
      } else {
        this.onlyOne = false;
        this.getPatientDetails(patientId);
      }
    });
  }
  getPatientDetails(id: string) {
    this.subscriptions.add(
      this.dashboardService.getPatientDetials(id).subscribe({
        next: (res: any) => {
          this.patient = { patient: res };
          this.getConsultationList(id);
        },
      }),
    );
  }

  patientContainerDetails(id: string) {
    this.subscriptions.add(
      this.dashboardService.getConsultationDetails(id).subscribe({
        next: (res: ConsultationDetailsPatient) => {
          this.loading = false;
          const { data }: ConsultationDetailsPatient = res;
          if (data.length > 0) {
            this.patient = data[0];
          }
          this.consultationList = [];
          this.cdr.detectChanges();
        },
        error: (err) => {
          console.log(err);
        },
      }),
    );
  }
  /*   getConsultationList(param: object) {
    const httpParams = this.getHttpParams(param);
    return this.http.get(`/consultations`, { params: httpParams });
  }
 */

  getConsultationList(id) {
    const params = {
      patient: id,
      "status[0]": 0,
      "status[1]": 1,
      "status[2]": 6,
      "appointmentStatus[0]": 3,
      "appointmentStatus[1]": 4,
      "appointmentStatus[2]": 5,
      "appointmentStatus[3]": 6,
      "appointmentStatus[4]": 7,
      "appointmentStatus[5]": 8,
      "appointmentStatus[6]": 9,
      size: 1000,
      /* "appointmentStatus[5]": 8, */
    };
    this.subscriptions.add(
      this.dashboardService
        .getConsultationList(params)
        .subscribe((res: any) => {
          this.loading = false;
          this.consultationList = res.data;
          this.cdr.detectChanges();
        }),
    );
  }

  goDashboard() {
    this.router.navigate(["agent/dashboard"]);
  }
}
