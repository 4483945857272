<div class="forgot-container">
  <app-header></app-header>
  <div class="container">
    <div style="text-align: center">
      <h4 class="heading" style="margin-top: 0px">
        {{ "Forgot Password" | translate }}
      </h4>
      <p class="subheading">
        {{ "Please enter your registered email below" | translate }}
      </p>
    </div>
    <mat-card class="forgot-card">
      <form
        [formGroup]="forgotpasswordForm"
        (ngSubmit)="forgotPasswordSubmit()"
      >
        <p
          style="
            text-align: initial;
            color: #46535b;
            font-weight: 600;
            margin: 0px !important;
          "
        >
          {{ "We will send you link to reset your password" | translate }}
        </p>
        <mat-form-field class="my-form-field" style="width: 100%">
          <mat-label>{{ "Email" | translate }}</mat-label>
          <input
            type="text"
            matInput
            autocomplete="email"
            placeholder=""
            formControlName="email"
          />
          <mat-error
            *ngIf="forgotpasswordForm.get('email').hasError('required')"
          >
            {{ "Email is Required." | translate }}
          </mat-error>
          <mat-error
            *ngIf="forgotpasswordForm.get('email').hasError('pattern')"
          >
            {{ "Please enter a valid email" | translate }}
          </mat-error>
        </mat-form-field>
        <p class="msg" *ngIf="showSuccessMsg">
          {{
            "The reset password link has been sent to your registered mail."
              | translate
          }}
        </p>
        <p *ngIf="showErrorMsg" style="color: red; font-weight: bold">
          errorMsg
        </p>
        <div class="forgot-button-row">
          <button
            class="large-button-blue medium-width"
            style="width: 80% !important"
            mat-raised-button
            [disabled]="loading || disableBtn"
          >
            {{ "Continue" | translate }}
          </button>
        </div>
      </form>
    </mat-card>
  </div>
</div>
