import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  Input,
  SimpleChanges,
  SimpleChange,
  OnChanges,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  FormBuilder,
  FormArray,
  Validators,
  NgForm,
} from "@angular/forms";
import { DashboardService } from "../dashboard.service";
import { DataStoreServiceService } from "../data-store-service.service";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { CommonService } from "../common.services";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { ModalService } from "../modal.service";
import { ActivatedRoute } from "@angular/router";
import _ from "lodash";
import { HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { SharedService } from "src/app/shared/shared.service";
import { CredentialsService } from "src/app/core/credentials.service";

export class PageParams {
  patient: string;
  status: number;
  size: number;
  skip: number;
}

export enum Status {
  DRAFT = 0,
  WAITING = 1,
  ACTIVE = 2,
  CALL_STARTED = 3,
  CALL_COMPLETED = 4,
  COMPLETED = 5,
  CANCELLED = 6,
  DELETED = 7
}

@Component({
  selector: "app-medical-history",
  templateUrl: "./medical-history.component.html",
  styleUrls: ["./medical-history.component.scss"],
})
export class MedicalHistoryComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  @Input() isEditable: boolean;
  medicaldata = false;
  otherField = false;
  medicalField = false;
  illness: any = [];
  diseaseCondition: any = [];
  individualPatient;
  public singPatientRecord: any;
  medicalHistory;
  maxDate = new Date();
  visible = true;
  selectable = true;
  removable = false;
  addOnBlur = true;
  currentLanguage = "es";
  showOtherChildillness: boolean = false;
  showOtherMedicalCondition: boolean = false;
  showOtherFatherIllness: boolean = false;
  showOtherMotherIllness: boolean = false;
  showOtherBrotherIllness: boolean = false;
  showOtherSisterIllness: boolean = false;
  showOtherSonIllness: boolean = false;
  showOtherDaughterIllness: boolean = false;
  primary: false;
  medicalHistoryForm: FormGroup;
  loading: boolean = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  patientId: string;
  callFeedbackSubscription: Subscription;
  isOriginalDoctor: boolean = false;
  isFollowUpDoctor: boolean = false;
  isEditTimeOver: boolean = true;
  isEditFollowupTimeOver: boolean = true;
  isEditFollowUpInTime: boolean = false;
  isEditFollowUp2InTime: boolean = false;
  isEditFollowUp3InTime: boolean = false;
  showEditButton: boolean = false;
  showTooltipInfo: boolean = false;
  pageParams: Partial<PageParams> = { size: 100, skip: 0 };

  constructor(
    private fb: FormBuilder,
    private dashboardService: DashboardService,
    private credentialService: CredentialsService,
    private store: DataStoreServiceService,
    private commonServices: CommonService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private sharedService: SharedService,
  ) {
    this.getIllness();
    this.getDisease();

    this.modalService.vscallStatusObservable.subscribe((res) => {
      if (res.callStarted == true) {
        this.enableEditView();
      }
    });
  }


  getMedicalHistory(patientId) {
    this.dashboardService.getMedicalHistory({
        patient: patientId,
      }).subscribe({
        next: (res: any) => {
          this.singPatientRecord = res;
          this.editData(res);
        },
        error: (error: HttpErrorResponse) => {
          if (error.status == 404) {
            this.singPatientRecord = null;
            this.setEmptyValues();
          }
        },
      });
  }

  ngOnChanges(sc: SimpleChanges) {
    if (sc.isEditable.currentValue) {
      this.enableEditView();
      this.medicaldata = true;
    }
  }

  ngOnInit() {
    console.log("init medical history");
    
    if (this.translateService.currentLang == "en-US") {
      this.currentLanguage = "en";
    }

    this.buildForm();

    this.childotherField();
    this.medicalOtherField();
    this.fatherHistory();
    this.motherHistory();
    this.brotherHistory();
    this.sisterHistory();
    this.sonHistory();
    this.daughterHistory();

    this.route.queryParams.subscribe((params) => {
      this.patientId = params.patient;
      console.log("params", params);
      
      this.getMedicalHistory(this.patientId);
      
      //todo: para que traigo las consultas? 
      this.checkConsultationList();
    });
  }

  buildForm() {
    this.medicalHistoryForm = this.fb.group({
      surgery: this.fb.array([this.createItem()]),
      childillness: [""],
      otherChildillness: [""],
      medicalCondition: [""],
      otherMedicalCondition: [""],
      allergies: this.fb.array([]),
      smoking: [false],
      drinking: [false],
      father: [""],
      otherFatherMedicalCondition: [""],
      mother: [""],
      otherMotherMedicalCondition: [""],
      sister: [""],
      otherSisterMedicalCondition: [""],
      brother: [""],
      otherBrotherMedicalCondition: [""],
      son: [""],
      otherSonMedicalCondition: [""],
      daughter: [""],
      otherDaughterMedicalCondition: [""],
      height: ["", Validators.pattern(/^\d{0,1}(\.\d{0,2})?$/)],
      weight: ["", Validators.pattern(/^\d{0,3}(\.\d{0,2})?$/)],
    });
  }

  enableEditView() {
    this.medicaldata = true;
    this.removable = true;
    this.medicalHistoryForm.enable();

    const heightWithoutSufix = (
      this.medicalHistoryForm.get("height").value ?? ("" as String)
    ).replace(" m", "");
    this.medicalHistoryForm.controls["height"].setValue(heightWithoutSufix);

    const weightWithoutSuffix = (
      this.medicalHistoryForm.get("weight").value ?? ("" as String)
    ).replace(" kg", "");
    this.medicalHistoryForm.controls["weight"].setValue(weightWithoutSuffix);
    this.medicalHistoryForm.updateValueAndValidity();
  }

  ngAfterViewInit() {}

  removeEmptyKeys(obj) {
    for (var propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === "" ||
        obj[propName].length < 1
      ) {
        delete obj[propName];
      }
    }
    return obj;
  }

  createItem() {
    return this.fb.group({
      surgery: [""],
      date: [""],
    });
  }

  addItem() {
    const control = <FormArray>this.medicalHistoryForm.controls["surgery"];
    control.push(this.createItem());
  }

  removeInput(index) {
    (
      (<FormArray>this.medicalHistoryForm.controls["surgery"]) as FormArray
    ).removeAt(index);
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };

  editData(singPatientRecord) {
    this.medicalHistoryForm.reset();
    this.clearFormArray(this.medicalHistoryForm.get("allergies") as FormArray);
    this.clearFormArray(this.medicalHistoryForm.get("surgery") as FormArray);
    var childhoodIllness = [];
    var medicalConditions = [];
    var fatherHistory = [];
    var otherFatherHistory: string;
    var motherHistory = [];
    var otherMotherHistory: string;
    var sisterHistory = [];
    var otherSisterHistory: string;
    var brotherHistory = [];
    var otherBrotherHistory: string;
    var sonHistory = [];
    var otherSonHistory: string;
    var daugtherHistory = [];
    var otherDaughterHistory: string;
    var hasAlcoholHabit: boolean = false;
    var hasSmokingHabit: boolean = false;
    var otherChildhoodIllness: string;
    var otherMedicalConditions: string;

    // childhoodIllness data manipulation
    for (var arr in this.illness) {
      for (var filter in singPatientRecord.childhoodIllness) {
        if (
          this.illness[arr]._id == singPatientRecord.childhoodIllness[filter].id
        ) {
          childhoodIllness.push(this.illness[arr]);
          if (this.illness[arr].isOther == true) {
            otherChildhoodIllness =
              singPatientRecord.childhoodIllness[filter].other;
          }
        }
      }
    }

    // Medical condition data manipulation
    for (var arr in this.diseaseCondition) {
      for (var filter in singPatientRecord.medicalCondition) {
        if (
          this.diseaseCondition[arr]._id ==
          singPatientRecord.medicalCondition[filter].id
        ) {
          medicalConditions.push(this.diseaseCondition[arr]);
          if (this.diseaseCondition[arr].isOther == true) {
            otherMedicalConditions =
              singPatientRecord.medicalCondition[filter].other;
          }
        }
      }
    }

    //hasAlcoholHabit
    if (singPatientRecord.hasAlcoholHabit) {
      hasAlcoholHabit = true;
    } else {
      hasAlcoholHabit = false;
    }

    //hasSmokingHabit
    if (singPatientRecord.hasSmokingHabit) {
      hasSmokingHabit = true;
    } else {
      hasSmokingHabit = false;
    }

    // Family history data manipulation
    for (var fh in singPatientRecord.familyHistory) {
      for (var dc in this.diseaseCondition) {
        // Father
        if (
          singPatientRecord.familyHistory[fh].relationship == 1 &&
          singPatientRecord.familyHistory[fh].disease.id ==
            this.diseaseCondition[dc]._id
        ) {
          fatherHistory.push(this.diseaseCondition[dc]);
          if (this.diseaseCondition[dc].isOther == true) {
            otherFatherHistory =
              singPatientRecord.familyHistory[fh].disease.other;
          }
        }

        // Mother
        else if (
          singPatientRecord.familyHistory[fh].relationship == 2 &&
          singPatientRecord.familyHistory[fh].disease.id ==
            this.diseaseCondition[dc]._id
        ) {
          motherHistory.push(this.diseaseCondition[dc]);
          if (this.diseaseCondition[dc].isOther == true) {
            otherMotherHistory =
              singPatientRecord.familyHistory[fh].disease.other;
          }
        }

        // Brother
        else if (
          singPatientRecord.familyHistory[fh].relationship == 3 &&
          singPatientRecord.familyHistory[fh].disease.id ==
            this.diseaseCondition[dc]._id
        ) {
          brotherHistory.push(this.diseaseCondition[dc]);
          if (this.diseaseCondition[dc].isOther == true) {
            otherBrotherHistory =
              singPatientRecord.familyHistory[fh].disease.other;
          }
        }

        // Sister
        else if (
          singPatientRecord.familyHistory[fh].relationship == 4 &&
          singPatientRecord.familyHistory[fh].disease.id ==
            this.diseaseCondition[dc]._id
        ) {
          sisterHistory.push(this.diseaseCondition[dc]);
          if (this.diseaseCondition[dc].isOther == true) {
            otherSisterHistory =
              singPatientRecord.familyHistory[fh].disease.other;
          }
        }

        // Son
        else if (
          singPatientRecord.familyHistory[fh].relationship == 5 &&
          singPatientRecord.familyHistory[fh].disease.id ==
            this.diseaseCondition[dc]._id
        ) {
          sonHistory.push(this.diseaseCondition[dc]);
          if (this.diseaseCondition[dc].isOther == true) {
            otherSonHistory = singPatientRecord.familyHistory[fh].disease.other;
          }
        }

        // Daughter
        else if (
          singPatientRecord.familyHistory[fh].relationship == 6 &&
          singPatientRecord.familyHistory[fh].disease.id ==
            this.diseaseCondition[dc]._id
        ) {
          daugtherHistory.push(this.diseaseCondition[dc]);
          if (this.diseaseCondition[dc].isOther == true) {
            otherDaughterHistory =
              singPatientRecord.familyHistory[fh].disease.other;
          }
        }
      }
    }

    this.medicalHistoryForm.patchValue({
      childillness: childhoodIllness,
      otherChildillness: otherChildhoodIllness,
      medicalCondition: medicalConditions,
      otherMedicalCondition: otherMedicalConditions,
      smoking: hasSmokingHabit,
      drinking: hasAlcoholHabit,
      father: fatherHistory,
      otherFatherMedicalCondition: otherFatherHistory,
      mother: motherHistory,
      otherMotherMedicalCondition: otherMotherHistory,
      brother: brotherHistory,
      otherBrotherMedicalCondition: otherBrotherHistory,
      sister: sisterHistory,
      otherSisterMedicalCondition: otherSisterHistory,
      son: sonHistory,
      otherSonMedicalCondition: otherSonHistory,
      daughter: daugtherHistory,
      otherDaughterMedicalCondition: otherDaughterHistory,
      height: singPatientRecord.height / 100 + " m",
      weight: singPatientRecord.weight + " kg",
    });

    if (singPatientRecord.allergy.length > 0) {
      this.medicalHistoryForm.setControl(
        "allergies",
        this.setExistAllergies(singPatientRecord.allergy),
      );
    }
    if (singPatientRecord.surgery.length > 0) {
      this.medicalHistoryForm.setControl(
        "surgery",
        this.setExistSurgeries(singPatientRecord.surgery),
      );
    } else {
      this.addItem();
    }
    this.medicalHistoryForm.disable();
    this.loading = false;
  }

  setExistSurgeries(list: any[]): FormArray {
    const formArray = new FormArray([]);
    list.forEach((s) => {
      formArray.push(
        this.fb.group({
          surgery: s.surgery,
          date: s.date,
        }),
      );
    });
    return formArray;
  }

  setExistAllergies(list: any[]): FormArray {
    const allergyTags = this.medicalHistoryForm.get("allergies") as FormArray;
    //const formArray = new FormArray([]);
    list.forEach((s) => {
      allergyTags.push(this.fb.control(s.trim()));
    });
    return allergyTags;
  }

  getIllness() {
    this.dashboardService.getIllness().subscribe(
      (res: any) => {
        this.illness = res;
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      },
    );
  }

  getDisease() {
    this.dashboardService.getDisease().subscribe(
      (res: any) => {
        this.diseaseCondition = res;
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      },
    );
  }

  editMedicalDetails() {
    if (!this.medicalHistoryForm.valid) {
      this.translateService
        .get("Please fill the mandatory fields in the medical history form")
        .subscribe((text: string) => {
          alert(text);
        });
      return;
    }

    let numberPattern: RegExp = new RegExp(/^\d{0,3}(\.\d{0,2})?$/);
    if (
      this.medicalHistoryForm.value.height &&
      String(this.medicalHistoryForm.value.height).length > 0
    ) {
      if (!numberPattern.test(this.medicalHistoryForm.value.height)) {
        this.translateService
          .get("Height should be valid")
          .subscribe((text: string) => {
            alert(text);
          });
        return;
      }
    }
    if (
      this.medicalHistoryForm.value.weight &&
      String(this.medicalHistoryForm.value.weight).length > 0
    ) {
      if (!numberPattern.test(this.medicalHistoryForm.value.weight)) {
        this.translateService
          .get("Weight should be valid")
          .subscribe((text: string) => {
            alert(text);
          });
        return;
      }
    }

    let surgeryList = this.medicalHistoryForm.value.surgery;
    for (var f = 0; f < surgeryList.length; f++) {
      if (!surgeryList[f].surgery && surgeryList[f].date) {
        this.translateService
          .get("Please enter a surgery name in Medical history form")
          .subscribe((text: string) => {
            alert(text);
          });
        return;
      }
    }

    var finalChildhoodIllness = [];
    var finalMedicalCondition = [];
    var finalFatheristory = [];
    var finalMotherHistory = [];
    var finalBrotherHistory = [];
    var finalSisterHistory = [];
    var finalSonHistory = [];
    var finalDaughterHistory = [];
    var finalFamilyHistory = [];

    for (var ci in this.medicalHistoryForm.value.childillness) {
      if (this.medicalHistoryForm.value.childillness[ci].isOther == true) {
        finalChildhoodIllness.push({
          illness: this.medicalHistoryForm.value.childillness[ci]._id,
          other: this.medicalHistoryForm.value.otherChildillness,
        });
      } else {
        finalChildhoodIllness.push({
          illness: this.medicalHistoryForm.value.childillness[ci]._id,
        });
      }
    }

    for (var mc in this.medicalHistoryForm.value.medicalCondition) {
      if (this.medicalHistoryForm.value.medicalCondition[mc].isOther == true) {
        finalMedicalCondition.push({
          disease: this.medicalHistoryForm.value.medicalCondition[mc]._id,
          other: this.medicalHistoryForm.value.otherMedicalCondition,
        });
      } else {
        finalMedicalCondition.push({
          disease: this.medicalHistoryForm.value.medicalCondition[mc]._id,
        });
      }
    }

    for (var fh in this.medicalHistoryForm.value.father) {
      if (this.medicalHistoryForm.value.father[fh].isOther == true) {
        finalFatheristory.push({
          relationship: 1,
          disease: this.medicalHistoryForm.value.father[fh]._id,
          other: this.medicalHistoryForm.value.otherFatherMedicalCondition,
        });
      } else {
        finalFatheristory.push({
          relationship: 1,
          disease: this.medicalHistoryForm.value.father[fh]._id,
        });
      }
    }

    for (var mh in this.medicalHistoryForm.value.mother) {
      if (this.medicalHistoryForm.value.mother[mh].isOther == true) {
        finalMotherHistory.push({
          relationship: 2,
          disease: this.medicalHistoryForm.value.mother[mh]._id,
          other: this.medicalHistoryForm.value.otherMotherMedicalCondition,
        });
      } else {
        finalMotherHistory.push({
          relationship: 2,
          disease: this.medicalHistoryForm.value.mother[mh]._id,
        });
      }
    }

    for (var bh in this.medicalHistoryForm.value.brother) {
      if (this.medicalHistoryForm.value.brother[bh].isOther == true) {
        finalBrotherHistory.push({
          relationship: 3,
          disease: this.medicalHistoryForm.value.brother[bh]._id,
          other: this.medicalHistoryForm.value.otherBrotherMedicalCondition,
        });
      } else {
        finalBrotherHistory.push({
          relationship: 3,
          disease: this.medicalHistoryForm.value.brother[bh]._id,
        });
      }
    }

    for (var sh in this.medicalHistoryForm.value.sister) {
      if (this.medicalHistoryForm.value.sister[sh].isOther == true) {
        finalSisterHistory.push({
          relationship: 4,
          disease: this.medicalHistoryForm.value.sister[sh]._id,
          other: this.medicalHistoryForm.value.otherSisterMedicalCondition,
        });
      } else {
        finalSisterHistory.push({
          relationship: 4,
          disease: this.medicalHistoryForm.value.sister[sh]._id,
        });
      }
    }

    for (var soh in this.medicalHistoryForm.value.son) {
      if (this.medicalHistoryForm.value.son[soh].isOther == true) {
        finalSonHistory.push({
          relationship: 5,
          disease: this.medicalHistoryForm.value.son[soh]._id,
          other: this.medicalHistoryForm.value.otherSonMedicalCondition,
        });
      } else {
        finalSonHistory.push({
          relationship: 5,
          disease: this.medicalHistoryForm.value.son[soh]._id,
        });
      }
    }

    for (var dh in this.medicalHistoryForm.value.daughter) {
      if (this.medicalHistoryForm.value.daughter[dh].isOther == true) {
        finalDaughterHistory.push({
          relationship: 6,
          disease: this.medicalHistoryForm.value.daughter[dh]._id,
          other: this.medicalHistoryForm.value.otherDaughterMedicalCondition,
        });
      } else {
        finalDaughterHistory.push({
          relationship: 6,
          disease: this.medicalHistoryForm.value.daughter[dh]._id,
        });
      }
    }

    finalFamilyHistory = finalFamilyHistory.concat(
      finalFatheristory,
      finalMotherHistory,
      finalBrotherHistory,
      finalSisterHistory,
      finalSonHistory,
      finalDaughterHistory,
    );

    let apiData = {
      patient: this.patientId,
      hasSmokingHabit: this.medicalHistoryForm.value.smoking,
      hasAlcoholHabit: this.medicalHistoryForm.value.drinking,
      allergy: this.medicalHistoryForm.value.allergies,
      childhoodIllness: finalChildhoodIllness,
      medicalCondition: finalMedicalCondition,
      surgery: this.medicalHistoryForm.value.surgery,
      familyHistory: finalFamilyHistory,
      height: Number(this.medicalHistoryForm.value.height * 100),
      weight: Number(this.medicalHistoryForm.value.weight),
    };

    apiData = this.removeEmptyKeys(apiData);

    let ppp = apiData["surgery"].filter(
      (item) => item.surgery != null && item.surgery != "",
    );

    for (var i = 0; i < ppp.length; i++) {
      ppp[i] = this.removeEmptyKeys(ppp[i]);
    }
    apiData["surgery"] = ppp;

    this.dashboardService.editMedicalHistory(apiData).subscribe(
      (res: any) => {
        this.translateService
          .get("Medical History Updated Successfully")
          .subscribe((text: string) => {
            this.sharedService.showMessage(text, "success");
            if (this.showTooltipInfo) {
              this.showEditButton = true;
              this.disableEditView();
            }
          });
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      },
    );
  }

  childotherField() {
    var tempOtherChildillness =
      this.medicalHistoryForm.get("otherChildillness");
    this.medicalHistoryForm
      .get("childillness")
      .valueChanges.subscribe((val) => {
        if (val != null && val != undefined && val != "") {
          const checkOtherFieldExistence = val.some(function (el) {
            return el.isOther === true;
          });

          if (checkOtherFieldExistence) {
            this.showOtherChildillness = true;
            tempOtherChildillness.setValidators([Validators.required]);
            tempOtherChildillness.markAsDirty();
            tempOtherChildillness.markAsTouched();
            tempOtherChildillness.updateValueAndValidity();
          } else {
            this.showOtherChildillness = false;
            tempOtherChildillness.setValue("");
            tempOtherChildillness.clearValidators();
            tempOtherChildillness.updateValueAndValidity();
          }
        } else {
          this.showOtherChildillness = false;
          tempOtherChildillness.setValue("");
          tempOtherChildillness.clearValidators();
          tempOtherChildillness.updateValueAndValidity();
        }
      });
  }

  medicalOtherField() {
    var tempOtherMedicalCondition = this.medicalHistoryForm.get(
      "otherMedicalCondition",
    );
    this.medicalHistoryForm
      .get("medicalCondition")
      .valueChanges.subscribe((val) => {
        if (val != null && val != undefined && val != "") {
          const checkOtherFieldExistence = val.some(function (el) {
            return el.isOther === true;
          });

          if (checkOtherFieldExistence) {
            this.showOtherMedicalCondition = true;
            tempOtherMedicalCondition.setValidators([Validators.required]);
            tempOtherMedicalCondition.markAsDirty();
            tempOtherMedicalCondition.markAsTouched();
            tempOtherMedicalCondition.updateValueAndValidity();
          } else {
            this.showOtherMedicalCondition = false;
            tempOtherMedicalCondition.setValue("");
            tempOtherMedicalCondition.clearValidators();
            tempOtherMedicalCondition.updateValueAndValidity();
          }
        } else {
          this.showOtherMedicalCondition = false;
          tempOtherMedicalCondition.setValue("");
          tempOtherMedicalCondition.clearValidators();
          tempOtherMedicalCondition.updateValueAndValidity();
        }
      });
  }

  fatherHistory() {
    var tempOtherFatherHistory = this.medicalHistoryForm.get(
      "otherFatherMedicalCondition",
    );
    this.medicalHistoryForm.get("father").valueChanges.subscribe((val) => {
      if (val != null && val != undefined && val != "") {
        const checkOtherFieldExistence = val.some(function (el) {
          return el.isOther === true;
        });

        if (checkOtherFieldExistence) {
          this.showOtherFatherIllness = true;
          tempOtherFatherHistory.setValidators([Validators.required]);
          tempOtherFatherHistory.markAsDirty();
          tempOtherFatherHistory.markAsTouched();
          tempOtherFatherHistory.updateValueAndValidity();
        } else {
          this.showOtherFatherIllness = false;
          tempOtherFatherHistory.setValue("");
          tempOtherFatherHistory.clearValidators();
          tempOtherFatherHistory.updateValueAndValidity();
        }
      } else {
        this.showOtherFatherIllness = false;
        tempOtherFatherHistory.setValue("");
        tempOtherFatherHistory.clearValidators();
        tempOtherFatherHistory.updateValueAndValidity();
      }
    });
  }

  motherHistory() {
    var tempOtherMotherHistory = this.medicalHistoryForm.get(
      "otherMotherMedicalCondition",
    );
    this.medicalHistoryForm.get("mother").valueChanges.subscribe((val) => {
      if (val != null && val != undefined && val != "") {
        const checkOtherFieldExistence = val.some(function (el) {
          return el.isOther === true;
        });

        if (checkOtherFieldExistence) {
          this.showOtherMotherIllness = true;
          tempOtherMotherHistory.setValidators([Validators.required]);
          tempOtherMotherHistory.markAsDirty();
          tempOtherMotherHistory.markAsTouched();
          tempOtherMotherHistory.updateValueAndValidity();
        } else {
          this.showOtherMotherIllness = false;
          tempOtherMotherHistory.setValue("");
          tempOtherMotherHistory.clearValidators();
          tempOtherMotherHistory.updateValueAndValidity();
        }
      } else {
        this.showOtherMotherIllness = false;
        tempOtherMotherHistory.setValue("");
        tempOtherMotherHistory.clearValidators();
        tempOtherMotherHistory.updateValueAndValidity();
      }
    });
  }

  brotherHistory() {
    var tempOtherBrotherHistory = this.medicalHistoryForm.get(
      "otherBrotherMedicalCondition",
    );
    this.medicalHistoryForm.get("brother").valueChanges.subscribe((val) => {
      if (val != null && val != undefined && val != "") {
        const checkOtherFieldExistence = val.some(function (el) {
          return el.isOther === true;
        });

        if (checkOtherFieldExistence) {
          this.showOtherBrotherIllness = true;
          tempOtherBrotherHistory.setValidators([Validators.required]);
          tempOtherBrotherHistory.markAsDirty();
          tempOtherBrotherHistory.markAsTouched();
          tempOtherBrotherHistory.updateValueAndValidity();
        } else {
          this.showOtherBrotherIllness = false;
          tempOtherBrotherHistory.setValue("");
          tempOtherBrotherHistory.clearValidators();
          tempOtherBrotherHistory.updateValueAndValidity();
        }
      } else {
        this.showOtherBrotherIllness = false;
        tempOtherBrotherHistory.setValue("");
        tempOtherBrotherHistory.clearValidators();
        tempOtherBrotherHistory.updateValueAndValidity();
      }
    });
  }

  sisterHistory() {
    var tempOtherSisterHistory = this.medicalHistoryForm.get(
      "otherSisterMedicalCondition",
    );
    this.medicalHistoryForm.get("sister").valueChanges.subscribe((val) => {
      if (val != null && val != undefined && val != "") {
        const checkOtherFieldExistence = val.some(function (el) {
          return el.isOther === true;
        });

        if (checkOtherFieldExistence) {
          this.showOtherSisterIllness = true;
          tempOtherSisterHistory.setValidators([Validators.required]);
          tempOtherSisterHistory.markAsDirty();
          tempOtherSisterHistory.markAsTouched();
          tempOtherSisterHistory.updateValueAndValidity();
        } else {
          this.showOtherSisterIllness = false;
          tempOtherSisterHistory.setValue("");
          tempOtherSisterHistory.clearValidators();
          tempOtherSisterHistory.updateValueAndValidity();
        }
      } else {
        this.showOtherSisterIllness = false;
        tempOtherSisterHistory.setValue("");
        tempOtherSisterHistory.clearValidators();
        tempOtherSisterHistory.updateValueAndValidity();
      }
    });
  }

  sonHistory() {
    var tempOtherSonHistory = this.medicalHistoryForm.get(
      "otherSonMedicalCondition",
    );
    this.medicalHistoryForm.get("son").valueChanges.subscribe((val) => {
      if (val != null && val != undefined && val != "") {
        const checkOtherFieldExistence = val.some(function (el) {
          return el.isOther === true;
        });

        if (checkOtherFieldExistence) {
          this.showOtherSonIllness = true;
          tempOtherSonHistory.setValidators([Validators.required]);
          tempOtherSonHistory.markAsDirty();
          tempOtherSonHistory.markAsTouched();
          tempOtherSonHistory.updateValueAndValidity();
        } else {
          this.showOtherSonIllness = false;
          tempOtherSonHistory.setValue("");
          tempOtherSonHistory.clearValidators();
          tempOtherSonHistory.updateValueAndValidity();
        }
      } else {
        this.showOtherSonIllness = false;
        tempOtherSonHistory.setValue("");
        tempOtherSonHistory.clearValidators();
        tempOtherSonHistory.updateValueAndValidity();
      }
    });
  }

  daughterHistory() {
    var tempOtherDaughterHistory = this.medicalHistoryForm.get(
      "otherDaughterMedicalCondition",
    );
    this.medicalHistoryForm.get("daughter").valueChanges.subscribe((val) => {
      if (val != null && val != undefined && val != "") {
        const checkOtherFieldExistence = val.some(function (el) {
          return el.isOther === true;
        });

        if (checkOtherFieldExistence) {
          this.showOtherDaughterIllness = true;
          tempOtherDaughterHistory.setValidators([Validators.required]);
          tempOtherDaughterHistory.markAsDirty();
          tempOtherDaughterHistory.markAsTouched();
          tempOtherDaughterHistory.updateValueAndValidity();
        } else {
          this.showOtherDaughterIllness = false;
          tempOtherDaughterHistory.setValue("");
          tempOtherDaughterHistory.clearValidators();
          tempOtherDaughterHistory.updateValueAndValidity();
        }
      } else {
        this.showOtherDaughterIllness = false;
        tempOtherDaughterHistory.setValue("");
        tempOtherDaughterHistory.clearValidators();
        tempOtherDaughterHistory.updateValueAndValidity();
      }
    });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || "").trim()) {
      const allergyTags = this.medicalHistoryForm.get("allergies") as FormArray;
      allergyTags.push(this.fb.control(value.trim()));
    }
    // Reset the input value
    if (input) {
      input.value = "";
    }
  }

  remove(index: number): void {
    const allergyTags = this.medicalHistoryForm.get("allergies") as FormArray;

    if (index >= 0) {
      allergyTags.removeAt(index);
    }
  }

  history(data) {
    if (data == "false") {
      this.primary = false;
    }
  }

  setEmptyValues() {
    this.medicalHistoryForm.reset();
    this.clearFormArray(this.medicalHistoryForm.get("allergies") as FormArray);
    this.medicalHistoryForm.disable();
    this.loading = false;
  }

  ngOnDestroy() {}

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }

  enableEdit() {
    this.showEditButton = false;
    this.enableEditView();
  }

  disableEditView() {
    this.medicaldata = false;
    this.removable = false;
    this.medicalHistoryForm.disable();
  }

  checkIfShowEditButton(consultId: string) {
    this.isOriginalDoctor = false;
    this.isFollowUpDoctor = false;
    this.isEditTimeOver = true;
    this.isEditFollowupTimeOver = true;
    this.isEditFollowUpInTime = false;
    this.isEditFollowUp2InTime = false;
    this.isEditFollowUp3InTime = false;
    this.showEditButton = false;
    this.showTooltipInfo = false;

    this.checkConsultationDetails(consultId);

    return this.showEditButton && this.showTooltipInfo;
  }

  checkIsTheOriginalDoctor( doctorId = null ) {
    if ( this.credentialService.credentials.userDetails._id == doctorId ) {
      this.isOriginalDoctor = true;
    } else {
      this.isOriginalDoctor = false;
    }
  }

  get anyFollowUpInTimeForEdit(): boolean {
    return (
      this.isEditFollowUpInTime ||
      this.isEditFollowUp2InTime ||
      this.isEditFollowUp3InTime
    );
  }

  checkConsultationDetails(consultId: string) {
    //todo: ver si puedo enviar un array de consultas solo request para obtener toda la info de la consulta
      //deberia consultar incluso los status 5
    
    this.dashboardService.getConsultationDetails(consultId, Status.COMPLETED).subscribe((res: any) => {
      console.log(res.data);
      
      if ( res?.data?.length ) {
        res.data.forEach((consult: any) => {

          this.checkIsTheOriginalDoctor(consult.doctor._id);

          //Consulta de seguimiento
          if ( consult.hasFollowUp ) {
            this.isFollowUpDoctor = false;

            if (
              consult.followUpConsultation3 &&
              consult.followUpConsultation3.doctor &&
              this.credentialService.credentials.userDetails._id ==
                consult.followUpConsultation3.doctor._id
            ) {
              this.isFollowUpDoctor = true;
            }

            if (
              !this.isFollowUpDoctor &&
              consult.followUpConsultation2 &&
              consult.followUpConsultation2.doctor &&
              this.credentialService.credentials.userDetails._id ==
                consult.followUpConsultation2.doctor._id
            ) {
              this.isFollowUpDoctor = true;
            }

            if (
              !this.isFollowUpDoctor &&
              consult.followUpConsultation &&
              consult.followUpConsultation.doctor &&
              this.credentialService.credentials.userDetails._id ==
                consult.followUpConsultation.doctor._id
            ) {
              this.isFollowUpDoctor = true;
            }
          }

          let dateAt12am;

          let strDate = "";
          if (!consult.hasFollowUp || !consult.followUpConsultation) {
            dateAt12am = new Date(consult.date);
            strDate = consult.date.split("T")[0];
          } else {
            dateAt12am = new Date(consult.followUpConsultation.date);
            strDate = consult.followUpConsultation.date.split("T")[0];
          }

          dateAt12am = new Date(
            +strDate.split("-")[0],
            +strDate.split("-")[1] - 1,
            +strDate.split("-")[2],
          );
          dateAt12am.setDate(dateAt12am.getDate() + 1);
          dateAt12am.setHours(0, 0, 0, 0);

          if (!consult.hasFollowUp || !consult.followUpConsultation) {
            this.isEditTimeOver = new Date().getTime() > dateAt12am.getTime();
          } else {
            this.isEditFollowupTimeOver =
              new Date().getTime() > dateAt12am.getTime();
          }

          if (
            consult.hasFollowUp &&
            consult.followUpConsultation?.date &&
            consult.followUpConsultation?.followUpForm
          ) {
            const followUpDateAt12Am = new Date(
              consult.followUpConsultation.date,
            );
            followUpDateAt12Am.setDate(followUpDateAt12Am.getDate() + 1);
            followUpDateAt12Am.setHours(0, 0, 0, 0);

            if (new Date().getTime() <= followUpDateAt12Am.getTime()) {
              this.isEditFollowUpInTime = true;
            }
          }

          if (
            consult.hasFollowUp &&
            consult.followUpConsultation2?.date &&
            consult.followUpConsultation2?.followUpForm
          ) {
            const followUp2DateAt12Am = new Date(
              consult.followUpConsultation2.date,
            );
            followUp2DateAt12Am.setDate(followUp2DateAt12Am.getDate() + 1);
            followUp2DateAt12Am.setHours(0, 0, 0, 0);

            if (new Date().getTime() <= followUp2DateAt12Am.getTime()) {
              this.isEditFollowUp2InTime = true;
            }
          }

          if (
            consult.hasFollowUp &&
            consult.followUpConsultation3?.date &&
            consult.followUpConsultation3?.followUpForm
          ) {
            const followUp3DateAt12Am = new Date(
              consult.followUpConsultation3.date,
            );
            followUp3DateAt12Am.setDate(followUp3DateAt12Am.getDate() + 1);
            followUp3DateAt12Am.setHours(0, 0, 0, 0);

            if (new Date().getTime() <= followUp3DateAt12Am.getTime()) {
              this.isEditFollowUp3InTime = true;
            }
          }
        });
      }

      if (
        (this.isOriginalDoctor && !this.isEditTimeOver) ||
        (this.anyFollowUpInTimeForEdit && (this.isOriginalDoctor || this.isFollowUpDoctor) )
      ) {
        this.showEditButton = true;
        this.showTooltipInfo = true;
      }
    });
  }

  checkConsultationList() {
    this.pageParams.patient = this.patientId;
    this.pageParams.status = Status.COMPLETED;
    this.pageParams.size = 20;
    console.log(this.patientId);
    

    //todo: si esta paginando? 
      // para ir uno por uno al detalle de las consultas? 
      //Deberian ser las ultimas 10 y ver de cuales esas se pueden editar
    
      this.dashboardService.getConsultationList(this.pageParams as HttpParams).subscribe({
      next: (res: any) => {
        const consults: any[] = [...res.data];

        const consultsIds = consults.map(consult => consult._id).join(',');

        console.log({consultsIds});
        //todo: no deberia iterar con some
        
          return this.checkIfShowEditButton(consultsIds);
        // consults.some((consult) => {
        // });
      },
      error: (error: HttpErrorResponse) => {
        this.handleError(error);
      },
    });
  }
}
