import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  AfterViewChecked,
} from "@angular/core";
import { DashboardService } from "../dashboard.service";
import { DataStoreServiceService } from "../data-store-service.service";
import { ActivatedRoute, Router } from "@angular/router";
import moment from "moment";
import { ReturnWaitingRoomComponent } from "../return-waiting-room/return-waiting-room.component";
import { MatDialog } from "@angular/material/dialog";
import { HttpErrorResponse } from "@angular/common/http";
import { ModalService } from "../modal.service";
import { FormGroup } from "@angular/forms";
import { QbService } from "../qb.service";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { CredentialsService } from "src/app/core/credentials.service";
import { SharedService } from "src/app/shared/shared.service";
import { Location } from "@angular/common";
import { CallStateService } from "src/app/shared/call-state/call-state.service";
import { ScheduleFollowUpConsultationComponent } from "../schedule-follow-up-consultation/schedule-follow-up-consultation.component";
import { SubscriptionDetails } from "src/app/core/subscriptions.spec";

import type { RecordedVideoOutput } from "../video/video.model";
import { SucessPopupComponent } from "../sucess-popup/sucess-popup.component";
import { ConfirmEmConsultationComponent } from "../confirm-em-consultation/confirm-em-consultation.component";
//import { title } from "process";
import { SpecialtiesService } from "src/app/core/specialties.service";
import { P, R } from "@angular/cdk/keycodes";
import { CleverTapService } from "src/app/clevertap.service";

@Component({
  selector: "app-consults-page",
  templateUrl: "./consults-page.component.html",
  styleUrls: ["./consults-page.component.scss"],
})
export class ConsultsPageComponent
  implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy
{
  @ViewChild("visitsummary", { read: ElementRef })
  visitSummaryElement: ElementRef;
  @ViewChild("medicalhistory", { read: ElementRef })
  medicalHistoryElement: ElementRef;
  @ViewChild("attachments", { read: ElementRef })
  attachmentsElement: ElementRef;
  @ViewChild("medication", { read: ElementRef }) medicationElement: ElementRef;
  @ViewChild("visithistory", { read: ElementRef })
  visitHistoryElement: ElementRef;

  @ViewChild("componentContainer", { static: true })
  compContainerElement: ElementRef;
  public currentActive = 0;
  public visitSummaryOffset: Number = null;
  public medicalHistoryOffset: Number = null;
  public attachmentsOffset: Number = null;
  public medicationOffset: Number = null;
  public visitHistoryOffset: Number = null;
  public mainScrollOffset: number = null;

  activeState = "Medical History";
  public singPatientRecord: any;
  public consultDetail: any;
  public attachmentOnCallRecord: any;
  minDate;
  consultId: any;
  age: number;
  bmi: number;
  displayBMI: string;
  startVideoCall: boolean = false;
  startAudioCall: boolean = false;
  startChat: boolean = false;
  loading: boolean = true;
  hideVideo;
  consultComplete: boolean = false;
  saveExit: boolean = false;
  connectPatient: boolean = false;
  saveExitEnable: boolean = false;
  disabled: boolean = true;
  followCall = false;
  followCallForm: FormGroup;
  follow: any;
  followCallingUp: boolean = false;
  followCallingDate: any;
  connectTodisabled: boolean = true;
  qbchatConnectionForVideo: string = "start";
  subscriptions: Subscription = new Subscription();
  patientId: string;
  currentLanguage: string;
  fileUrl: string;
  countryCode: string;
  phoneNumber: string;
  currentSection: string = "visitHistory";
  memberships: string[] = ["Movistar", "Movilnet", "Digitel"];
  showOverlayAffiliationsView = false;
  subscriptionsElements: SubscriptionDetails[] = [];

  transfer: boolean = false;
  normalConsult: boolean = true;
  connectToPatientEM: boolean = true;

  constructor(
    private dashboardService: DashboardService,
    private store: DataStoreServiceService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private qbService: QbService,
    private modalService: ModalService,
    private credService: CredentialsService,
    private translateService: TranslateService,
    private sharedService: SharedService,
    private _location: Location,
    private callStateService: CallStateService,
    private specialtiesService: SpecialtiesService,
    private readonly clevertap: CleverTapService,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.consultId = params.id;
      this.patientId = params.patient;
    });
  }

  ngOnInit() {
    if (this.translateService.currentLang == "en-US") {
      this.currentLanguage = "en";
    } else {
      this.currentLanguage = "es";
    }
    this.fileUrl = this.credService.credentials.fileUrl + "/";
    this.patientContainerDetails(this.consultId);

    // Tracking the call status
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.subscriptions.add(
      this.modalService.disconnectCallObservable.subscribe((res: any) => {
        if (res.callStatus == "disconnected") {
          this.startVideoCall = false;
          this.startAudioCall = false;
          this.startChat = false;

          this.callStateService.updateCallState(false);
        }
      }),
    );

    // Reconnect mechanism handling
    this.subscriptions.add(
      this.modalService.reconnectObservable.subscribe((res: any) => {
        this.connectToPatient(res.status);
      }),
    );

    this.subscriptions.add(
      this.modalService.vscallStatusObservable.subscribe((res) => {
        this.saveExit = true;
        this.consultComplete = false;
        this.connectPatient = false;
      }),
    );

    this.subscriptions.add(
      this.modalService.callFeedBackObservable.subscribe((res) => {
        if (res.callStatus == 2) {
          this.disabled = false;
        }
      }),
    );

    const getSubscriptions$ = this.modalService.getSubscriptions(
      this.patientId,
    );
    getSubscriptions$.subscribe(
      (subscriptionElements: any) => {
        // this.isLoading = false;
        this.subscriptionsElements = subscriptionElements;
      },
      (error: HttpErrorResponse) => {
        // this.isLoading = false;
        this.handleError(error);
      },
    );

    if (sessionStorage.getItem("chatConnection") == "finish") {
      this.qbchatConnectionForVideo = "finish";
      this.connectTodisabled = false;
    }

    this.subscriptions.add(
      this.qbService.qbListenerObservable.subscribe((res: any) => {
        if (res == true) {
          this.qbchatConnectionForVideo = "finish";
          this.connectTodisabled = false;
        }
      }),
    );
  }

  ngAfterViewInit() {}

  ngAfterViewChecked() {}

  patientContainerDetails(id) {
    this.subscriptions.add(
      this.dashboardService.getConsultationDetails(id).subscribe((res: any) => {
        localStorage.setItem("patientDetails", JSON.stringify(res.data.patient));
        this.consultDetail = res.data;

        if (res.data?.appointmentType !== 3) {
          this.connectToPatientEM = false;
        }
        if (
          res.data?.appointmentType === 3 &&
          (res.data?.status === 0 || res.data?.status === 5)
        ) {
          this.connectToPatientEM = false;
        }
        if (res.data?.appointmentType === 2) {
          this.normalConsult = false;
        }

        this.consultDetail.specialtyTitle = this.getSpecialtyName(res.data);
      }),
    );

    this.subscriptions.add(
      this.dashboardService.getPatientDetails(id).subscribe(
        (res: any) => {
          this.singPatientRecord = res;
          if (res.patient.parent) {
            this.countryCode = res.patient.parent.countryCode;
            this.phoneNumber = res.patient.parent.phoneNumber;
          } else {
            this.countryCode = res.patient.countryCode;
            this.phoneNumber = res.patient.phoneNumber;
          }
          this.calculateAge(this.singPatientRecord.patient.dateOfBirth);
          this.calculateBMI(
            this.singPatientRecord.medicalHistory.height,
            this.singPatientRecord.medicalHistory.weight,
          );
          if (this.singPatientRecord.consultation.status === 5) {
            this.consultComplete = true;
          } else {
            this.consultComplete = false;
          }

          if (this.singPatientRecord.consultation.status === 4) {
            this.saveExit = true;
            this.disabled = false;
          } else {
            this.saveExit = false;
          }
          if (
            this.singPatientRecord.consultation.status === 2 ||
            this.singPatientRecord.consultation.status === 3
          ) {
            this.connectPatient = true;
          } else {
            this.connectPatient = false;
          }

          this.store.setPatientRecord(this.singPatientRecord);
          this.loading = false;
        },

        (error: HttpErrorResponse) => {
          this.handleError(error);
          this.router.navigate(["/"]);
        },
      ),
    );
  }

  get canCreateFollowUp(): boolean {
    return !!this.singPatientRecord.patient.subscription ||
      (!!this.singPatientRecord.patient.parent &&
        !!this.singPatientRecord.patient.parent.subscription)
      ? (!!this.singPatientRecord.patient.subscription &&
          (!this.singPatientRecord.parentConsultation ||
            !this.singPatientRecord.parentConsultation.followUpDate3)) ||
          (!!this.singPatientRecord.patient.parent &&
            !!this.singPatientRecord.patient.parent.subscription &&
            (!this.singPatientRecord.parentConsultation ||
              !this.singPatientRecord.parentConsultation.followUpDate3))
      : !this.singPatientRecord.consultation.isFollowUp;
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }
  setStateAsActive(link) {
    this.activeState = link.name;
  }

  calculateAge(dob: any) {
    this.age = moment().diff(dob, "years");
  }

  calculateBMI(height: number, weight: number) {
    if (weight > 0 && height > 0) {
      this.bmi = weight / (((height / 100) * height) / 100);
      this.displayBMI = this.bmi.toFixed(2);
    } else {
      this.bmi = null;
    }
  }
  openDialog() {
    const dialogRef = this.dialog.open(ReturnWaitingRoomComponent, {
      width: "429px",
      // height:'273px',
      disableClose: true,
      autoFocus: false,
    });

    this.subscriptions.add(dialogRef.afterClosed().subscribe((result) => {}));
  }

  connectToPatient(communicationMode: number) {
    if (
      this.consultDetail?.appointmentType === 3 &&
      this.consultDetail.status === 1
    ) {
      this.subscriptions.add(
        this.dashboardService
          .getConsultation(this.consultDetail._id)
          .subscribe({
            next: (res: any) => {
              this.consultDetail = { ...this.consultDetail, status: 3 };
              this.subscriptions.add(
                this.dashboardService
                  .startCallByDoctor(this.consultId, {
                    communicationMode: communicationMode,
                  })
                  .subscribe(
                    (res: any) => {
                      if (res) {
                        this.connectToPatientEM = false;
                        if (communicationMode == 2) {
                          this.callStateService.updateCallState(true);
                          this.startVideoCall = true;
                        } else if (communicationMode == 1) {
                          this.callStateService.updateCallState(true);
                          this.startAudioCall = true;
                        } else if (communicationMode == 3) {
                          this.callStateService.updateCallState(true);
                          this.startChat = true;
                        }
                      }
                    },
                    (error: HttpErrorResponse) => {
                      this.handleError(error);
                    },
                  ),
              );
            },
            error: (error: HttpErrorResponse) => {
              this.handleError(error);
            },
          }),
      );
    } else {
      this.subscriptions.add(
        this.dashboardService
          .startCallByDoctor(this.consultId, {
            communicationMode: communicationMode,
          })
          .subscribe(
            (res: any) => {
              this.connectToPatientEM = false;
              if (res) {
                if (communicationMode == 2) {
                  this.callStateService.updateCallState(true);
                  this.startVideoCall = true;
                } else if (communicationMode == 1) {
                  this.callStateService.updateCallState(true);
                  this.startAudioCall = true;
                } else if (communicationMode == 3) {
                  this.callStateService.updateCallState(true);
                  this.startChat = true;
                }
              }
            },
            (error: HttpErrorResponse) => {
              this.handleError(error);
            },
          ),
      );
    }
  }

  openModalWithAfilliations() {
    this.showOverlayAffiliationsView = true;
  }

  closeModalWithAfilliations() {
    this.showOverlayAffiliationsView = false;
  }

  submitVisitSummary() {
    if (this.followCallingUp && !this.followCallingDate) {
      this.subscriptions.add(
        this.translateService
          .get(
            "Please select the date if you want followup call to be scheduled",
          )
          .subscribe((text: string) => {
            alert(text);
          }),
      );
      return;
    }
    this.modalService.sendVsFormData({
      hasFollowUp: this.followCallingUp,
      followUpDate: this.followCallingDate,
    });
  }

  setTransfer(transfer: boolean) {
    this.transfer = transfer;
    setTimeout(() => {
      this.submitVisitSummary();
    }, 500);
  }

  weekendFilter = (d: Date): boolean => {
    return true;
  };

  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }

  scrollTo(section) {
    document.querySelector("#" + section).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }

  openScheduleFollowUpConsultation() {
    const dialogRef = this.dialog.open(ScheduleFollowUpConsultationComponent, {
      width: "480px",
      disableClose: true,
      autoFocus: false,
      panelClass:"schedule-follow-up-dialog",
      data: {
        action: "create",
        consultId: this.consultId,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.event && result.event === "create") {
        this.followCallingUp = true;
        this.followCallingDate = result?.data?.followUpDate;
      }
    });
  }

  routeToBackState() {
    this._location.back();
  }

  ngOnDestroy() {
    this.dialog.closeAll();
    this.subscriptions.unsubscribe();
  }

  rescheduled(appointmentId: string) {
    const dialogRef = this.dialog.open(ConfirmEmConsultationComponent, {
      width: "600px",
      height: "380px",
      data: {
        title: "¿Estás seguro que deseas solicitar reagendar esta cita?",
        acceptAction: "Solicitar",
        cancelAction: "Cancelar",
        margin: true,
      },
      panelClass: "transfer-dialog",
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.specialtiesService
          .rescheduleAppointment(appointmentId, {
            appointmentStatusUpdatedBy: 2,
          })
          .subscribe({
            next: (res) => {
              this.sharedService.showMessage(
                "Se envió tu solicitud al agente exitosamente.",
                "success",
              );
              this.patientContainerDetails(this.consultId);
              this.clevertap.registerEvent$("reschedule_requested", {
                user_id: this.consultDetail.patient.identificationNumber,
                specialty: res.data.medicalSpecialty,
                requester: this.credService.credentials.userDetails._id,
              });
              this.connectToPatientEM = false;
            },
            error: (error) => {
              this.sharedService.showErrorMessage("ocurrio un error", "error");
            },
          });
      }
    });
  }

  getSpecialtyName(consultation: any) {
    if (consultation?.appointmentType === 2) {
      if (consultation.medicalSpecialty?.subSpeciality) {
        return `Triaje para ${
          consultation.medicalSpecialty.subSpeciality.subSpeciality[
            this.currentLanguage
          ]
        } - `;
      }
      if (consultation.medicalSpecialty?.speciality) {
        return `Triaje para ${
          consultation.medicalSpecialty.speciality.speciality[
            this.currentLanguage
          ]
        } - `;
      }
    }

    if (consultation?.appointmentType === 3) {
      if (consultation.medicalSpecialty?.subSpeciality) {
        return `${
          consultation.medicalSpecialty.subSpeciality.subSpeciality[
            this.currentLanguage
          ]
        } - `;
      }
      if (consultation.medicalSpecialty?.speciality) {
        return `${
          consultation.medicalSpecialty.speciality.speciality[
            this.currentLanguage
          ]
        } - `;
      }
    }

    return "";
  }
}
