import { Component, OnInit } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";

enum OriginCall {
  IVR = 4, //central telefonica
  COLLABORATOR = 5,
  OTHER = 6,
}

@Component({
  selector: "app-consultation-origin",
  templateUrl: "./consultation-origin.component.html",
  styleUrls: ["./consultation-origin.component.scss"],
})
export class ConsultationOriginComponent {
  originCall = OriginCall;

  constructor(public dialogRef: MatDialogRef<ConsultationOriginComponent>) {}

  onSelectOrigin(origin: any) {
    this.dialogRef.close(origin);
  }

  onClose() {
    this.dialogRef.close();
  }
}
