import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { dataPrueba } from "src/app/agent/data-test";
import { AppointmentStatus } from "./appointment.enum";
import { M } from "@angular/cdk/keycodes";
import { CredentialsService } from "src/app/core/credentials.service";
import { SharedService } from "src/app/shared/shared.service";
@Component({
  selector: "app-appointment-item",
  templateUrl: "./appointment-item.component.html",
  styleUrls: ["./appointment-item.component.scss"],
})
export class AppointmentItemComponent implements OnInit {
  @Input() messages: any = [];
  @Input() reAgent?: boolean = false;
  /*  
  messages: any = dataPrueba; */
  fileUrl: string | undefined;
  currentLanguage: string | undefined;
  constructor(
    public readonly sharedService: SharedService,
    private readonly router: Router,
    private translateService: TranslateService,
    private credentialService: CredentialsService,
  ) {}

  ngOnInit(): void {
    if (this.translateService.currentLang == "en-US") {
      this.currentLanguage = "en";
    } else {
      this.currentLanguage = "es";
    }

    if (this.credentialService.credentials?.fileUrl) {
      this.fileUrl = this.credentialService.credentials.fileUrl + "/";
    }
  }

  gotoPatient(id: number) {
    this.router.navigate([`agent/patient`], {
      queryParams: { patient: id, appointment: true },
    });
  }

  public isResScheduleByDoctor(message: any) {
    if (message.appointmentStatus === AppointmentStatus.RESCHEDULE_BY_DOCTOR) {
      return true;
    }
    return false;
  }
}
